import { useTheme } from "@emotion/react";
import { Card, Typography, Box, useMediaQuery, Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DarkLightModeContext } from "../../../context/DarkLightModeContext";
import { useContext } from "react";

const Tile = ({ title = "", path = "", badgeCount = 0 }) => {
  const { isDarkMode } = useContext(DarkLightModeContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ width: "100%" }}>
      <Badge
        color={isDarkMode ? "primary" : "secondary"}
        badgeContent={badgeCount}
        max={25}
        sx={{ width: "100%" }}
      >
        <Card
          raised={true}
          sx={{
            width: "100%",
            height: "115px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",

            cursor: "pointer",
            backgroundColor: "#0D0D0D",
            // backgroundColor: theme.palette.secondary.main,
            borderRadius: "4px",
            justifyContent: "center",
          }}
          onClick={() => navigate(path)}
        >
          <Typography
            variant="h7"
            // fontWeight={300}
            color="white"
            textAlign={"center"}
          >
            {title.toUpperCase()}
          </Typography>
        </Card>
      </Badge>
    </Box>
  );
};
export default Tile;
