import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Fab,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import ListItem from "../../../component/ListItem";
import { AuthContext } from "../../../context/AuthContext";
import InventoryTracking from "../../../api/InventoryTracking";
import useSearchAndPagination from "../../../customHooks/useSearchAndPagination";
import SearchAndPagination from "../../../component/SearchAndPagination";
import PrintableQRCode from "../../../component/PrintableQRCode";

import escapeCharacters from "../../../helpers/escapeCharacters";

import CreateReturnDialog from "../../../component/CreateReturnDialog";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import PageContent from "../../../component/PageContent";
import serverTimeConverter from "../../../helpers/serverTimeConverter";
import { useTheme } from "@emotion/react";

const STATUS_PENDING = "PENDING";
const STATUS_POSTED = "POSTED";
const STATUS_ALL = "ALL";

const Return = () => {
  const navigate = useNavigate();
  const { authToken, fullName, userBranch } = useContext(AuthContext);
  const [returnList, setReturnList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [addModal, setAddModal] = useState({
    open: false,
    search: "",
    imageBlobs: [],
    imageUrls: [],
    items: [{ item_text: "", quantity: 0 }],
    jobId: null,
    notes: "",
  });
  const [returnStatus, setReturnStatus] = useState(STATUS_PENDING);

  const { openSnackMessage } = useContext(SnackAlertContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const searchAndPagination = useSearchAndPagination(userBranch);
  const { search, location, page, limit, setPages, setPage } =
    searchAndPagination;

  const getReturns = async () => {
    try {
      console.log("Get Returns");
      let locationId = "";
      for (const loc of locations) {
        if (loc.location === location) {
          locationId = loc.locationid;
        }
      }

      const response = await InventoryTracking.get(
        `/returns?search=${escapeCharacters(
          search
        )}&pageSize=20&page=${page}&status=${returnStatus}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Returns", response.data);
      setReturnList(
        response.data.returns.map((ret) => ({
          ...ret,
          img_urls: ret?.img_urls?.split(","),
        }))
      );
      setPages(response.data.pages);
    } catch (error) {
      console.log(error);
    }
  };

  const getJobs = async () => {
    try {
      const response = await InventoryTracking.get(
        `/jobs?search=${addModal.search}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      setJobs(response.data.jobs);
    } catch (error) {
      console.log(error);
    }
  };

  const getLocations = async () => {
    try {
      const response = await InventoryTracking.get(`/locations`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setLocations(response.data.locations);
    } catch (error) {
      console.log(error);
    }
  };
  const [loading, setLoading] = useState(false);
  const saveReturn = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      let imageFormDataArray = [];

      let x = Math.ceil(addModal.imageBlobs.length / 15);

      for (let i = 0; i < x; i++) {
        let imgFormData = new FormData();
        for (
          let j = 15 * i;
          j < 15 * (i + 1) && j < addModal.imageBlobs.length;
          j++
        ) {
          imgFormData.append("images", addModal.imageBlobs[j]);
        }
        console.log(imgFormData);
        imageFormDataArray.push(imgFormData);
      }
      console.log("imageFormDataArray", imageFormDataArray);
      let imageUrls = [];
      for (let data of imageFormDataArray) {
        data.append("resourceType", "public/return");
        let response = await InventoryTracking.post(`/uploads/images`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "content-type": "multipart/form-data",
          },
        });
        imageUrls = [...imageUrls, ...response.data.files];
      }
      if (imageUrls.length > 30) throw "You can only upload up to 30 images";
      console.log("imageUrls", imageUrls);

      const response = await InventoryTracking.post(
        `/returns`,
        {
          createdBy: fullName,
          notes: addModal.notes,
          jobId: addModal.jobId,
          items: addModal.items,
          images: imageUrls,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response);

      openSnackMessage("success", "Return created");
      // Reset and Close Modal
      setAddModal({
        open: false,
        search: "",
        imageBlobs: [],
        imageUrls: [],
        items: [{ item_text: "", quantity: 0 }],
        jobId: null,
      });
      getReturns();
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (locations.length === 0) getLocations();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      getReturns();
    }
  }, [locations, location, page]);

  useEffect(() => {
    if (locations.length > 0) {
      setTimeout(() => {
        getReturns();
        setPage(1);
      }, 1500);
    }
  }, [search, returnStatus]);

  useEffect(() => {
    getJobs();
    //getReturns();
    console.log(addModal);
  }, [addModal.search]);

  const elipsis = (word, length) => {
    return word.length > length ? word.slice(0, length) + "..." : word;
  };

  return (
    <PageContent>
      <h2 style={{ textAlign: "center" }}>Return Requests</h2>
      <Fab
        color="primary"
        aria-label="add"
        style={{ position: "fixed", bottom: 24, right: 24, cursor: "pointer" }}
        onClick={() => {
          setAddModal((prev) => ({ ...prev, open: true }));
        }}
      >
        <AddIcon />
      </Fab>
      <CreateReturnDialog
        loading={loading}
        addModal={addModal}
        setAddModal={setAddModal}
        jobs={jobs}
        saveReturn={saveReturn}
      />
      <div
        style={{
          position: "relative",
          flexDirection: "row",
          display: useMediaQuery(theme.breakpoints.down("sm"))
            ? "column"
            : "row",
          marginTop: 16,
          marginLeft: 16,
          marginRight: 16,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!isSmallScreen && (
            <PrintableQRCode
              url={`${window.location.host}/return`}
              displaySize={100}
              printSize={100}
              subtext="Returns Page"
            />
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: isSmallScreen ? "flex-end" : "center",
        }}
      >
        <div style={{ width: isSmallScreen ? "inherit" : "100%" }}>
          <SearchAndPagination
            stateHandlers={searchAndPagination}
            locationOptions={locations.map((l) => l.location)}
          />
        </div>
        <TextField
          select
          value={returnStatus}
          label="Status"
          onChange={(e) => setReturnStatus(e.target.value)}
          size="small"
          sx={{
            width: "150px",
          }}
        >
          <MenuItem value={STATUS_PENDING}>PENDING</MenuItem>
          <MenuItem value={STATUS_POSTED}>POSTED</MenuItem>
          <MenuItem value={STATUS_ALL}>ALL</MenuItem>
        </TextField>
      </div>

      {returnList.map((returned, index) => {
        let details = [];
        details.push(
          `Status: ${
            returned.posted_on
              ? "POSTED"
              : returned.rejected_on
              ? "REJECTED"
              : "PENDING"
          }`
        );
        details.push(
          `Return Date: ${serverTimeConverter
            .convertServerCreatedOnTimeStampToPhoenix(returned.created_on)
            .format("MM/DD/YY h:mm a")}`
        );
        details.push(`Returned By: ${returned.created_by}`);
        details.push(`Notes: ${returned.notes}`);
        return (
          <ListItem
            key={index}
            title={`${returned.transactionid} - ${returned.job_name}`}
            details={details}
            // image={returned.img_url ? returned.img_url : undefined}
            onClick={() => {
              navigate(`/return/${returned.id}`);
            }}
          />
        );
      })}
    </PageContent>
  );
};

export default Return;
