import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Grid, Modal, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import PrintableQRCode from "../../../component/PrintableQRCode";
import { GrabsContext } from "../../../context/GrabsContext";
import { Check, PanTool, ShoppingCart } from "@mui/icons-material";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import PageContent from "../../../component/PageContent";
import { useTheme } from "@emotion/react";

const CommittedDetails = () => {
  const { id } = useParams();
  const { authToken } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [details, setDetails] = useState(null);
  let navigate = useNavigate();
  const { grabs, grabFromCommitted } = useContext(GrabsContext);
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [verifyJobChangeModal, setVerifyJobChangeModal] = useState(false);

  const getCommittedDetails = async () => {
    try {
      const response = await InventoryTracking.get(`/committed/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setDetails(response.data.material);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCommittedDetails();
  }, []);

  if (true)
    return (
      <PageContent>
        {details ? (
          <div>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={4}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ marginTop: 16 }}>
                  {!isSmallScreen && (
                    <div
                      style={{
                        background: "white",
                        padding: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PrintableQRCode
                        printSize={100}
                        displaySize={100}
                        url={`${window.location.host}/committed/${id}`}
                        subtext={details.item_description}
                        topText={"COMMITTED: " + details.job_name}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      style={{ width: 150, height: 50 }}
                      variant="contained"
                      startIcon={<ShoppingCart />}
                      onClick={async () => {
                        if (
                          grabs.committed.length == 0 &&
                          grabs.staged.length == 0 &&
                          grabs.selectedJob &&
                          grabs.selectedJob.projectId !== null &&
                          grabs.selectedJob.projectId !== details.job_id
                        ) {
                          setVerifyJobChangeModal(true);
                        } else if (
                          (grabs.committed.length > 0 ||
                            grabs.staged.length > 0) &&
                          grabs.selectedJob &&
                          grabs.selectedJob.projectId !== details.job_id
                        ) {
                          openSnackMessage(
                            "warning",
                            `You are currently stocking out materials committed to or staged for ${grabs.selectedJob.jobName} (${grabs.selectedJob.projectId}). You cannot stock out materials committed to a different job`
                          );
                        } else {
                          grabFromCommitted(details);
                          navigate("/daily-stockouts/new");
                        }
                      }}
                    >
                      Stock Out
                    </Button>
                    <Modal
                      open={verifyJobChangeModal}
                      onClose={() => {
                        setVerifyJobChangeModal(false);
                      }}
                    >
                      <Card
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          minWidth: 300,
                          maxWidth: 500,
                          maxHeight: "100%",
                          bgcolor: "background.paper",
                          boxShadow: 24,
                          p: 4,
                          display: "block",
                          overflow: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <h3>Verify Job Change for Stock Out</h3>
                          <div>
                            Current Stock Out is set for{" "}
                            {grabs.selectedJob ? grabs.selectedJob.jobName : ""}{" "}
                            (
                            {grabs.selectedJob
                              ? grabs.selectedJob.projectId
                              : ""}
                            ). Stocking Out this committed item will set the
                            selected job to {details.job_name} ({details.job_id}
                            ).
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              style={{ width: 100, marginRight: 8 }}
                              variant="outlined"
                              onClick={() => {
                                setVerifyJobChangeModal(false);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              style={{ width: 100 }}
                              variant="contained"
                              startIcon={<ShoppingCart />}
                              onClick={() => {
                                setVerifyJobChangeModal(false);
                                grabFromCommitted(details);
                                navigate("/daily-stockouts/new");
                              }}
                            >
                              Stock Out
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Modal>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <div style={{ padding: 16 }}>
                      <h3 style={{ textAlign: "center" }}>
                        {details.item_description}
                      </h3>

                      <div style={{ textAlign: "center" }}>
                        Committed to: {details.job_name} ({details.job_id})
                      </div>
                      <div style={{ textAlign: "center" }}>
                        Internal Code: {details.internal_code}
                      </div>
                      <div style={{ textAlign: "center" }}>
                        Quantity: {details.quantity}
                      </div>
                      <div style={{ textAlign: "center" }}>
                        UOM: {details.uom}
                      </div>
                      <div style={{ textAlign: "center" }}>
                        Branch: {details.branch}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ padding: 16 }}>
                      <div
                        style={{
                          width: "100%",
                          padding: 12,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={details.image_url}
                          style={{ maxWidth: "100%", maxHeight: 300 }}
                        />
                      </div>
                      {/* <Grid
                      container
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 16,
                        marginBottom: 16,
                      }}
                    >
                      <Grid item md={7}>
                        QUANTITY
                      </Grid>
                      <Grid item md={5}>
                        ADJUST BUTTON
                      </Grid>
                    </Grid> */}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <div style={{ padding: 16 }}>
                  <h3>Transactions</h3>
                  {details &&
                    details.transactions.map((transaction, index) => (
                      <div
                        key={index}
                        style={{
                          marginTop: 8,
                          padding: 16,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <h4 style={{ flex: 1 }}>
                          {transaction.transaction_id}
                        </h4>
                        <p
                          style={{
                            padding: 8,
                            color: transaction.quantity < 0 ? "red" : "green",
                          }}
                        >
                          {transaction.quantity < 0 ? "" : "+"}
                          {transaction.quantity}
                        </p>
                      </div>
                    ))}
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ height: "100%" }}>
            <div>Loading...</div>
          </div>
        )}
      </PageContent>
    );
};

export default CommittedDetails;
