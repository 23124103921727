import momentTz from "moment-timezone";
import moment from "moment";

const serverTimeZone = "MDT"; // Bluehost is MDT(UTC - 6 hours) default

/*
  For some odd reason, Bluehost chooses MDT for their server time
  so autogenerated timestamps are stored in MDT. The problem is when an update timestamp
  is added on BRM (approved_on) it BRMLite stores the arizona time which is 1 hour late
*/
const convertServerCreatedOnTimeStampToPhoenix = (serverCreatedOnTimeStamp) => {
  //console.log("US TIMEZONES", momentTz.tz.zonesForCountry("US", true));
  //   let mdtTime = momentTz(serverTimeStamp).tz("America/Denver"); // Bluehost is MDT(UTC - 6 hours) default
  let phx = momentTz(serverCreatedOnTimeStamp).tz("America/Phoenix");
  phx.subtract("hours", 1);
  return phx;
};

const convertServerTimeStampToPhoenix = (serverCreatedOnTimeStamp) => {
  //console.log("US TIMEZONES", momentTz.tz.zonesForCountry("US", true));
  //   let mdtTime = momentTz(serverTimeStamp).tz("America/Denver"); // Bluehost is MDT(UTC - 6 hours) default
  let phx = momentTz(serverCreatedOnTimeStamp).tz("America/Phoenix");
  return phx;
};
export default {
  convertServerCreatedOnTimeStampToPhoenix,
  convertServerTimeStampToPhoenix,
};
