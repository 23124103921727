import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { LockClock, Search } from "@mui/icons-material";
import {
  Button,
  Card,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ListItem from "../../../component/ListItem";
import { AuthContext } from "../../../context/AuthContext";
import InventoryTracking from "../../../api/InventoryTracking";
import useSearchAndPagination from "../../../customHooks/useSearchAndPagination";
import SearchAndPagination from "../../../component/SearchAndPagination";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import ImageModalButton from "../../../component/ImageModalButton";
import Print from "../../../component/Print";
import QRCode from "react-qr-code";
import AddIcon from "@mui/icons-material/Add";
import escapeCharacters from "../../../helpers/escapeCharacters";

import Fab from "@mui/material/Fab";
import axios from "axios";
import InventoryTabs from "../../../component/InventoryTabs";

import PendingPoModal from "../../../component/PendingPoModal";
import PageContent from "../../../component/PageContent";
import { useTheme } from "@emotion/react";

const Inventory = () => {
  const navigate = useNavigate();
  const { authToken, userBranch } = useContext(AuthContext);
  const [materials, setMaterials] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState(["SDL", "FLG"]);
  const [isLoading, setIsLoading] = useState(false);

  const { openSnackMessage } = useContext(SnackAlertContext);
  // const [pendingPos, setPendingPos] = useState([]);
  // const [pendingPoModalData, setPendingPoModalData] = useState(null);

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const searchAndPagination = useSearchAndPagination(userBranch);
  const {
    search,
    location,
    page,
    pages,
    setPage,
    setPages,
    category,
    setCategory,
    loading,
    setLoading,
  } = searchAndPagination;

  // INFINITE SCROLLING
  const observer = useRef();
  const lastElement = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < pages) {
          console.log("visible");
          setPage((curr) => curr + 1);
        }
      });
      if (node) observer.current.observe(node);
      // console.log("NODE: ", node);
    },
    [isLoading, page < pages]
  );
  //

  const getInventory = async (controller) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const response = await InventoryTracking.get(
        `/inventory?search=${escapeCharacters(
          search
        )}&category=${category}&location=${location}&pageSize=20&page=${page}`,
        data
      );
      console.log(response.data);
      // setMaterials(response.data.materials);

      if (page > 1) {
        setMaterials((prev) => {
          return [...prev, ...response.data.materials];
        });
      } else {
        setMaterials((prev) => {
          return [...response.data.materials];
        });
      }
      setPages(response.data.pages);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getCategories = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await InventoryTracking.get(
        `/material-categories`,
        data
      );
      console.log(response.data);
      setCategories(response.data.categories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getInventory(controller);

    if (categories.length < 1) getCategories(controller);
    // if (locations.length < 1) getLocations(controller);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [category, location, page]);

  useEffect(() => {
    const controller = new AbortController();
    setTimeout(() => {
      getInventory(controller);
      setPage(1);
    }, 300);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [search]);

  // useEffect(() => {
  //   const controller = new AbortController();
  //   if (page > 1) {
  //     setPage(1);
  //   } else {
  //     getInventory(controller);
  //   }
  //   return () => {
  //     controller.abort();
  //   };
  // }, [search, location, category]);

  useEffect(() => {
    const controller = new AbortController();
    getInventory(controller);
    return () => {
      controller.abort();
    };
  }, [page]);

  const scrollToTop = () =>
    document.getElementById("root-container").scrollTo(0, 0);

  return (
    <PageContent id={"the-card"}>
      <div
        style={{
          position: "relative",
          flexDirection: "row",
          display: useMediaQuery(theme.breakpoints.down("sm"))
            ? "column"
            : "row",
          marginTop: 16,
          marginLeft: 16,
          marginRight: 16,
        }}
      >
        <Button
          variant="outlined"
          style={{
            position: useMediaQuery(theme.breakpoints.down("sm"))
              ? "relative"
              : "absolute",
            right: 0,
            top: 0,
            // width: useMediaQuery(theme.breakpoints.down("sm")) ? "100%" : 120,
            height: 36,
            margin: 0,
            display: useMediaQuery(theme.breakpoints.down("sm")) ? "none" : "",
          }}
          onClick={() => navigate("/inventory/print")}
        >
          Print Inventory List
        </Button>
        <InventoryTabs pageName={"Inventory"} />
        <Print>
          <Print.Trigger
            style={{
              display: useMediaQuery(theme.breakpoints.down("sm"))
                ? "none"
                : "flex",
              flexDirection: "row",
              justifyContent: "end",
              marginTop: "5px",
            }}
          >
            <Button variant="contained">Print QR Codes</Button>
          </Print.Trigger>
          <Print.PrintableData>
            <div style={{ columns: 1, marginLeft: "15px" }}>
              {materials.map((item) => (
                <div
                  style={{
                    breakInside: "avoid",
                    breakBefore: "always",
                    breakAfter: "always",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "30%",
                      marginBottom: "0px",
                      width: "100px",
                    }}
                  >
                    <p
                      style={{
                        color: "#000",
                        textAlign: "center",
                        marginBottom: 0,
                      }}
                    >
                      {item.description}
                    </p>
                  </div>
                  <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="L" //'L' 'M' 'Q' 'H'
                    size={100} // display size
                    title={item.description}
                    value={`${window.location.host}/inventory/${item.inventoryId}`}
                    style={{
                      marginLeft: "30%",
                      marginTop: "0px",
                      marginBottom: 0,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "30%",
                      marginTop: "0px",
                      width: "100px",
                    }}
                  >
                    <p
                      style={{
                        color: "#000",
                        textAlign: "center",
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      {item.internal_code ? item.internal_code : "N/A"}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Print.PrintableData>
        </Print>
      </div>

      <SearchAndPagination
        stateHandlers={searchAndPagination}
        locationOptions={locations}
        categoryOptions={categories.map((cat) => cat.key_name)}
        showCategory={true}
      />
      <Button
        variant="outlined"
        style={{
          width: useMediaQuery(theme.breakpoints.down("sm")) ? "100%" : 200,
        }}
        onClick={() => {
          navigate("/inventory/transactions");
        }}
      >
        View Transactions
      </Button>
      <Button
        variant="outlined"
        style={{
          width: useMediaQuery(theme.breakpoints.down("sm")) ? "100%" : 200,
        }}
        onClick={() => {
          navigate("/inventory/counts");
        }}
      >
        Inventory Counts
      </Button>
      {materials.map((item, index) => {
        let details = [];
        details.push(`Code: ${item.internal_code}`);
        details.push(`Current Inventory: ${item.quantity} ${item.uom}`);
        // details.push(`Category: ${item.category}`);
        // details.push(`Branch: ${item.branch}`);
        if (materials.length === index + 1) {
          return (
            <div ref={lastElement} key={index}>
              <ListItem
                title={item.description}
                details={details}
                // image={item.image_url}
                onClick={() => {
                  navigate(`/inventory/${item.inventoryId}`);
                }}
              />
            </div>
          );
        }
        return (
          <div key={index}>
            <ListItem
              title={item.description}
              details={details}
              // image={item.image_url}
              onClick={() => {
                navigate(`/inventory/${item.inventoryId}`);
              }}
            />
          </div>
        );
      })}
      {/* <div>
        <h3>Pending POs</h3>
        {pendingPos &&
          pendingPos.map((po, index) => {
            let itemDetails = [];
            return (
              <ListItem
                key={index}
                title={`${po.poid}`}
                details={itemDetails}
                onClick={() => {
                  getPendingPoById(po.id);
                }}
              />
            );
          })}
        <PendingPoModal
          pendingPoModalData={pendingPoModalData}
          setPendingPoModalData={setPendingPoModalData}
          //receivePendingPo={receivePendingPo}
          //isLoading={isLoading}
          onUpdatePo={() => {
            // Reset page data
            getInventory();
            getPendingInventoryPos();
          }}
        />
      </div> */}
    </PageContent>
  );
};

export default Inventory;
