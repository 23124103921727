import makePage from "../../HOCs/makePage";
import Print from "../../../../../component/Print";
import { Box, Button, Typography } from "@mui/material";
import blackBehmerLogo from "../../../../../assets/behmer_logo2.png";
import { useState } from "react";

const PrintBehmerStickers = () => {
  const [assets, setAssets] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
  ]);
  const AssetList = () => {
    return (
      <div style={{ columns: 2, marginLeft: "15px" }}>
        {assets.map((asset, index) => (
          <div key={index}>
            <div
              style={{
                breakInside: "avoid",
                breakBefore: "always",
                breakAfter: "always",
              }}
            >
              <img
                src={blackBehmerLogo}
                alt="Behmer Roofing Logo"
                style={{
                  marginTop: "16px",
                  maxWidth: "100px",
                  maxHeight: "75px",
                  cursor: "pointer",
                }}
                //onClick={() => navigate("/home")}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Box>
        <Print>
          <Print.Trigger>
            <Button variant="contained">Print</Button>
          </Print.Trigger>
          <Print.PrintableData>
            <div style={{ columns: 2, marginLeft: "15px" }}>
              {assets.map((ass, index) => (
                <div key={index}>
                  <div
                    style={{
                      breakInside: "avoid",
                      breakBefore: "always",
                      breakAfter: "always",
                    }}
                  >
                    <img
                      src={blackBehmerLogo}
                      alt="Behmer Roofing Logo"
                      style={{
                        marginTop: "16px",
                        maxWidth: "100px",
                        maxHeight: "75px",
                        cursor: "pointer",
                      }}
                      //onClick={() => navigate("/home")}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Print.PrintableData>
        </Print>
        <AssetList />
      </Box>
    </>
  );
};
export default makePage(PrintBehmerStickers);
