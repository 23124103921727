import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { Typography } from "@mui/material";

const TruckItem = (props) => {
  const { jobId, onTruckId } = props;
  const { authToken } = useContext(AuthContext);
  const [details, setDetails] = useState(null);

  const getTruckItemDetails = async () => {
    try {
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/loaded-on-truck-items/${onTruckId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      setDetails(response.data.onTruck);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTruckItemDetails();
  }, [onTruckId]);

  if (details)
    return (
      <Typography {...props}>
        {details.description} [UOM: {details.uom}]
      </Typography>
    );
};

export default TruckItem;
