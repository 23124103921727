import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { GrabsContext } from "../../../context/GrabsContext";
import PageContent from "../../../component/PageContent";
import escapeCharacters from "../../../helpers/escapeCharacters";
import serverTimeConverter from "../../../helpers/serverTimeConverter";
import { useTheme } from "@emotion/react";
import TextFieldDebounced from "../../../component/TextFieldDebounced";
import useDailyStockouts from "../../../customHooks/useDailyStockouts";
import InfiniteScrollerTable from "../../../component/InfiniteScroller/InfiniteScrollerTable";
import ListFooter from "../../../component/InfiniteScroller/ListFooter";

const PendingDailyStockouts = ({ stockouts, search, setSearch }) => {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const { clearGrabs } = useContext(GrabsContext);
  const {
    searchQuery,
    setSearchQuery,
    status,
    setStatus,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  } = useDailyStockouts(authToken, 50, "pending");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div
        style={{
          display: "flex",
          position: "relative",
          flexDirection: isSmallScreen ? "column" : "row",
          marginTop: 16,
          paddingLeft: 16,
          paddingRight: 16,
          justifyContent: isSmallScreen ? "center" : "flex-end",
        }}
      >
        <TextFieldDebounced
          placeholder="search..."
          value={searchQuery}
          size="small"
          sx={{
            width: isSmallScreen ? "100%" : 200,
            marginRight: isSmallScreen ? 0 : "8px",
            marginTop: isSmallScreen ? "8px" : 0,
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <InfiniteScrollerTable
        TableContainerComponent={Box}
        TableStyle={{ minWidth: "650px" }}
        TableHead={() => (
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Pulled By</TableCell>
              <TableCell>Job</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Posted</TableCell>
            </TableRow>
          </TableHead>
        )}
        items={results}
        renderRow={(item, index) => {
          return (
            <TableRow
              onClick={() => navigate(`/pull-requests/${item.id}`)}
              key={index}
              sx={{
                // "& td": { border: 0 },
                cursor: "pointer",
                "&:hover": {
                  background:
                    theme.palette.mode == "dark"
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.light,
                },
              }}
            >
              <TableCell>
                {serverTimeConverter
                  .convertServerCreatedOnTimeStampToPhoenix(item.created_on)
                  .format("MM/DD/YY hh:mm A z")}
              </TableCell>
              <TableCell>{item.transactionid}</TableCell>
              <TableCell>{item.user_full_name}</TableCell>
              <TableCell>
                {item.job_name
                  ? `${item.projectid} - ${item.job_name}`
                  : "[UNLISTED]"}
              </TableCell>
              <TableCell>{item.status_description}</TableCell>
              <TableCell>
                {item.approved_on
                  ? "Yes"
                  : item.rejected_on
                  ? "Rejected"
                  : "No"}
              </TableCell>
            </TableRow>
          );
        }}
        onRefresh={onRefresh}
        onEndReached={onLoadMore}
        loading={refreshing || loadingMore}
        ListFooterComponent={() => {
          return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
        }}
      />
    </>
  );
};

export default PendingDailyStockouts;
