import { useState } from "react";

const useSearchAndPagination = (defaultBranch = "SDL") => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState();
  const [location, setLocation] = useState(defaultBranch); // DEFAULT TO SDL/ADOBE branch
  const [category, setCategory] = useState("");
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState(false);

  return {
    search,
    setSearch,
    page,
    setPage,
    pages,
    setPages,
    location,
    setLocation,
    category,
    setCategory,
    limit,
    setLimit,
    loading,
    setLoading,
  };
};

export default useSearchAndPagination;
