import { Card, Modal, Typography, Button } from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";

const ImageModalButton = ({ currentImage, onSubmit }) => {
  // TODO: do not let upload if user did not attach a new image
  const [imageModal, setImageModal] = useState({
    open: false,
    imageBlob: null,
    imageUrl: currentImage,
  });

  return (
    <div>
      <div
        style={{
          width: "100%",
          padding: 12,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setImageModal((prev) => ({ ...prev, open: true }));
          }}
        >
          {currentImage ? (
            <img
              src={currentImage}
              style={{ maxWidth: "100%", maxHeight: 300 }}
            />
          ) : (
            <div
              style={{
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#d2d2d2",
                color: "#d2d2d2",
                width: 150,
                height: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AddIcon sx={{ fontSize: 40 }} />
            </div>
          )}
        </div>
      </div>
      <Modal
        open={imageModal.open}
        onClose={() => {
          setImageModal((prev) => ({ ...prev, open: false }));
        }}
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 300,
            maxWidth: 500,
            maxHeight: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "block",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add/Change Image
          </Typography>
          <div>
            <label>
              <input
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                capture="environment"
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  console.log(
                    "Image",
                    URL.createObjectURL(event.target.files[0])
                  );
                  setImageModal((prev) => ({
                    ...prev,
                    imageBlob: event.target.files[0],
                    imageUrl: URL.createObjectURL(event.target.files[0]),
                  }));
                }}
              />
              <div
                style={{
                  cursor: "pointer",
                  width: "100%",
                  padding: 12,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {imageModal.imageUrl ? (
                  <img
                    src={imageModal.imageUrl}
                    style={{ marginTop: 16, maxWidth: "100%", maxHeight: 200 }}
                  />
                ) : (
                  <div
                    style={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#d2d2d2",
                      color: "#d2d2d2",
                      width: 150,
                      height: 150,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddIcon sx={{ fontSize: 40 }} />
                  </div>
                )}
              </div>
            </label>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              disabled={imageModal.imageBlob === null}
              variant="outlined"
              onClick={async () => {
                await onSubmit(imageModal.imageBlob);
                setImageModal((prev) => ({
                  ...prev,
                  open: false,
                  imageBlob: null,
                }));
              }}
            >
              Submit
            </Button>
          </div>
        </Card>
      </Modal>
    </div>
  );
};

export default ImageModalButton;
