import { Card, Typography } from "@mui/material";
import composePage from "../../HOCs/composePage";
import makePage from "../../HOCs/makePage";
import withConditionalFeedback from "../../HOCs/withConditionalFeedback";
import withSearchBar from "../../HOCs/withSearchBar";

const EmployeeList = ({ data, handleClick, infiniteScrollRef }) => {
  return (
    <>
      {data.map((user, index) => {
        if (data.length === index + 1) {
          return (
            <Card
              ref={infiniteScrollRef}
              sx={styles.card}
              key={index}
              onClick={() => handleClick(user)}
            >
              <Typography variant="overline" sx={styles.name}>
                {user.full_name}
              </Typography>
              <Typography variant="caption">{user.branch}</Typography>
            </Card>
          );
        } else {
          return (
            <Card
              sx={styles.card}
              key={index}
              onClick={() => handleClick(user)}
            >
              <Typography variant="overline" sx={styles.name}>
                {user.full_name}
              </Typography>
              <Typography variant="caption">{user.branch}</Typography>
            </Card>
          );
        }
      })}
    </>
  );
};
export default composePage(
  makePage,
  withSearchBar,
  withConditionalFeedback
)(EmployeeList);

const styles = {
  card: {
    padding: ".5em",
    marginBottom: ".5em",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  name: {
    fontWeight: "600",
  },
};
