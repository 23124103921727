import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthContext";
import { ThemeProvider } from "@emotion/react";
import { TopNavProvider } from "./context/TopNavContext";
import { createTheme, CssBaseline } from "@mui/material";
import { GrabsProvider } from "./context/GrabsContext";
import { PullRequestProvider } from "./context/PullRequestContext";
import { SnackAlertProvider } from "./context/SnackAlertContext";
import { AssetContextProvider } from "./context/AssetContext";
import {
  DarkLightModeContext,
  DarkLightModeProvider,
} from "./context/DarkLightModeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

/* COLOR THEORY
Primary color: 
  This is where the user’s attention goes. Calls-to-action, buttons, 
  and any other important information should utilize the primary color.
Secondary color: 
  The secondary colors are used to highlight less important elements. 
  Secondary action buttons, less important text, and anything else that 
  doesn’t need immediate attention should be presented in a secondary color.
Neutral/Additional colors: 
  Neutrals are typically used for text, backgrounds, or anything else 
  that does not need to compete for attention.
*/

// const lightTheme = createTheme({
//   palette: {
//     type: "light",
//     primary: {
//       main: "#C7AD87", // Orange
//       //main: "#e49908",
//       contrastText: "#242424", // Almost Black
//     },
//     // secondary: {
//     //   main: "#242424", // Almost black
//     //   contrastText: "#FFFFFF",
//     // },
//   },
// });

// Modify default app theme here: https://mui.com/material-ui/customization/default-theme/
const lightTheme = createTheme({
  palette: {
    mode: "light",
    type: "light",
    primary: {
      main: "#242424", // Almost black
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#C7AD87",
      light: "#f7efe4",
      // main: "#e49908",
      contrastText: "#000",
    },
  },
});

// TODO: UPDATED THEME
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#C7AD87",
      dark: "#c9964b",
      extraDark: "#ff9700",
      light: "#f7efe4",
      contrastText: "#141414",
      // main: "#A5D7CF",
      // dark: "#5fada1",
      // extraDark: "#23302e",
      // light: "#97ded3",
      // contrastText: "#141414",
    },
    secondary: {
      main: "#231F20", //black
      dark: "#181516",
      light: "#4f4b4c",
    },
    tertiary: {
      main: "#4287f5", // blue
    },
  },
});

const Main = () => {
  const { isDarkMode, toggleDarkMode } = useContext(DarkLightModeContext);
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <AuthProvider>
        <SnackAlertProvider>
          <GrabsProvider>
            <PullRequestProvider>
              <TopNavProvider>
                <AssetContextProvider>
                  <App />
                </AssetContextProvider>
              </TopNavProvider>
            </PullRequestProvider>
          </GrabsProvider>
        </SnackAlertProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

root.render(
  <React.StrictMode>
    <DarkLightModeProvider>
      <Main />
    </DarkLightModeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
