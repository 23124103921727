import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import toFixedIfNecessary from "../helpers/toFixedIfNecessary";
import { useTheme } from "@emotion/react";

const EditPoItem = ({
  poItem,
  enableUpdateQuantity,
  onUpdateOrderedQuantity,
  onUpdateReceivedQuantity,
  onSaveReceivedQuantity,
  disableDelete,
  onDelete,
  disabled,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  if (poItem.is_deleted) return null;

  if (isSmallScreen)
    return (
      <Card sx={{ marginBottom: "4px", position: "relative" }}>
        <div
          style={{
            padding: 12,
            flex: 1,
            fontSize: 12,
            fontWeight: "bold",
            display: "flex",
            //justifyContent: "center",
            // /alignItems: "center",
          }}
        >
          <Box sx={{ flex: 1, margin: 0 }}>
            <Typography variant="subtitle2">{poItem.description}</Typography>
            <Typography variant="caption">{poItem.item_comments}</Typography>
          </Box>
          {!disableDelete && (
            <CloseIcon
              style={{
                cursor: "pointer",
              }}
              color="error"
              onClick={() => {
                onDelete();
              }}
            />
          )}
        </div>
        <CardContent
          style={{
            borderRadius: 0,
            marginTop: 1,
            display: "flex",
            flexDirection: "row",
            padding: 8,
            alignItems: "center",
          }}
        >
          <TextField
            disabled={disabled || !enableUpdateQuantity}
            style={{
              width: 100,
              paddingLeft: 4,
              paddingRight: 4,
            }}
            label="Ordered"
            type="number"
            value={`${poItem.quantity}`}
            onChange={(e) => {
              let val;
              if (
                e.target.value == "" ||
                e.target.value === undefined ||
                e.target.value === null ||
                e.target.value === "0" ||
                e.target.value === "00"
              ) {
                val = 0;
              } else {
                val = e.target.value.replace(/^0+/, "");
                val = toFixedIfNecessary(val, 2);
              }
              // Do Not Allow Negative
              if (val < 0) {
                val = 0;
              }

              onUpdateOrderedQuantity(val);
            }}
          />

          <TextField
            disabled={disabled}
            style={{
              width: 100,
              paddingLeft: 4,
              paddingRight: 4,
            }}
            label="Received"
            type="number"
            value={`${poItem.qty_received}`}
            onChange={(e) => {
              let val;
              if (
                e.target.value == "" ||
                e.target.value === undefined ||
                e.target.value === null ||
                e.target.value === "0" ||
                e.target.value === "00"
              ) {
                val = 0;
              } else {
                val = e.target.value.replace(/^0+/, "");
                val = toFixedIfNecessary(val, 2);
              }
              // Do Not Allow Negative
              if (val < 0) {
                val = 0;
              }

              onUpdateReceivedQuantity(val);
            }}
          />
          <div
            style={{
              width: 80,
              fontSize: 12,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ margin: 0, textAlign: "center" }}>
              {poItem.unit_measure_desc}
            </p>
          </div>
        </CardContent>
      </Card>
    );
  else
    return (
      <div>
        <div>
          <div
            style={{
              borderRadius: 0,
              marginTop: 1,
              display: "flex",
              flexDirection: "row",
              padding: 8,
              position: "relative",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                fontSize: 12,
                fontWeight: "bold",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="subtitle2" sx={{ flex: 1, margin: 0 }}>
                {poItem.description}
              </Typography>
              <Typography variant="caption">{poItem.item_comments}</Typography>
            </div>

            <div
              style={{
                width: 80,
                fontSize: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ margin: 0, textAlign: "center" }}>
                {poItem.unit_measure_desc}
              </p>
            </div>
            {enableUpdateQuantity ? (
              <TextField
                disabled={disabled}
                style={{
                  width: 100,
                  paddingLeft: 4,
                  paddingRight: 4,
                }}
                label="Ordered"
                type="number"
                value={`${poItem.quantity}`}
                onChange={(e) => {
                  let val;
                  if (
                    e.target.value == "" ||
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === "0" ||
                    e.target.value === "00"
                  ) {
                    val = 0;
                  } else {
                    val = e.target.value.replace(/^0+/, "");
                    val = toFixedIfNecessary(val, 2);
                  }
                  // Do Not Allow Negative
                  if (val < 0) {
                    val = 0;
                  }

                  onUpdateOrderedQuantity(val);
                }}
              />
            ) : (
              <div style={{ width: 100, fontSize: 12 }}>
                <p
                  style={{
                    margin: 0,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {poItem.quantity}
                </p>
              </div>
            )}

            <TextField
              disabled={disabled}
              style={{
                width: 100,
                paddingLeft: 4,
                paddingRight: 4,
              }}
              label="Received"
              type="number"
              value={`${poItem.qty_received}`}
              onChange={(e) => {
                let val;
                if (
                  e.target.value == "" ||
                  e.target.value === undefined ||
                  e.target.value === null ||
                  e.target.value === "0" ||
                  e.target.value === "00"
                ) {
                  val = 0;
                } else {
                  val = e.target.value.replace(/^0+/, "");
                  val = toFixedIfNecessary(val, 2);
                }
                // Do Not Allow Negative
                if (val < 0) {
                  val = 0;
                }

                onUpdateReceivedQuantity(val);
              }}
            />
            {!disableDelete && (
              <CloseIcon
                style={{
                  cursor: "pointer",
                }}
                color="error"
                onClick={() => {
                  onDelete();
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
};

export default EditPoItem;
