import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useState, useEffect, useContext } from "react";
import api from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ToolRequests = () => {
  const { authToken, role } = useContext(AuthContext);
  const navigate = useNavigate();

  const location = useLocation();
  const isAllRequests = (path) => {
    const regexp = new RegExp("/tools/requests");
    return !!path.match(regexp);
  };

  const allRequestsPage = isAllRequests(location.pathname);

  const [requests, setRequests] = useState();

  const getToolRequests = async () => {
    try {
      const response = await api.get(`/tools/requests`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      // console.log(response);
      setRequests(response.data.allRequests);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getToolRequests();
  }, []);

  const approveOrRejectRequest = async (trueOrFalse, requestId) => {
    try {
      console.log("APPROVING");
      const response = await api.post(
        `/tools/requests/${requestId}`,
        {
          response: trueOrFalse,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // console.log(response);
      getToolRequests();
    } catch (error) {
      console.log(error);
    }
  };

  if (!requests) return <div>Loading...</div>;

  return (
    <div style={{ marginTop: 70 }}>
      <Box>
        {requests !== null &&
          requests?.length > 0 &&
          requests.map((req, i) => (
            <Card key={i} sx={{ display: "flex", flexDirection: "row" }}>
              <CardContent>
                {allRequestsPage ? (
                  <>
                    <Typography sx={{ fontWeight: "700" }} display="inline">
                      Approve Transfer of{" "}
                    </Typography>
                    <Typography
                      sx={{ textDecoration: "underline" }}
                      display="inline"
                    >
                      {req.asset_name}
                    </Typography>
                    <Typography sx={{ fontWeight: "700" }} display="inline">
                      {" "}
                      to:{" "}
                    </Typography>
                  </>
                ) : (
                  <Typography sx={{ fontWeight: "700" }} display="inline">
                    Approve Transfer to:
                  </Typography>
                )}
                <Typography
                  sx={{ textDecoration: "underline" }}
                  display="inline"
                >
                  {req.potential_assignee_name
                    ? req.potential_assignee_name
                    : "YARD"}
                </Typography>
                ?
              </CardContent>
              <CardActions>
                <IconButton
                  sx={{ color: "green" }}
                  onClick={() => approveOrRejectRequest(true, req.id)}
                >
                  <CheckCircleOutlineOutlinedIcon fontSize="large" />
                </IconButton>
                <IconButton
                  sx={{ color: "red" }}
                  onClick={() => approveOrRejectRequest(false, req.id)}
                >
                  <CancelOutlinedIcon fontSize="large" />
                </IconButton>
              </CardActions>
            </Card>
          ))}
        {requests !== null && requests?.length === 0 && isAllRequests && (
          <Typography textAlign={"center"} variant="h4">
            No requests to display
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default ToolRequests;
