import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import toFixedIfNecessary from "../helpers/toFixedIfNecessary";

const DebouncedTextField = (props) => {
  const { value, delay, onChange } = props;
  const [val, setVal] = useState(value);
  const [debouncedValue] = useDebounce(val, delay);

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    let e = {
      target: {
        value: debouncedValue,
      },
    };
    onChange(e);
  }, [debouncedValue]);

  return (
    <TextField
      {...props}
      type="number"
      value={val}
      onChange={(e) => {
        console.log("changing...");
        // setVal(e.target.value);

        let numberVal;
        if (
          e.target.value == "" ||
          e.target.value === undefined ||
          e.target.value === null ||
          e.target.value === "0" ||
          e.target.value === "00"
        ) {
          numberVal = 0;
        } else {
          numberVal = e.target.value.replace(/^0+/, "");
          numberVal = toFixedIfNecessary(numberVal, 2);
        }
        // Do Not Allow Negative
        if (numberVal < 0) {
          numberVal = 0;
        }
        setVal(numberVal);
      }}
    />
  );
};

export default DebouncedTextField;
