import api from "../../../../api/InventoryTracking";
import axios from "axios";
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { AssetContext } from "../../../../context/AssetContext";
import escapeCharacters from "../../../../helpers/escapeCharacters";
import EmployeeListView from "./EmployeeListView";

const EmployeeListComponent = () => {
  const { userBranch, authToken } = useContext(AuthContext);
  const [isLoading, setIsloading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null);

  const [search, setSearch] = useState("");
  const [branch, setBranch] = useState(userBranch);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const observer = useRef();
  const lastElement = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < pages) {
          console.log("visible");
          setPage((curr) => curr + 1);
        }
      });
      if (node) observer.current.observe(node);
      // console.log("NODE: ", node);
    },
    [isLoading, page < pages]
  );

  useEffect(() => {
    setIsloading(true);
    const controller = new AbortController();
    api
      .get(
        `/employees?search=${escapeCharacters(
          search
        )}&location=${branch}&pageSize=25&page=${page}`,
        {
          signal: controller.signal,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        console.log("EMPLOYEES: ", res);
        setPages(res.data.pages);
        if (page > 1) {
          setEmployees((curr) => [...curr, ...res.data.employees]);
        } else {
          setEmployees([...res.data.employees]);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("request canceled");
        } else {
          setError(err);
          console.log("ERROR: ", err);
        }
      })
      .finally(() => setIsloading(false));
    return () => controller.abort();
  }, [, page, branch, search]);

  return (
    <>
      <EmployeeListView
        data={employees}
        callback={lastElement}
        isLoading={isLoading}
        error={error}
        branchOptions={["SDL", "FLG"]}
        branch={branch}
        setBranch={setBranch}
        search={search}
        setSearch={setSearch}
      />
    </>
  );
};
export default EmployeeListComponent;
