import { Card, Typography, useMediaQuery, Box, Button } from "@mui/material";
import { useState, useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import jwt_decode from "jwt-decode";
import DamageReport from "./DamageReport/DamageReport";
import { useTheme } from "@emotion/react";

const RequestCard = ({ request, handleAcceptReject }) => {
  const { authToken } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [damageModal, setDamageModal] = useState(false);
  const toggleModal = () => setDamageModal(!damageModal);
  const user = jwt_decode(authToken);
  const makeDataRelevant = (data) => {
    const isOwnRequest = data.sender_user_id === user.id;

    const who = isOwnRequest ? "you" : data.sender_user_name;
    const wantsTo =
      data.target_user_id === user.id
        ? "ASSIGN"
        : !data.target_user_id
        ? "RETURN"
        : "TAKE";
    const asset = data.asset_name;

    return { isOwnRequest, who, wantsTo, asset };
  };
  const { isOwnRequest, who, wantsTo, asset } = makeDataRelevant(request);

  const respondToRequest = (bool) => {
    const {
      id,
      asset_id,
      asset_type,
      asset_name,
      target_user_id,
      target_user_name,
    } = request;
    handleAcceptReject(
      bool,
      id,
      asset_id,
      asset_type,
      asset_name,
      target_user_id,
      target_user_name
    );
  };

  return (
    <>
      <Card
        sx={{
          width: isSmallScreen ? "100%" : "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: "1em",
          p: 1,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
          <Typography
            variant="overline"
            sx={{ textDecoration: "underline", lineHeight: "1.5em" }}
          >
            {who}
          </Typography>
          <Typography variant="overline" sx={{ lineHeight: "1.5em" }}>
            {" "}
            {who === "you" ? "requested" : "want's to"}
          </Typography>
          <Typography
            variant="overline"
            sx={{ lineHeight: "1.5em", fontWeight: "600" }}
          >
            {" "}
            {who !== "you" && wantsTo}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
          <Typography
            variant="overline"
            sx={{ lineHeight: "1.5em", textDecoration: "underline" }}
          >
            {asset}
          </Typography>
          <Typography variant="overline" sx={{ lineHeight: "1.5em" }}>
            {who !== "you" && wantsTo === "ASSIGN" && " To You"}
            {wantsTo === "TAKE" && " From You"}
          </Typography>
        </Box>
        {!isOwnRequest && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1em",
              mt: "1em",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                if (request.action === "return") {
                  toggleModal();
                }
                respondToRequest(true);
              }}
              size="small"
            >
              Accept?
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => respondToRequest(false)}
            >
              Reject?
            </Button>
          </Box>
        )}
      </Card>
      <DamageReport
        open={damageModal}
        toggleOpen={toggleModal}
        request={request}
      />
    </>
  );
};
export default RequestCard;
