import { Box, Typography } from "@mui/material";
import serverTimeConverter from "../../../helpers/serverTimeConverter";

const ToolPossessionHistory = ({ logs = [] }) => {
  // console.log(logs);
  return (
    <>
      <Typography fontWeight={700}>Logs: </Typography>
      {logs.map((log, i) => (
        <Box key={i} sx={{ marginLeft: "15px" }}>
          <Typography
            display="inline"
            sx={{ fontWeight: "700", textDecoration: "underline" }}
          >
            {log.current_assignee_name ? log.current_assignee_name : "the yard"}
          </Typography>
          <Typography display="inline"> got it from </Typography>
          <Typography
            display="inline"
            sx={{ fontWeight: "700", textDecoration: "underline" }}
          >
            {log.previous_assignee_name
              ? log.previous_assignee_name
              : "the yard"}
          </Typography>
          <Typography display="inline"> on </Typography>
          <Typography
            display="inline"
            sx={{ fontWeight: "700", textDecoration: "underline" }}
          >
            {serverTimeConverter
              .convertServerCreatedOnTimeStampToPhoenix(log.date_assigned)
              .format("MM/DD/YY hh:mm A z")}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default ToolPossessionHistory;
