import composePage from "../../HOCs/composePage";
import makePage from "../../HOCs//makePage";
import withConditionalFeedback from "../../HOCs/withConditionalFeedback";

import { Box, Typography } from "@mui/material";

import RequestCard from "./RequestCard";

const RequestsView = ({ data, isAssetManager, handleAcceptReject }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box mb={"2em"}>
          <Typography variant="h4">
            {isAssetManager && "Return Requests"}
          </Typography>
        </Box>
        {data.map((req, i) => (
          <RequestCard
            key={i}
            request={req}
            handleAcceptReject={handleAcceptReject}
          />
        ))}
      </Box>
    </>
  );
};

export default composePage(makePage, withConditionalFeedback)(RequestsView);
