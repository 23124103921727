import {
  Card,
  Divider,
  ListItemText,
  ListItem as MuiListItem,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { DarkLightModeContext } from "../context/DarkLightModeContext";
import { useTheme } from "@emotion/react";

const ListItem = ({
  title,
  details,
  image,
  onClick,
  emphasize,
  highlight,
  highlightColor,
  highlightOn,
  iconRight,
  ...rest
}) => {
  const { isDarkMode } = useContext(DarkLightModeContext);
  const theme = useTheme();
  return (
    <>
      <MuiListItem
        alignItems="flex-start"
        onClick={onClick}
        sx={{
          // background: highlightOn
          //   ? highlightColor
          //     ? highlightColor
          //     : "default"
          //   : "default",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // padding: 8,
          cursor: "pointer",
          "&:hover": {
            background: isDarkMode ? "#5A5A5A" : theme.palette.secondary.light,
          },
        }}
        {...rest}
      >
        <ListItemText
          primary={title}
          secondary={
            <>
              {details &&
                details.map((detail, index) => (
                  <Typography
                    key={index}
                    style={{
                      fontSize: 12,
                      color:
                        highlight && highlight.includes(detail)
                          ? highlightColor
                          : "inherit",
                      fontWeight:
                        emphasize && emphasize.includes(detail)
                          ? "bold"
                          : "normal",
                    }}
                  >
                    {detail}
                  </Typography>
                ))}
            </>
          }
        />

        {image && (
          <div style={{ width: 100, padding: 12 }}>
            <img src={image} style={{ width: "100%", aspectRatio: 15 / 20 }} />
          </div>
        )}
        {iconRight && iconRight()}
      </MuiListItem>
      <Divider />
    </>
  );
};

export default ListItem;
