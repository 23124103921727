import React, {
  ReactDOM,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  FilledInput,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  Box,
  TextareaAutosize,
  Grid,
} from "@mui/material";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { GrabsContext } from "../../../context/GrabsContext";

import {
  Search,
  Add,
  Edit,
  CheckBox,
  AddAPhoto,
  FileUpload,
  Close,
} from "@mui/icons-material";
import { useLocation, useParams } from "react-router-dom";
import CustomQrCodeScanner from "../../../component/CustomQrCodeScanner";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { TopNavContext } from "../../../context/TopNavContext";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import escapeCharacters from "../../../helpers/escapeCharacters";

import CategorySearch from "../../../component/CategorySearch";
import PulledItemCard from "../../../component/PulledItemCard";
import PageContent from "../../../component/PageContent";
import toFixedIfNecessary from "../../../helpers/toFixedIfNecessary";
import { useNavigate } from "react-router-dom";
import { fontSize } from "@mui/system";
import SelectTruckModal from "../Jobs/JobDetails/Tabs/SelectTruckModal";
import ListItem from "../../../component/ListItem";
import { useTheme } from "@emotion/react";
import imageCompression from "browser-image-compression";
import { DarkLightModeContext } from "../../../context/DarkLightModeContext";

const CreateStockout = () => {
  const { isDarkMode } = useContext(DarkLightModeContext);
  const { jobId } = useParams();
  let navigate = useNavigate();
  const { authToken, userBranch } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const {
    grabFromInventory,
    grabFromBoneyard,
    grabFromCommitted,
    grabCount,
    grabs,
    updateInventoryGrabQuantity,
    removeInventoryGrab,
    updateBoneyardGrabQuantity,
    removeBoneyardGrab,
    updateCommittedGrabQuantity,
    removeCommittedGrab,
    // setDeliveryLocation,
    setStatus,
    setNotes,
    setSelectedJob,
    clearSelectedJob,
    clearGrabs,
  } = useContext(GrabsContext);

  // Image Upload
  const uploadRef = useRef(null);
  const cameraRef = useRef(null);
  const [imageDetails, setImageDetails] = useState({
    images: [],
    imageBlobs: [],
    imageUrls: [],
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [qrScan, setQrScan] = useState("");
  const [scannedItem, setScannedItem] = useState(null);

  const [loading, setLoading] = useState(false);

  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("SDL");
  // const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]); // pull options is status
  const [search, setSearch] = useState("");
  const [jobs, setJobs] = useState([]);
  const [isEditingJob, setIsEditingJob] = useState(false);
  const [isUnlisted, setIsUnlisted] = useState(false);

  const { setPageName } = useContext(TopNavContext);

  // INVENTORY SEARCH BY CATEGORY ----------------------------
  const [category, setCategory] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [categorySearchResults, setCategorySearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [addMore, setAddMore] = useState(false);
  const [selectTruckModalOpen, setSelectTruckModalOpen] = useState(false);

  // Uploads an Array Of Images
  // Returns an Array of Entity Image object with id, entity_type, image_url
  const uploadImages = async () => {
    let imageFormDataArray = [];

    let x = Math.ceil(imageDetails.imageBlobs.length / 15);
    console.log("x = ", x);
    for (let i = 0; i < x; i++) {
      let imgFormData = new FormData();
      for (
        let j = 15 * i;
        j < 15 * (i + 1) && j < imageDetails.imageBlobs.length;
        j++
      ) {
        imgFormData.append("images", imageDetails.imageBlobs[j]);
      }
      console.log(imgFormData);
      imageFormDataArray.push(imgFormData);
    }
    console.log("imageFormDataArray", imageFormDataArray);
    let imageUrls = [];
    for (let data of imageFormDataArray) {
      data.append("resourceType", "public/pull");
      let response = await InventoryTracking.post(`/uploads/images`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "content-type": "multipart/form-data",
        },
      });
      imageUrls = [...imageUrls, ...response.data.files];
    }
    console.log("imageUrls uploaded", imageUrls);
    let images = [];
    for (let img of imageUrls) {
      images.push({
        image_url: img.url,
      });
    }
    return images;
  };

  const getEndpoint = (category) => {
    switch (category) {
      case "Inventory":
        return "/inventory";
      case "Boneyard":
        return "/boneyard";
      case "Committed":
        return "/committed";

      default:
        break;
    }
  };
  const getResponseData = (category, apiResponse) => {
    switch (category) {
      case "Inventory":
        return {
          items: apiResponse.data.materials,
          pages: apiResponse.data.pages,
        };
      case "Boneyard":
        return {
          items: apiResponse.data.materials,
          pages: apiResponse.data.pages,
        };
      case "Committed":
        return {
          items: apiResponse.data.committed.committed,
          pages: apiResponse.data.committed.pages,
        };

      default:
        break;
    }
  };

  const handleCategorySearch = async (controller) => {
    try {
      if (!categorySearch) {
        setCategorySearchResults([]);
        return;
      }
      setLoading(true);
      let headers = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        headers.signal = controller.signal;
      }
      const repo = getEndpoint(category);
      const query = escapeCharacters(categorySearch);
      const response = await InventoryTracking.get(
        `${repo}?search=${query}&location=${location}&pageSize=20&page=${page}`,
        headers
      );
      const data = getResponseData(category, response);
      console.log(data);
      setLoading(false);
      setCategorySearchResults([...data.items]);
      setPages(data.pages);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleClearPullData = () => {
    if (jobId) {
      console.log("clearGrabs(true)");
      clearGrabs(true);
    } else {
      console.log("clearGrabs(false)");
      clearGrabs(false);
    }
  };

  // ----------------------
  // INFINITE SCROLLING
  const observer = useRef();
  const lastElement = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < pages) {
          console.log("visible");
          setPage((curr) => curr + 1);
        }
      });
      if (node) observer.current.observe(node);
      // console.log("NODE: ", node);
    },
    [loading, page < pages]
  );
  //

  const getLocations = async () => {
    try {
      const response = await InventoryTracking.get(`/locations`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setLocations(response.data.locations);
    } catch (error) {
      console.log(error);
    }
  };

  // const getDeliveryLocations = async () => {
  //   try {
  //     const response = await InventoryTracking.get(`/delivery-locations`, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });
  //     console.log(response.data);
  //     setDeliveryLocations(response.data.deliveryLocations);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getStatusOptions = async () => {
    try {
      const response = await InventoryTracking.get(`/pulls/statuses`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setStatusOptions(response.data.statuses);
    } catch (error) {
      console.log(error);
    }
  };

  const getJobs = async () => {
    try {
      const response = await InventoryTracking.get(
        `/jobs?search=${escapeCharacters(search)}&location=${location}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      setJobs(response.data.jobs);
    } catch (error) {
      console.log(error);
    }
  };

  const getJobDetails = async (id) => {
    try {
      const response = await InventoryTracking.get(`/jobs/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let job = response.data;
      setSelectedJob(job.id, job.job_name, job.projectid);
      setIsUnlisted(false);
      setIsEditingJob(false);
    } catch (error) {
      console.log(error);
    }
  };

  const submitGrabs = async (status, transfer_vehicle_id = null) => {
    try {
      setLoading(true);
      if (grabs.selectedJob) grabs.project_backlog_id = grabs.selectedJob.id;

      // Remove any 0 quantity items from grabs
      let grabsCopy = { ...grabs };
      let invCopy = [];
      let boneyardCopy = [];
      let committedCopy = [];

      for (let item of grabsCopy.inventory) {
        if (item.quantity > 0) {
          invCopy.push(item);
        }
      }
      for (let item of grabsCopy.boneyard) {
        if (item.quantity > 0) {
          boneyardCopy.push(item);
        }
      }
      for (let item of grabsCopy.committed) {
        if (item.quantity > 0) {
          committedCopy.push(item);
        }
      }

      // Upload Images and Add to Object
      const newImages = await uploadImages();
      let images = [...imageDetails.images, ...newImages];
      let allImageCount = images.length;
      if (allImageCount > 30) {
        throw "You can only upload up to 30 images";
      }

      grabsCopy = {
        ...grabsCopy,
        inventory: invCopy,
        boneyard: boneyardCopy,
        committed: committedCopy,
        status,
        transfer_vehicle_id,
        images,
      };

      const response = await InventoryTracking.post(`/stockouts`, grabsCopy, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      handleClearPullData();
      setIsUnlisted(false);
      openSnackMessage("success", "Stock Out created");
      navigate(`/daily-stockouts`);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const getItemDetailFromScan = async (scannedUrl) => {
    try {
      setLoading(true);

      if (scannedUrl.includes("/jobs/")) {
        let jobId = scannedUrl.split("/jobs/")[1];
      } else {
        // Parse Item QR Code URL
        let itemUri;
        let itemId;
        if (scannedUrl.includes("/inventory/")) {
          itemUri = "inventory";
          itemId = scannedUrl.split("/inventory/")[1];
        } else if (scannedUrl.includes("/boneyard/")) {
          itemUri = "boneyard";
          itemId = scannedUrl.split("/boneyard/")[1];
        } else if (scannedUrl.includes("/committed/")) {
          itemUri = "committed";
          itemId = scannedUrl.split("/committed/")[1];
        }

        console.log(`getting item /${itemUri}/${itemId}`);
        // Get Item Details
        const response = await InventoryTracking.get(`/${itemUri}/${itemId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        let material = response.data.material;
        console.log(material);

        // Set Modal Data
        if (scannedUrl.includes("/inventory/")) {
          setScannedItem({
            source: itemUri,
            details: material,
            name: material.description,
            image: material.image_url,
            uom: material.uom,
            quantity: 1,
          });
        } else if (scannedUrl.includes("/boneyard/")) {
          setScannedItem({
            source: itemUri,
            details: material,
            name: material.item,
            image: material.image_url,
            uom: material.uom,
            quantity: 1,
          });
        } else if (scannedUrl.includes("/committed/")) {
          setScannedItem({
            source: itemUri,
            details: material,
            name: material.item_description,
            image: material.image_url,
            uom: material.uom,
            quantity: 1,
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const grabItem = async (item, type) => {
    try {
      setLoading(true);
      let itemId;
      switch (type) {
        case "Inventory":
          itemId = item.inventoryId;
          break;
        case "Committed":
          itemId = item.id;
          break;
        case "Boneyard":
          itemId = item.id;
          break;

        default:
          break;
      }

      const response = await InventoryTracking.get(`/${type}/${itemId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      let material = response.data.material;
      console.log("MATERIAL: ", material);

      // Set Modal Data
      if (type === "Inventory") {
        grabFromInventory(material, 1);
      } else if (type === "Boneyard") {
        grabFromBoneyard(material, 1);
      } else if (type === "Committed") {
        if (
          (grabs.committed.length > 0 || grabs.staged.length > 0) &&
          grabs.selectedJob.projectId !== item.job_id
        ) {
          openSnackMessage(
            "warning",
            `
                    This material is committed to ${item.job_name} 
                    (${item.job_id}). You are currently pulling
                    materials committed to or staged for 
                    ${grabs.selectedJob.jobName} (${grabs.selectedJob.projectId}).
                    You must remove the committed and staged materials before
                    pulling materials committed to another job.
                  `
          );
        } else {
          grabFromCommitted(material, 1);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const grabScannedItem = async () => {
    try {
      setLoading(true);
      if (scannedItem.source === "inventory") {
        // Add Item To Grabs
        grabFromInventory(scannedItem.details, scannedItem.quantity);
      } else if (scannedItem.source === "boneyard") {
        // Add Item To Grabs
        grabFromBoneyard(scannedItem.details, scannedItem.quantity);
      } else if (scannedItem.source === "committed") {
        // TODO: grab from committed
        grabFromCommitted(scannedItem.details, scannedItem.quantity);
      }
      openSnackMessage("success", "Item Added");
    } catch (error) {
      console.log(error);
    } finally {
      setScannedItem(null);
      setQrScan("");
      setLoading(false);
    }
  };

  const handleFileSelect = async (event) => {
    const originalFile = event.target.files[0];
    const originalFileUrl = URL.createObjectURL(originalFile);
    console.log("originalFile", originalFile);
    console.log("originalFileURL", originalFileUrl);
    console.log("originalFile instanceof Blob", originalFile instanceof Blob); // true
    console.log(`originalFile size ${originalFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    // Compress File (returns Blob instead of File)
    let compressedBlob = await imageCompression(originalFile, options);
    // Convert Compressed Blob back to File
    let compressedFile = new File([compressedBlob], originalFile.name, {
      type: originalFile.type,
    });
    const compressedFileUrl = URL.createObjectURL(compressedFile);

    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    console.log("compressedFile", compressedFile);
    console.log("compressedFileURL", compressedFileUrl);
    setImageDetails((prev) => ({
      ...prev,
      imageBlobs: [...prev.imageBlobs, compressedFile],
      imageUrls: [...prev.imageUrls, compressedFileUrl],
    }));
  };

  useEffect(() => {
    const controller = new AbortController();
    setTimeout(() => {
      handleCategorySearch(controller);
      setPage(1);
    }, 300);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [categorySearch, category]);

  useEffect(() => {
    getLocations();
    getStatusOptions();
  }, []);

  useEffect(() => {
    if (jobId) {
      getJobDetails(jobId);
    } else {
      getJobs();
    }
  }, [jobId, location, search]);

  useEffect(() => {
    console.log("qrScan", qrScan);
    if (qrScan) {
      // Get Item Details from qr scan link
      // Add to grabs
      getItemDetailFromScan(qrScan);
    }
  }, [qrScan]);

  useEffect(() => {
    setPageName("Quick Pull");
  }, []);

  useEffect(() => {
    console.log("GRABS CONTEXT CHANGES", grabs);
  }, [grabs]);

  return (
    <PageContent
      style={{
        position: "relative",
      }}
    >
      <h2 style={{ textAlign: "center" }}>Stock Out</h2>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          size="small"
          startIcon={
            <RestartAltIcon
              style={{
                width: "40px",
                height: "40px",
              }}
            />
          }
          variant="text"
          style={{}}
          onClick={() => {
            handleClearPullData();
          }}
        >
          Restart
        </Button>
      </div>

      <div style={{}}>
        {/* JOB SECTION */}
        {isUnlisted ? (
          <h3
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              setIsEditingJob(true);
            }}
          >
            For Unlisted Job (Add details to notes section){" "}
            {!isEditingJob && (
              <Edit
                onClick={() => {
                  setIsEditingJob(true);
                }}
              />
            )}
          </h3>
        ) : grabs.selectedJob ? (
          <h3
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              if (!jobId) {
                if (grabs.committed.length > 0) {
                  openSnackMessage(
                    "warning",
                    `You've grabbed items that are committed to or staged for ${grabs.selectedJob.jobName} (${grabs.selectedJob.projectId}). You must remove all committed and staged items to change the job selection`
                  );
                } else {
                  setIsEditingJob(true);
                }
              }
            }}
          >
            For Job: {grabs.selectedJob.jobName} ({grabs.selectedJob.projectId})
            {!isEditingJob && !jobId && <Edit />}
          </h3>
        ) : (
          <h3 style={{ display: "flex", alignItems: "center" }}>Select Job:</h3>
        )}
        {(isEditingJob || (!grabs.selectedJob && !isUnlisted)) && (
          <div style={{ marginTop: 16, paddingLeft: 16, paddingRight: 16 }}>
            <FormControl fullWidth style={{}}>
              <InputLabel>Location</InputLabel>
              <Select
                value={location}
                label="Location"
                onChange={(e) => {
                  let selectedLocation = locations.find(
                    (element) => element.location === e.target.value
                  );
                  setLocation(selectedLocation.location);
                }}
              >
                {locations.map((location, index) => (
                  <MenuItem key={index} value={location.location}>
                    {location.location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FilledInput
              style={{ marginTop: 16, width: "100%" }}
              type="text"
              hiddenLabel={true}
              value={search}
              placeholder="Search Job Name"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
            />
            <div
              style={{
                display: "block",
                maxHeight: 200,
                maxWidth: "100%",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              {jobs.map((job, index) => (
                <ListItem
                  title={`${job.projectid} - ${job.job_name}`}
                  details={[job.address]}
                  onClick={() => {
                    setSelectedJob(job.id, job.job_name, job.projectid);
                    setIsUnlisted(false);
                    setIsEditingJob(false);
                  }}
                />
              ))}
            </div>
            <div
              style={{
                flex: 1,
                fontSize: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isUnlisted}
                    onChange={(e) => {
                      setIsUnlisted(e.target.checked);
                      // setSelectedJob(null);
                      clearSelectedJob();
                      if (e.target.checked) setIsEditingJob(false);
                    }}
                  />
                }
                label="Unlisted Job? / Can't Find"
              />
            </div>
          </div>
        )}
      </div>

      {grabs.inventory.map((grabbed, grabbedIndex) => (
        <div key={grabbed.material_id}>
          <PulledItemCard
            itemName={grabbed.item_description}
            source="Inventory"
            uom={grabbed.uom}
            qtyPulled={grabbed.quantity}
            onQtyChange={(value) => {
              let val;
              if (
                value == "" ||
                value === undefined ||
                value === null ||
                value === "0" ||
                value === "00"
              ) {
                val = 0;
              } else {
                val = value.replace(/^0+/, "");
                val = toFixedIfNecessary(val, 2);
              }
              // Do Not Allow Negative
              if (val < 0) {
                val = 0;
              }
              updateInventoryGrabQuantity(grabbed.material_id, val);
            }}
            onRemove={() => removeInventoryGrab(grabbed.material_id)}
          />
        </div>
      ))}
      {grabs.committed.map((grabbed, grabbedIndex) => (
        <div key={grabbed.material_id}>
          <PulledItemCard
            itemName={grabbed.item_description}
            source="Committed"
            uom={grabbed.uom}
            qtyPulled={grabbed.quantity}
            onQtyChange={(value) => {
              let val;
              if (
                value == "" ||
                value === undefined ||
                value === null ||
                value === "0" ||
                value === "00"
              ) {
                val = 0;
              } else {
                val = value.replace(/^0+/, "");
                val = toFixedIfNecessary(val, 2);
              }
              // Do Not Allow Negative
              if (val < 0) {
                val = 0;
              }
              updateCommittedGrabQuantity(grabbed.material_id, val);
            }}
            onRemove={() => removeCommittedGrab(grabbed.material_id)}
          />
        </div>
      ))}

      {grabs.boneyard.map((grabbed, grabbedIndex) => (
        <div key={grabbed.material_id}>
          <PulledItemCard
            itemName={grabbed.item_description}
            source="Boneyard"
            uom={grabbed.uom}
            qtyPulled={grabbed.quantity}
            onQtyChange={(value) => {
              let val;
              if (
                value == "" ||
                value === undefined ||
                value === null ||
                value === "0" ||
                value === "00"
              ) {
                val = 0;
              } else {
                val = value.replace(/^0+/, "");
                val = toFixedIfNecessary(val, 2);
              }
              // Do Not Allow Negative
              if (val < 0) {
                val = 0;
              }
              updateBoneyardGrabQuantity(grabbed.material_id, val);
            }}
            onRemove={() => removeBoneyardGrab(grabbed.material_id)}
          />
        </div>
      ))}

      {!addMore ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              height: 50,
              marginTop: 16,
              width: 200,
            }}
            fullWidth
            onClick={() => setAddMore(true)}
            startIcon={<Add />}
          >
            MATERIAL
          </Button>
        </div>
      ) : (
        <div style={{ marginTop: 24, marginBottom: 32 }}>
          <CategorySearch
            categoryOptions={["Inventory", "Committed", "Boneyard"]}
            categoryValue={category}
            searchValue={categorySearch}
            results={categorySearchResults}
            handleResultClick={(item) => {
              grabItem(item, category);
              setCategorySearch("");
              setAddMore(false);
            }}
            handleSearch={(e) => setCategorySearch(e.target.value)}
            handleSelect={(e) => setCategory(e.target.value)}
            onQrCodeScan={(result) => {
              setQrScan(result);
              setAddMore(false);
            }}
          />
        </div>
      )}

      <div
        style={{
          width: "100%",
          marginTop: 8,
        }}
      >
        <FormControl fullWidth>
          <TextareaAutosize
            minRows={5}
            aria-label="empty textarea"
            placeholder="Add Notes"
            style={{
              width: "100%",
              marginTop: 16,
              background: isDarkMode
                ? "#232323"
                : theme.palette.primary.extraDark,
              color: theme.palette.secondary.contrastText,
              borderColor: theme.palette.primary.main,
            }}
            value={grabs.notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
        </FormControl>
      </div>

      {/* Add Image(s) Section - At least 1 image required */}
      <div>
        <h4>Images</h4>
        <Typography
          id="modal-modal-title"
          variant="p"
          component="p"
          sx={{ margin: 0 }}
        >
          Add images here (optional). Maximum 30 images
        </Typography>

        <div
          style-={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            padding={0}
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            {
              //Saved Images
              imageDetails &&
                imageDetails.images &&
                imageDetails.images.map((entity_image, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <img
                      onClick={() => {
                        window.open(
                          entity_image.image_url,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                      src={entity_image.image_url}
                      style={{
                        cursor: "pointer",
                        minHeight: "100px",
                        maxHeight: "100px",
                        minWidth: "100px",
                        maxWidth: "100px",
                        marginRight: "3px",
                        border: "1px solid black",
                      }}
                    />

                    <Close
                      color="error"
                      style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        let imagesCopy = imageDetails.images;
                        imagesCopy.splice(index, 1);
                        setImageDetails((prev) => ({
                          ...prev,
                          images: imagesCopy,
                        }));
                      }}
                    />
                  </div>
                ))
            }
            {
              //Unsaved Images
              imageDetails &&
                imageDetails.imageUrls &&
                imageDetails.imageUrls.length > 0 &&
                imageDetails.imageUrls.map((url, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <img
                      src={url}
                      style={{
                        minHeight: "100px",
                        maxHeight: "100px",
                        minWidth: "100px",
                        maxWidth: "100px",
                        marginRight: "3px",
                        border: "1px solid black",
                      }}
                    />
                    <Close
                      color="error"
                      style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                      }}
                      onClick={() => {
                        // Remove unsaved image from imageBlobs and imageUrls arrays
                        let imageBlobsCopy = imageDetails.imageBlobs;
                        let imageUrlsCopy = imageDetails.imageUrls;
                        imageBlobsCopy.splice(index, 1);
                        imageUrlsCopy.splice(index, 1);
                        setImageDetails((prev) => ({
                          ...prev,
                          imageBlobs: imageBlobsCopy,
                          imageUrls: imageUrlsCopy,
                        }));
                      }}
                    />
                  </div>
                ))
            }
          </Grid>

          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "4px",
            }}
          >
            <label>
              <input
                ref={uploadRef}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                name="images"
                // capture="environment"
                onChange={(event) => {
                  handleFileSelect(event);
                }}
              />
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  uploadRef.current.click();
                }}
              >
                <FileUpload />
                Upload
              </Button>
            </label>
            <label>
              <input
                ref={cameraRef}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                name="images"
                capture="environment"
                onChange={(event) => {
                  handleFileSelect(event);
                }}
              />
              <Button
                size="small"
                variant="contained"
                sx={{ marginLeft: "8px" }}
                onClick={() => {
                  cameraRef.current.click();
                }}
              >
                <AddAPhoto sx={{ marginRight: "4px" }} /> Capture
              </Button>
            </label>
          </div>
        </div>
      </div>

      <Modal
        open={scannedItem !== null}
        onClose={() => {
          setScannedItem(null);
          setQrScan("");
        }}
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 300,
            maxWidth: 500,
            maxHeight: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "block",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          {scannedItem ? (
            <div style={{ flex: 1 }}>
              <h3>Pull from {scannedItem.source}</h3>
              {!isSmallScreen && (
                <div
                  style={{
                    borderRadius: 0,
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    padding: 8,
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: 100,
                      paddingLeft: 12,
                      paddingRight: 12,
                      fontSize: 12,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        //textAlign: "center",
                      }}
                    >
                      Image
                    </p>
                  </div>
                  <div style={{ flex: 1, fontSize: 12 }}>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        //textAlign: "center",
                      }}
                    >
                      Description
                    </p>
                  </div>
                  <div style={{ minWidth: 80, fontSize: 12 }}>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      UOM
                    </p>
                  </div>
                  <div style={{ minWidth: 100, fontSize: 12 }}>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Quantity
                    </p>
                  </div>
                </div>
              )}
              {isSmallScreen ? (
                <div style={{ padding: 8, marginTop: 4 }}>
                  <div
                    style={{
                      flex: 1,
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div style={{ width: 100, padding: 12 }}>
                      <img
                        src={scannedItem.image}
                        style={{ width: "100%", aspectRatio: 15 / 20 }}
                      />
                    </div>
                    <p style={{ flex: 1, margin: 0 }}>{scannedItem.name}</p>
                  </div>

                  <div
                    style={{
                      borderRadius: 0,
                      marginTop: 8,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: 0, padding: 8, textAlign: "end" }}>
                        {scannedItem.uom}
                      </p>
                    </div>
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <TextField
                        label="Quantity"
                        type="number"
                        value={scannedItem.quantity}
                        onChange={(e) => {
                          let val;
                          if (
                            e.target.value == "" ||
                            e.target.value === undefined ||
                            e.target.value === null ||
                            e.target.value === "0" ||
                            e.target.value === "00"
                          ) {
                            val = 0;
                          } else {
                            val = e.target.value.replace(/^0+/, "");
                            val = toFixedIfNecessary(val, 2);
                          }
                          // Do Not Allow Negative
                          if (val < 0) {
                            val = 0;
                          }
                          setScannedItem((prev) => ({
                            ...prev,
                            quantity: val,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    borderRadius: 0,
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    padding: 8,
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: 100, padding: 12 }}>
                    <img
                      src={scannedItem.image}
                      style={{ width: "100%", aspectRatio: 15 / 20 }}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ flex: 1, margin: 0 }}>{scannedItem.name}</p>
                  </div>
                  <div
                    style={{
                      width: 100,
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ margin: 0, textAlign: "center" }}>
                      {scannedItem.uom}
                    </p>
                  </div>
                  <TextField
                    style={{
                      width: 80,
                    }}
                    label="Quantity"
                    type="number"
                    value={scannedItem.quantity}
                    onChange={(e) => {
                      let val;
                      if (
                        e.target.value == "" ||
                        e.target.value === undefined ||
                        e.target.value === null ||
                        e.target.value === "0" ||
                        e.target.value === "00"
                      ) {
                        val = 0;
                      } else {
                        val = e.target.value.replace(/^0+/, "");
                        val = toFixedIfNecessary(val, 2);
                      }
                      // Do Not Allow Negative
                      if (val < 0) {
                        val = 0;
                      }
                      setScannedItem((prev) => ({ ...prev, quantity: val }));
                    }}
                  />
                </div>
              )}
              {scannedItem.source === "committed" &&
                grabs.committed.length == 0 &&
                grabs.selectedJob && (
                  <div>
                    Current Quick Pull is set for {grabs.selectedJob.jobName} (
                    {grabs.selectedJob.projectId}). Pulling committed material
                    will set the selected job to {scannedItem.details.job_name}{" "}
                    ({scannedItem.details.job_id}
                    ).
                  </div>
                )}
              {scannedItem.source === "committed" &&
                grabs.committed.length > 0 &&
                grabs.selectedJob.projectId !== scannedItem.details.job_id && (
                  <div>
                    This material is committed to {scannedItem.details.job_name}{" "}
                    ({scannedItem.details.job_id}). You are currently pulling
                    materials committed to or staged for{" "}
                    {grabs.selectedJob.jobName} ({grabs.selectedJob.projectId}).
                    You must remove the committed and staged materials before
                    pulling materials committed to another job.
                  </div>
                )}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{ width: 100, marginRight: 8 }}
                  variant="outlined"
                  onClick={() => {
                    setScannedItem(null);
                    setQrScan("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    scannedItem.source === "committed" &&
                    grabs.committed.length > 0 &&
                    grabs.selectedJob.projectId !== scannedItem.details.job_id
                  }
                  style={{ width: 100 }}
                  variant="contained"
                  onClick={() => {
                    console.log("Submit");
                    grabScannedItem();
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
          ) : null}
        </Card>
      </Modal>

      <div>
        <h3 style={{ display: "flex", alignItems: "center" }}>
          Submit with Status
        </h3>
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              "& > *": {
                m: 1,
              },
            }}
          >
            <ButtonGroup variant="contained">
              {statusOptions &&
                statusOptions.map((opt) => (
                  <Button
                    disabled={
                      loading ||
                      !((grabs.selectedJob || isUnlisted) && grabCount > 0)
                    }
                    onClick={() => {
                      if (opt.key_name == "PULLLOADED") {
                        // select vehicle
                        setSelectTruckModalOpen(true);
                      } else submitGrabs(opt.key_name);
                    }}
                    key={opt.key_name}
                  >
                    {opt.description}
                  </Button>
                ))}
            </ButtonGroup>
            <SelectTruckModal
              open={selectTruckModalOpen}
              setOpen={setSelectTruckModalOpen}
              onSelect={(vehicleId, vehicle) => {
                setSelectTruckModalOpen(false);
                submitGrabs("PULLLOADED", vehicleId);
              }}
            />
          </Box>
        </div>
      </div>
    </PageContent>
  );
};

export default CreateStockout;
