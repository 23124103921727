import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  Box,
  Fab,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AddAPhoto, FileUpload, Search } from "@mui/icons-material";
import clickOnceWrapper from "../helpers/clickOnceWrapper";
import toFixedIfNecessary from "../helpers/toFixedIfNecessary";
import ListItem from "./ListItem";
import { useTheme } from "@emotion/react";
import imageCompression from "browser-image-compression";
import { LoadingButton } from "@mui/lab";
import { DarkLightModeContext } from "../context/DarkLightModeContext";

const CreateReturnDialog = ({
  addModal,
  setAddModal,
  jobs,
  saveReturn,
  loading,
}) => {
  const { isDarkMode } = useContext(DarkLightModeContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const uploadRef = useRef(null);
  const cameraRef = useRef(null);

  const handleFileSelect = async (event) => {
    const originalFile = event.target.files[0];
    console.log("originalFile instanceof Blob", originalFile instanceof Blob); // true
    console.log(`originalFile size ${originalFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    // Compress File (returns Blob instead of File)
    let compressedBlob = await imageCompression(originalFile, options);
    // Convert Compressed Blob back to File
    let compressedFile = new File([compressedBlob], originalFile.name, {
      type: originalFile.type,
    });
    const compressedFileUrl = URL.createObjectURL(compressedFile);
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    console.log("compressedFile", compressedFile);
    console.log("compressedFileURL", compressedFileUrl);
    setAddModal((prev) => ({
      ...prev,
      imageBlobs: [...prev.imageBlobs, compressedFile],
      imageUrls: [...prev.imageUrls, compressedFileUrl],
    }));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      open={addModal.open}
      onClose={() => {
        setAddModal({
          open: false,
          search: "",
          imageBlobs: [],
          imageUrls: [],
          items: [{ item_text: "", quantity: 0 }],
          jobId: null,
        });
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          overflow: "scroll",
          padding: 16,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          Add New Return
        </Typography>

        <div>
          <Typography
            id="modal-modal-title"
            variant="p"
            sx={{ width: "100%", textAlign: "start", fontWeight: "bold" }}
          >
            Select Job
          </Typography>
          <FilledInput
            style={{ marginTop: 16, width: "100%" }}
            type="text"
            hiddenLabel={true}
            value={addModal.search}
            placeholder="Search Job Name"
            onChange={(e) => {
              setAddModal((prev) => ({ ...prev, search: e.target.value }));
            }}
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
          />
          <div
            style={{
              // display: "block",
              // maxHeight: 100,
              // maxWidth: "100%",
              // background: "#d2d2d2",
              // overflowX: "hidden",
              display: "block",
              maxHeight: 200,
              maxWidth: "100%",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            {jobs.map((job, index) => (
              // <div
              //   style={{
              //     padding: 8,
              //     maxWidth: "100%",
              //     background: job.id === addModal.jobId ? "#000" : null,
              //     color: job.id === addModal.jobId ? "#fff" : null,
              //   }}
              //   key={index}
              //   onClick={() => {
              //     setAddModal((prev) => ({
              //       ...prev,
              //       jobId: job.id,
              //       //selectedPo: null,
              //     }));
              //   }}
              // >
              //   <p style={{ margin: 0, fontWeight: "bold", fontSize: 16 }}>
              //     {job.projectid} - {job.job_name}
              //   </p>
              //   <p
              //     style={{
              //       margin: 0,
              //       fontSize: 12,
              //       color:
              //         job.id === addModal.jobId ? "#fff" : "rgba(0, 0, 0, 0.7)",
              //     }}
              //   >
              //     {job.address}
              //   </p>
              // </div>
              <ListItem
                title={`${job.projectid} - ${job.job_name}`}
                details={[job.address]}
                onClick={() => {
                  setAddModal((prev) => ({
                    ...prev,
                    jobId: job.id,
                    //selectedPo: null,
                  }));
                }}
                sx={{
                  background: job.id === addModal.jobId ? "#5A5A5A" : "inherit",
                }}
              />
            ))}
          </div>
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="p"
            sx={{ width: "100%", textAlign: "left", fontWeight: "bold" }}
          >
            Items
          </Typography>
          <div style={{ width: "100%" }}>
            {addModal.items &&
              addModal.items.map((item, index) => (
                <div
                  key={index}
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    sx={{ flex: 1 }}
                    name="Description"
                    label="Description"
                    type="text"
                    value={item.item_text}
                    onChange={(e) => {
                      let val = e.target.value;
                      let itemsCopy = [...addModal.items];
                      itemsCopy[index].item_text = val;
                      setAddModal((prev) => ({
                        ...addModal,
                        items: itemsCopy,
                      }));
                    }}
                  />
                  <TextField
                    sx={{ width: "80px", marginLeft: "8px" }}
                    name="Quantity"
                    label="Quantity"
                    type="number"
                    value={item.quantity}
                    // onChange={(e) => {
                    //   let val = e.target.value;
                    //   let itemsCopy = [...addModal.items];
                    //   itemsCopy[index].item_text = val;
                    //   setAddModal((prev) => ({
                    //     ...addModal,
                    //     items: itemsCopy,
                    //   }));
                    // }}
                    onChange={(e) => {
                      let val;
                      if (
                        e.target.value == "" ||
                        e.target.value === undefined ||
                        e.target.value === null ||
                        e.target.value === "0" ||
                        e.target.value === "00"
                      ) {
                        val = 0;
                      } else {
                        val = e.target.value.replace(/^0+/, "");
                        val = toFixedIfNecessary(val, 2);
                      }
                      // Do Not Allow Negative
                      if (val < 0) {
                        val = 0;
                      }
                      //   setScannedItem((prev) => ({
                      //     ...prev,
                      //     quantity: val,
                      //   }));

                      let itemsCopy = [...addModal.items];
                      itemsCopy[index].quantity = val;
                      setAddModal((prev) => ({
                        ...addModal,
                        items: itemsCopy,
                      }));
                    }}
                  />
                  <Button
                    sx={{}}
                    variant="text"
                    onClick={() => {
                      let itemsCopy = [...addModal.items];
                      itemsCopy.splice(index, 1);
                      setAddModal((prev) => ({
                        ...prev,
                        items: itemsCopy,
                      }));
                    }}
                  >
                    Remove
                  </Button>
                </div>
              ))}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ marginTop: "15px", width: 150 }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                let itemsCopy = [...addModal.items];
                itemsCopy.push({ item_text: "", quantity: 0 });
                setAddModal((prev) => ({
                  ...prev,
                  items: itemsCopy,
                }));
              }}
            >
              Add Item
            </Button>{" "}
          </div>
        </div>

        <h4>Images</h4>
        <Typography
          id="modal-modal-title"
          variant="p"
          component="p"
          sx={{ margin: 0 }}
        >
          Maximum 30 images
        </Typography>
        <Grid
          container
          padding={0}
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          {addModal.imageUrls.length > 0 &&
            addModal.imageUrls.map((url) => (
              <img
                src={url}
                style={{
                  minHeight: "100px",
                  maxHeight: "100px",
                  minWidth: "100px",
                  maxWidth: "100px",
                  marginRight: "3px",
                  border: "1px solid black",
                }}
              />
            ))}
        </Grid>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "4px",
          }}
        >
          <label>
            <input
              ref={uploadRef}
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              name="images"
              // capture="environment"
              onChange={(event) => {
                handleFileSelect(event);
              }}
            />
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                uploadRef.current.click();
              }}
            >
              <FileUpload />
              Upload
            </Button>
          </label>
          <label>
            <input
              ref={cameraRef}
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              name="images"
              capture="environment"
              onChange={(event) => {
                handleFileSelect(event);
              }}
            />
            <Button
              size="small"
              variant="contained"
              sx={{ marginLeft: "8px" }}
              onClick={() => {
                cameraRef.current.click();
              }}
            >
              <AddAPhoto sx={{ marginRight: "4px" }} /> Capture
            </Button>
          </label>
        </div>

        <h4>Notes</h4>
        <FormControl fullWidth>
          <TextareaAutosize
            placeholder="Notes..."
            minRows={3}
            style={{
              width: "100%",
              background: isDarkMode
                ? "#232323"
                : theme.palette.primary.extraDark,
              color: theme.palette.secondary.contrastText,
              borderColor: theme.palette.primary.main,
            }}
            value={addModal.notes}
            onChange={(e) => {
              setAddModal((prev) => ({
                ...prev,
                notes: e.target.value,
              }));
            }}
          />
        </FormControl>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px",
          }}
        >
          <LoadingButton
            loading={loading}
            variant="outlined"
            onClick={async () => {
              setAddModal({
                open: false,
                search: "",
                imageBlobs: [],
                imageUrls: [],
                items: [{ item_text: "", quantity: 0 }],
                jobId: null,
              });
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            loading={loading}
            sx={{ marginLeft: "8px" }}
            // disabled={addModal.jobId === null}
            variant="contained"
            onClick={clickOnceWrapper(() => {
              saveReturn();
            })}
          >
            Submit
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateReturnDialog;
