import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const SoftDeleteModal = ({ open, toggleOpen, handleDelete }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={toggleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: ".5rem",
              marginBottom: "2rem",
            }}
          >
            <WarningAmberIcon />
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign={"center"}
            >
              Are You Sure?
            </Typography>
            <WarningAmberIcon />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "4rem",
            }}
          >
            <Button
              color="error"
              variant="contained"
              onClick={() => handleDelete("DELETED")}
            >
              Delete
            </Button>
            <Button variant="contained" onClick={toggleOpen}>
              cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default SoftDeleteModal;
