import { useState, useEffect, useContext } from "react";
import api from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";

const useBadgeCounts = () => {
  const { authToken, userBranch } = useContext(AuthContext);
  const headers = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const [loading, setLoading] = useState(true);
  const [counts, setCounts] = useState();

  useEffect(() => {
    api
      .get(`/notifications/counts?branch=${userBranch}`, headers)
      .then((res) => setCounts(res.data))
      .catch((error) => {
        console.log(error);
        setCounts(error);
      })
      .finally(() => setLoading(false));
  }, []);

  return { loading, counts };
};

export default useBadgeCounts;
