import composePage from "../../HOCs/composePage";
import makePage from "../../HOCs/makePage";
import withConditionalFeedback from "../../HOCs/withConditionalFeedback";
import { Box, Typography } from "@mui/material";
import AssetCard from "./AssetCard";

const AssignedAssetsView = ({ data, handleReturn }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box mb={"2em"}>
          <Typography variant="h5">Your Asses</Typography>
        </Box>

        {data.map((ass, i) => (
          <AssetCard key={i} asset={ass} handleReturn={handleReturn} />
        ))}
      </Box>
    </>
  );
};
export default composePage(
  makePage,
  withConditionalFeedback
)(AssignedAssetsView);
