import { Box, Typography } from "@mui/material";
import ReportDetails from "./ReportDetails";
import composePage from "../../../HOCs/composePage";
import makePage from "../../../HOCs/makePage";
import withConditionalFeedback from "../../../HOCs/withConditionalFeedback";

const DamageReportView = ({ data }) => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        {data.map((dam) => (
          <ReportDetails key={dam.id} detail={dam} />
        ))}
      </Box>
    </>
  );
};
export default composePage(makePage, withConditionalFeedback)(DamageReportView);
