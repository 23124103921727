import React, { useState, createContext, useEffect } from "react";

export const TopNavContext = createContext();

export const TopNavProvider = ({ children }) => {
  const [pageName, setPageName] = useState("BRM");

  return (
    <TopNavContext.Provider value={{ pageName, setPageName }}>
      {children}
    </TopNavContext.Provider>
  );
};
