const toFixedIfNecessary = (val, dp) => {
  // COUNT HOW MANY DECIMAL PLACES THERE ARE
  // 1.0 (1 digit after)
  // 1.02 (2 digits after)
  let decimalSplit = val.split(".");
  let decimals = 0;
  if (decimalSplit[1]) {
    decimals = decimalSplit[1].length;
    if (decimals > 2) decimals = 2;
  }
  val = parseFloat(val).toFixed(decimals);
  return val;
};
export default toFixedIfNecessary;
