import {
  useState,
  useEffect,
  createContext,
  useReducer,
  useContext,
} from "react";

export const AssetContext = createContext();

export const AssetContextProvider = ({ children }) => {
  const initialAssignmentState = {
    asset: {
      assetType: null,
      assetId: null,
      assetName: null,
    },
    targetAssignee: {
      userId: null,
      userName: null,
    },
  };
  const initalDamageReportState = {
    responsibleUserId: null,
    responsibleUserName: null,
  };

  const assetReducer = (state, action) => {
    switch (action.type) {
      case "SET-ASSET":
        return {
          ...state,
          asset: {
            assetType: action.value.type,
            assetId: action.value.id,
            assetName: action.value.name,
          },
        };
      case "SET-USER":
        return {
          ...state,
          targetAssignee: {
            userId: action.value.id,
            userName: action.value.fullName,
          },
        };
      case "CLEAR-STATE":
        console.log({ ...initialAssignmentState });
        return { ...initialAssignmentState };
      default:
        throw Error(`Action of type ${action.type}, is NOT a valid action.`);
    }
  };

  const damageReducer = (state, action) => {
    switch (action.type) {
      case "SET-ID":
        return { ...state, responsibleUserId: action.payload };
      case "SET-NAME":
        return { ...state, responsibleUserName: action.payload };
      case "SET: ALL":
        console.log({
          ...state,
          responsibleUserName: action.payload.name,
          responsibleUserId: action.payload.id,
        });
        return {
          ...state,
          responsibleUserName: action.payload.name,
          responsibleUserId: action.payload.id,
        };
      default:
        throw Error(`Action of type ${action.type}, is NOT a valid action.`);
    }
  };

  const [assetAssignment, assetDispatch] = useReducer(
    assetReducer,
    initialAssignmentState
  );

  const [damageReport, damageDispatch] = useReducer(
    damageReducer,
    initalDamageReportState
  );

  return (
    <AssetContext.Provider
      value={{
        assetAssignment,
        damageReport,
        updateAssetAssignment: assetDispatch,
        updateDamageReport: damageDispatch,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};
