import {
  useReducer,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import { AssetContext } from "../../../../../context/AssetContext";
import { useParams } from "react-router-dom";
import chunk from "../../../../../helpers/chunk";
import api from "../../../../../api/InventoryTracking";
import axios from "axios";
import escapeCharacters from "../../../../../helpers/escapeCharacters";
import DamageReportView from "./DamageReportView";
import { useNavigate } from "react-router-dom";

const DamageReportComponent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(null);
  const { id, type } = useParams();
  const { authToken, userBranch } = useContext(AuthContext);
  const { updateDamageReport, damageReport } = useContext(AssetContext);

  const header = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const [employees, setEmployees] = useState([]);
  const [search, setSearch] = useState("");
  const [branch, setBranch] = useState(userBranch);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const observer = useRef();
  const lastElement = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < pages) {
          console.log("visible");
          setPage((curr) => curr + 1);
        }
      });
      if (node) observer.current.observe(node);
      // console.log("NODE: ", node);
    },
    [isLoading, page < pages]
  );

  const [asset, setAsset] = useState(null);

  const reportReducer = (state, action) => {
    const assetStatuses = ["out-of-commission", "useable"];
    switch (action.type) {
      case "UPDATE: NOTES":
        return { ...state, notes: action.payload };
      case "UPDATE: STATUS":
        if (!assetStatuses.includes(action.payload)) {
          throw `"${action.payload}" is not a valid status.`;
        }
        return { ...state, assetStatus: action.payload };
      case "UPDATE: IMAGE BLOBS":
        return { ...state, imgBlobs: [...state.imgBlobs, action.payload] };
      case "UPDATE: IMAGE URLS":
        return { ...state, imgUrls: [...state.imgUrls, action.payload] };
      default:
        throw Error("Unknown action: " + action.type);
    }
  };

  const [report, dispatch] = useReducer(reportReducer, {
    notes: "",
    assetStatus: "",
    imgBlobs: [],
    imgUrls: [],
  });

  const getAsset = async () => {
    try {
      setIsloading(true);
      const res = await api.get(`/assets/${type}/${id}`, header);
      console.log(res.data);
      setAsset(res.data.asset);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setIsloading(false);
    }
  };

  const uploadImages = async (imageBlobs = []) => {
    if (imageBlobs.length < 1) return;
    const MAX_UPLOAD = 15; // only upload 15 at a time;
    const blobChunks = chunk(imageBlobs, MAX_UPLOAD); // split the blobs array into chunks of size === MAX_UPLOAD
    const apiCalls = blobChunks
      .map((chunk) => {
        const formData = new FormData();
        chunk.forEach((blob) => {
          formData.append("images", blob);
        });
        return formData;
      })
      .map(async (data) => {
        data.append("resourceType", "public/damage");
        const res = await api.post(`/uploads/images`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "content-type": "multipart/form-data",
          },
        });
        console.log("uploadImages: ", res.data.files);
        return res.data.files;
      });
    let results = [];
    for await (let result of apiCalls) {
      results.push(result);
    }
    console.log("THE RESULTS ", results);
    return results;
  };

  const saveDamageReport = async () => {
    try {
      const images = await uploadImages(report.imgBlobs); // an array of urls
      const body = {
        images,
        assetType: type,
        assetId: id,
        notes: report.notes,
        assetStatus: report.assetStatus,
        responsibleUserId: damageReport.responsibleUserId, // TODO: How to get responsible user. It's NOT required. Sometimes things just break;
      };
      const res = await api.post(`/assets/${type}/${id}/damage`, body, header);
      console.log(res);
      if (res.status === 201) {
        navigate(`/assets/${type}/${id}`);
      }
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const getEmployees = async () => {
    const controller = new AbortController();
    api
      .get(
        `/employees?search=${escapeCharacters(
          search
        )}&location=${branch}&pageSize=25&page=${page}`,
        {
          signal: controller.signal,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        console.log("EMPLOYEES: ", res);
        setPages(res.data.pages);
        if (page > 1) {
          setEmployees((curr) => [...curr, ...res.data.employees]);
        } else {
          setEmployees([...res.data.employees]);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("request canceled");
        } else {
          setError(err);
          console.log("ERROR: ", err);
        }
      })
      .finally(() => setIsloading(false));
    return () => controller.abort();
  };

  useEffect(() => {
    getEmployees();
  }, [search, branch, page]);

  useEffect(() => {
    getAsset();
  }, []);

  return (
    <>
      <DamageReportView
        data={asset}
        report={report}
        dispatch={dispatch}
        saveDamageReport={saveDamageReport}
        fetchEmployees={{
          getEmployees,
          setBranch,
          setPage,
          setPages,
          setSearch,
          branch,
          search,
          lastElement,
        }}
        setResponsibleUser={(employee) => {
          console.log(employee);
          updateDamageReport({
            type: "SET: ALL",
            payload: {
              id: employee.id,
              name: employee.full_name,
            },
          });
        }}
        responsibleUser={damageReport}
        employees={employees}
        isLoading={isLoading}
        error={error}
      />
    </>
  );
};
export default DamageReportComponent;
