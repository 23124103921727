import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../../../api/InventoryTracking";
import ListItem from "../../../../../component/ListItem";
import serverTimeConverter from "../../../../../helpers/serverTimeConverter";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DeliverFromStaging from "./DeliverFromStaging";
import { SnackAlertContext } from "../../../../../context/SnackAlertContext";
import LoadedTrucks from "./LoadedTrucks";
import moment from "moment/moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

const LoadedOnTruckTab = ({ jobId }) => {
  const [trucks, setTrucks] = useState([]);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { authToken } = useContext(AuthContext);
  const [confirmDeliveredModal, setConfirmDeliveredModal] = useState({
    open: false,
    vehicle: null,
  });

  const { openSnackMessage } = useContext(SnackAlertContext);
  let navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // const [activities, setActivities] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().local().startOf("year").format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(moment().local().format("MM/DD/YYYY"));
  const selectStartDate = (val) => {
    setStartDate(val);
  };
  const selectEndDate = (val) => {
    setEndDate(val);
  };

  const getLoadedTrucksByJob = async () => {
    try {
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/loaded-on-truck`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Set All Item Transaction Quantity to Zero
      let trucksCopy = response.data.trucks;
      for (let truck of trucksCopy) {
        for (let item of truck.items) {
          item.transaction_quantity = 0;
        }
      }
      console.log("TRUCKS", trucksCopy);
      setTrucks(trucksCopy);
    } catch (error) {
      console.log(error);
    }
  };

  // const getLoadedOnTruckActivities = async (controller) => {
  //   try {
  //     let beginningOfStartDate = moment(startDate, "MM/DD/YYYY")
  //       .startOf("day")
  //       .format();
  //     let endOfEndDate = moment(endDate, "MM/DD/YYYY").endOf("day").format();

  //     let data = {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     };
  //     if (controller) {
  //       data.signal = controller.signal;
  //     }

  //     const response = await InventoryTracking.get(
  //       `/jobs/${jobId}/loaded-on-truck/activities?startDate=${beginningOfStartDate}&endDate=${endOfEndDate}`,
  //       data
  //     );
  //     console.log("Loaded On Truck Activities", response.data);
  //     setActivities(response.data.activities);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleVehicleSelected = async (vehicle_id) => {
    try {
      setIsLoading(true);

      const getVehicleDetails = await InventoryTracking.get(
        `/vehicles/${vehicle_id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const getVehicleItems = await InventoryTracking.get(
        `/vehicles/${vehicle_id}/items`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      let vehicle = {
        ...getVehicleDetails.data.vehicle,
        items: getVehicleItems.data.items,
      };

      setConfirmDeliveredModal({
        open: true,
        vehicle: vehicle,
      });
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deliverItems = async () => {
    try {
      setIsLoading(true);
      console.log("MODAL DATA", confirmDeliveredModal);
      let vehicle_id = confirmDeliveredModal.vehicle.id;

      let data = { loadedOnTruckItems: confirmDeliveredModal.vehicle.items };

      const response = await InventoryTracking.post(
        `/vehicles/${vehicle_id}/delivered`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("DELIVERED!", data);
      openSnackMessage("success", "Item delivery confirmed");
      setConfirmDeliveredModal({ open: false, vehicle: null });
      getLoadedTrucksByJob();
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLoadedTrucksByJob();
  }, []);

  // useEffect(() => {
  //   const controller = new AbortController();

  //   getLoadedOnTruckActivities(controller);
  //   return () => {
  //     controller.abort(); // abort api call on unmount for cleanup
  //   };
  // }, [startDate, endDate]);

  return (
    <div>
      <Dialog
        fullScreen={isSmallScreen}
        scroll="body" // Alows scrolling the table
        open={confirmDeliveredModal.open && confirmDeliveredModal.vehicle}
        onClose={() => {
          setConfirmDeliveredModal({
            open: false,
            vehicle: null,
          });
        }}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DialogTitle>Confirm delivery?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Make sure all items are on the truck before it leaves the yard
          </DialogContentText>
          <TableContainer component={Box}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>UOM</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Transaction ID</TableCell>
                  <TableCell>Job</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {confirmDeliveredModal &&
                  confirmDeliveredModal.vehicle &&
                  confirmDeliveredModal.vehicle.items &&
                  confirmDeliveredModal.vehicle.items.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.material_origin_key}</TableCell>
                      <TableCell>{item.uom}</TableCell>
                      <TableCell>{item.transaction_total_quantity}</TableCell>
                      <TableCell>{item.transaction_id}</TableCell>
                      <TableCell>
                        <Link
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            // navigate(`/jobs/${item.project_backlog_id}/loaded`);
                          }}
                        >
                          {item.job_id}: {item.job_name}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            onClick={() => {
              setConfirmDeliveredModal({
                open: false,
                vehicle: null,
              });
            }}
          >
            Back
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => {
              deliverItems();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <LoadedTrucks
        isLoading={isLoading}
        trucks={trucks}
        setTrucks={setTrucks}
        previewModalOpen={previewModalOpen}
        setPreviewModalOpen={setPreviewModalOpen}
        onTruckClick={(vehicleId, vehicle) => {
          handleVehicleSelected(vehicleId);
        }}
      />
      {/* <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <h2
            style={{
              flex: 1,
              margin: 0,
            }}
          >
            History
          </h2>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                label="From"
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={selectStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
              <MobileDatePicker
                label="To"
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={selectEndDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
        </div>

        <TableContainer component={Box}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Transferred To</TableCell>
                <TableCell>Transferred By</TableCell>
                <TableCell>Vehicle</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activities &&
                activities.map((activity, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      {serverTimeConverter
                        .convertServerCreatedOnTimeStampToPhoenix(
                          activity.created_on
                        )
                        .format("MM/DD/YY h:mm a")}
                    </TableCell>
                    <TableCell>{activity.description}</TableCell>
                    <TableCell
                      style={{
                        color: activity.quantity < 0 ? "red" : "green",
                      }}
                    >
                      {activity.quantity < 0 ? "" : "+"}
                      {activity.quantity}
                    </TableCell>
                    <TableCell>{activity.transaction_id}</TableCell>
                    <TableCell>
                      {activity.transferred_to
                        ? activity.transferred_to
                        : "TRUCK"}
                    </TableCell>
                    <TableCell>{activity.transferred_by}</TableCell>
                    <TableCell>{activity.vehicle_nick_name}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
    </div>
  );
};

export default LoadedOnTruckTab;
