import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const DeactivationModal = ({ open, toggleOpen, handleDelete }) => {
  const [reason, setReason] = useState(null);

  return (
    <>
      <Modal
        open={open}
        onClose={toggleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Status:
          </Typography>

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            >
              <FormControlLabel value="SOLD" control={<Radio />} label="Sold" />
              <FormControlLabel
                value="OUTOFCOMMITION" // I spelled it this way because this is how it was spelled in the Database.
                control={<Radio />}
                label="Out Of Commission"
              />
              <FormControlLabel
                value="LOSTSTOLEN"
                control={<Radio />}
                label="Lost/Stolen"
              />
            </RadioGroup>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "4rem",
            }}
          >
            <Button
              color="error"
              variant="contained"
              onClick={() => handleDelete(reason)}
            >
              Deactivate
            </Button>
            <Button variant="contained" onClick={toggleOpen}>
              cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default DeactivationModal;
