import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../../../api/InventoryTracking";
import ListItem from "../../../../../component/ListItem";
import serverTimeConverter from "../../../../../helpers/serverTimeConverter";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";

const ReturnsTab = ({ jobId }) => {
  const [returnList, setReturnList] = useState([]);
  const { authToken } = useContext(AuthContext);
  let navigate = useNavigate();
  const theme = useTheme();

  const getReturns = async () => {
    try {
      const response = await InventoryTracking.get(`/jobs/${jobId}/returns`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("Returns", response.data);
      setReturnList(
        response.data.returns.map((ret) => ({
          ...ret,
          img_urls: ret?.img_urls?.split(","),
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReturns();
  }, []);

  return (
    <div>
      {returnList.map((returned, index) => {
        let details = [];
        details.push(`Status: ${returned.posted_on ? "POSTED" : "PENDING"}`);
        details.push(
          `Return Date: ${serverTimeConverter
            .convertServerCreatedOnTimeStampToPhoenix(returned.created_on)
            .format("MM/DD/YY h:mm a")}`
        );
        details.push(`Returned By: ${returned.created_by}`);
        details.push(`Notes: ${returned.notes}`);
        return (
          <ListItem
            key={index}
            title={`${returned.job_name}`}
            details={details}
            onClick={() => {
              navigate(`/return/${returned.id}`);
            }}
          />
        );
      })}
    </div>
  );
};

export default ReturnsTab;
