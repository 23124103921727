import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { Typography } from "@mui/material";

const Vendor = (props) => {
  const { id } = props;
  const { authToken } = useContext(AuthContext);
  const [details, setDetails] = useState(null);

  const getVendor = async () => {
    try {
      const response = await InventoryTracking.get(`/vendors/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setDetails(response.data.vendor);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVendor();
  }, [id]);

  if (details) return <Typography {...props}>{details.provider}</Typography>;
};

export default Vendor;
