import { CircularProgress, Typography } from "@mui/material";

const MessageContainer = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15em",
      }}
    >
      {children}
    </div>
  );
};

const withConditionalFeedback = (Component) => (props) => {
  const { error, isLoading, data } = props;
  const noData = !data || Object.keys(data).length < 1;
  if (error) {
    return (
      <MessageContainer>
        {error.message ? (
          <Typography variant="overline">{error.message}</Typography>
        ) : (
          <Typography variant="overline">
            Oops... Internal Server Error
          </Typography>
        )}
      </MessageContainer>
    );
  }
  if (isLoading) {
    return (
      <MessageContainer>
        <CircularProgress />
        <Typography variant="overline" sx={{ marginTop: "1em" }}>
          loading
        </Typography>
      </MessageContainer>
    );
  }
  // if (!isLoading && !error && noData) {
  //   return (
  //     <MessageContainer>
  //       <Typography variant="overline">No Data To Display</Typography>
  //     </MessageContainer>
  //   );
  // }

  return <Component {...props} />;
};
export default withConditionalFeedback;
