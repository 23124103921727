import { useState, useContext, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../../../context/AuthContext";
import api from "../../../../../api/InventoryTracking";
import axios from "axios";
import AssetListView from "./AssetListView";
import escapeCharacters from "../../../../../helpers/escapeCharacters";

const AssetListComponent = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const { userBranch, authToken } = useContext(AuthContext);
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(null);

  const assetTypeOptions = [
    { name: "Tools", value: "tool" },
    { name: "Ladders", value: "ladder" },
    { name: "Equipment", value: "equipment" },
    { name: "Safety Equipment", value: "safety-equipment" },
    { name: "Safety Gear", value: "safety-gear" },
  ];
  const [assetType, setAssetType] = useState(type);

  const [assets, setAssets] = useState([]);

  const [search, setSearch] = useState("");
  const [branch, setBranch] = useState(userBranch);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [filter, setFilter] = useState("available");

  const observer = useRef();
  const lastElement = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < pages) {
          console.log("visible");
          setPage((curr) => curr + 1);
        }
      });
      if (node) observer.current.observe(node);
      // console.log("NODE: ", node);
    },
    [isLoading, page < pages]
  );

  const getAssets = async (signal) => {
    try {
      let headers = {
        signal: signal,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      // Filters: assigned, availables, empty string (returns all)
      const res = await api.get(
        `/assets/${type}?search=${escapeCharacters(
          search
        )}&filter=${filter}&location=${branch}&pageSize=25&page=${page}`,
        headers
      );
      console.log("ASSET API: ", res);
      setPages(res.data.pages);
      if (page > 1) {
        setAssets((curr) => [...curr, ...res.data.assets]);
      } else {
        setAssets([...res.data.assets]);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("request canceled");
      } else {
        setError(err);
        console.log("ERROR: ", err);
      }
    }
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  // useEffect(() => {
  //   const controller = new AbortController();
  //   withSetLoading(() => getAssets(controller.signal));
  //   return () => {
  //     controller.abort();
  //   };
  // }, []);

  useEffect(() => {
    setAssets([]);
    const controller = new AbortController();
    getAssets(controller.signal);
    return () => {
      controller.abort();
    };
  }, [search, branch, page, type]);

  useEffect(() => {
    setAssets([]);
    const controller = new AbortController();
    getAssets(controller.signal);
    return () => {
      controller.abort();
    };
  }, [filter]);

  useEffect(() => {
    setAssets([]);
    navigate(`/assets/${assetType}`);
  }, [assetType]);

  return (
    <>
      <AssetListView
        data={assets}
        callback={lastElement}
        isLoading={isLoading}
        error={error}
        branchOptions={["SDL", "FLG"]}
        branch={branch}
        setBranch={setBranch}
        search={search}
        setSearch={setSearch}
        handleFilter={handleFilter}
        filter={filter}
        assetType={assetType}
        setAssetType={setAssetType}
        assetTypeOptions={assetTypeOptions}
      />
    </>
  );
};
export default AssetListComponent;
