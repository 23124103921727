import { useState } from "react";
import {
  TextField,
  Typography,
  Modal,
  Button,
  Box,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const DamageReportModal = ({
  request,
  open,
  toggleOpen,
  submitRequest,
  damageReport,
  updateDamageReport,
}) => {
  const [isDamaged, setIsDamaged] = useState(false);
  return (
    <>
      <Modal open={open} onClose={toggleOpen}>
        {!isDamaged ? (
          <Box sx={style}>
            <Typography variant="h6" component="h2">
              Is {request.tool} Damaged?
            </Typography>
            <Box sx={{ display: "flex", gap: "3em", mt: "1em" }}>
              <Button
                size="small"
                variant="contained"
                onClick={() => setIsDamaged(true)}
              >
                yes
              </Button>
              <Button size="small" variant="contained">
                no
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={style}>
            <TextField
              label="Describe whats wrong..."
              multiline
              rows={3}
              fullWidth
              value={damageReport.notes}
              onChange={(e) =>
                updateDamageReport({
                  type: "update_notes",
                  value: e.target.value,
                })
              }
            />
            <FormControl sx={{ mt: "1em" }}>
              <RadioGroup
                row
                value={damageReport.toolStatus}
                onChange={(e) =>
                  updateDamageReport({
                    type: "update_tool_status",
                    value: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  value="out-of-commission"
                  control={<Radio />}
                  label="out-of-commission"
                />
                <FormControlLabel
                  value="useable"
                  control={<Radio />}
                  label="useable"
                />
              </RadioGroup>
            </FormControl>
            <Button
              variant="contained"
              startIcon={<AddAPhotoIcon />}
              sx={{ mt: "1em" }}
            >
              {" "}
              photo
            </Button>
            <Button
              variant="contained"
              size="small"
              fullWidth
              sx={{ mt: "3em" }}
            >
              submit
            </Button>
            <Button
              size="small"
              sx={{ mt: "2em" }}
              onClick={() => {
                updateDamageReport({ type: "cancel_report" });
                setIsDamaged(false);
              }}
            >
              cancel
            </Button>
          </Box>
        )}
      </Modal>
    </>
  );
};
export default DamageReportModal;
