import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { Typography } from "@mui/material";

const DeliveredItem = (props) => {
  const { jobId, deliveredId } = props;
  const { authToken } = useContext(AuthContext);
  const [details, setDetails] = useState(null);

  const getDeliveredItemDetails = async () => {
    try {
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/delivered-items/${deliveredId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      setDetails(response.data.delivered);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDeliveredItemDetails();
  }, [deliveredId]);

  if (details)
    return (
      <Typography {...props}>
        {details.description} [UOM: {details.uom}]
      </Typography>
    );
};

export default DeliveredItem;
