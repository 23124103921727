import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Search } from "@mui/icons-material";
import {
  Button,
  Card,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ListItem from "../../../component/ListItem";
import { AuthContext } from "../../../context/AuthContext";
import InventoryTracking from "../../../api/InventoryTracking";
import useSearchAndPagination from "../../../customHooks/useSearchAndPagination";
import SearchAndPagination from "../../../component/SearchAndPagination";
import QRCode from "react-qr-code";
import Print from "../../../component/Print";
import PrintableQRCode from "../../../component/PrintableQRCode";
import InventoryTabs from "../../../component/InventoryTabs";
import escapeCharacters from "../../../helpers/escapeCharacters";
import PageContent from "../../../component/PageContent";
import { useTheme } from "@emotion/react";

const Boneyard = () => {
  const navigate = useNavigate();
  const { authToken, userBranch } = useContext(AuthContext);
  const [materials, setMaterials] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // const [pages, setPages] = useState(1);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState(["SDL", "FLG"]);

  // const [search, setSearch] = useState("");
  const [category, setCategory] = useState(""); // ALL is empty string
  // const [location, setLocation] = useState("SDL");
  // const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const searchAndPagination = useSearchAndPagination(userBranch);
  const { search, location, page, setPages, setPage, pages } =
    searchAndPagination;

  const observer = useRef();
  const lastElement = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < pages) {
          console.log("visible");
          setPage((curr) => curr + 1);
        }
      });
      if (node) observer.current.observe(node);
      // console.log("NODE: ", node);
    },
    [isLoading, page < pages]
  );

  const getBoneyard = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await InventoryTracking.get(
        `/boneyard?search=${escapeCharacters(
          search
        )}&location=${location}&pageSize=20&page=${page}`,
        data
      );
      console.log(response.data);

      if (page > 1) {
        setMaterials((prev) => {
          return [...prev, ...response.data.materials];
        });
      } else {
        setMaterials((prev) => {
          return [...response.data.materials];
        });
      }
      setPages(response.data.pages);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await InventoryTracking.get(
        `/material-categories`,
        data
      );
      console.log(response.data);
      setCategories(response.data.categories);
    } catch (error) {
      console.log(error);
    }
  };

  const getLocations = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      console.log("Get Locations");
      const response = await InventoryTracking.get(`/locations`, data);
      console.log(response.data);
      setLocations(response.data.locations);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getBoneyard(controller);

    if (categories.length < 1) getCategories(controller);
    // if (locations.length < 1) getLocations(controller);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [location, page]);

  useEffect(() => {
    const controller = new AbortController();
    setTimeout(() => {
      setMaterials([]);
      getBoneyard(controller);
      setPage(1);
    }, 1500);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [search]);

  // useEffect(() => {
  //   const controller = new AbortController();
  //   if (page > 1) {
  //     setPage(1);
  //   } else {
  //     getBoneyard(controller);
  //   }
  //   return () => {
  //     controller.abort();
  //   };
  // }, [search, location, category]);

  // useEffect(() => {
  //   const controller = new AbortController();
  //   getBoneyard(controller);
  //   return () => {
  //     controller.abort();
  //   };
  // }, [page]);

  return (
    <PageContent>
      {!isSmallScreen && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <PrintableQRCode
            url={`${window.location.host}/boneyard`}
            displaySize={100}
            printSize={100}
            subtext="BONEYARD"
          />
        </div>
      )}

      <div
        style={{
          position: "relative",
          flexDirection: "row",
          display: useMediaQuery(theme.breakpoints.down("sm"))
            ? "column"
            : "row",
          marginTop: 16,
          marginLeft: 16,
          marginRight: 16,
        }}
      >
        {/* <h2
          style={{
            textAlign: useMediaQuery(theme.breakpoints.down("md"))
              ? "left"
              : "center",
            //background: "#d2d2d2",
            flex: 1,
            margin: 0,
          }}
        >
          Boneyard
        </h2> */}
        <InventoryTabs pageName="boneyard" />
        {/* <Button
          variant="outlined"
          style={{
            position: useMediaQuery(theme.breakpoints.down("sm"))
              ? "relative"
              : "absolute",
            right: 0,
            top: 0,
            width: useMediaQuery(theme.breakpoints.down("sm")) ? "100%" : 120,
            height: 36,
            margin: 0,
          }}
        >
          Print All
        </Button> */}
      </div>
      {/* <Grid container spacing={2} padding={2}>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={category}
              label="Category"
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <MenuItem value={""}>All</MenuItem>
              {categories.map((cat, index) => (
                <MenuItem key={index} value={cat.key_name}>
                  {cat.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl fullWidth>
            <InputLabel>Location</InputLabel>
            <Select
              value={location}
              label="Location"
              onChange={(e) => {
                setLocation(e.target.value);
              }}
            >
              <MenuItem value={""}>All</MenuItem>
              {locations.map((loc, index) => (
                <MenuItem key={index} value={loc.location}>
                  {loc.location}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FilledInput
            style={{ width: "100%" }}
            type="text"
            hiddenLabel={true}
            value={search}
            placeholder="Search By Name or Code"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
          />
        </Grid>
      </Grid> */}

      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={pages}
          page={page}
          onChange={(event, value) => {
            setPage(value);
          }}
          color="primary"
          size="small"
        />
      </div> */}
      <SearchAndPagination
        stateHandlers={searchAndPagination}
        locationOptions={locations}
      />
      {!isSmallScreen && (
        <Print>
          <Print.Trigger>
            <Button variant="contained">Print QR Codes</Button>
          </Print.Trigger>
          <Print.PrintableData>
            <div style={{ columns: 1, marginLeft: "15px" }}>
              {materials.map((item) => (
                <div
                  style={{
                    breakInside: "avoid",
                    breakBefore: "always",
                    breakAfter: "always",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "30%",
                      marginBottom: "0px",
                      width: "100px",
                    }}
                  >
                    <p
                      style={{
                        color: "#000",
                        marginBottom: "0px",
                        textAlign: "center",
                      }}
                    >
                      {"BONEYARD"}
                    </p>
                  </div>

                  <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="L" //'L' 'M' 'Q' 'H'
                    size={100} // display size
                    title={item.item}
                    value={`${window.location.host}/boneyard/${item.id}`}
                    style={{
                      marginLeft: "30%",
                      marginTop: "0px",
                      marginBottom: 0,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "30%",
                      marginTop: "0px",
                      width: "100px",
                    }}
                  >
                    <p
                      style={{
                        color: "#000",
                        marginBottom: "0px",
                        textAlign: "center",
                        marginTop: "0px",
                      }}
                    >
                      {item.item}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Print.PrintableData>
        </Print>
      )}
      <Button
        variant="outlined"
        style={{
          width: useMediaQuery(theme.breakpoints.down("sm")) ? "100%" : 200,
        }}
        onClick={() => {
          navigate("/boneyard/transactions");
        }}
      >
        View Transactions
      </Button>
      {materials.map((item, index) => {
        let details = [];
        details.push(`Current Quantity: ${item.qty} ${item.uom_description}`);
        details.push(`Branch: ${item.branch}`);

        if (materials.length === index + 1) {
          return (
            <div ref={lastElement}>
              <ListItem
                key={index}
                title={item.item}
                details={details}
                image={item.image_url}
                onClick={() => {
                  navigate(`/boneyard/${item.id}`);
                }}
              />
            </div>
          );
        }
        return (
          <ListItem
            key={index}
            title={item.item}
            details={details}
            image={item.image_url}
            onClick={() => {
              navigate(`/boneyard/${item.id}`);
            }}
          />
        );
      })}
    </PageContent>
  );
};

export default Boneyard;
