import { useReducer } from "react";
import DamageReportModal from "./DamageReportModal";

const DamageReport = ({ open, toggleOpen, request }) => {
  const initialState = {
    toolId: request.toolId,
    notes: "",
    toolStatus: "",
    images: [],
  };

  const damageReportReducer = (state, action) => {
    const toolStatusOptions = ["out-of-commission", "useable"];
    switch (action.type) {
      case "update_notes":
        return { ...state, notes: action.value };
      case "update_tool_status":
        if (!toolStatusOptions.includes(action.value)) {
          return { ...state };
        } else {
          return { ...state, toolStatus: action.value };
        }
      case "update_images":
        return { ...state, images: [...state.images, action.value] };
      case "cancel_report":
        return { ...initialState };
      default:
        throw Error("Unknown action: " + action.type);
    }
  };

  const [damageReport, dispatch] = useReducer(
    damageReportReducer,
    initialState
  );

  return (
    <DamageReportModal
      request={request}
      open={open}
      toggleOpen={toggleOpen}
      damageReport={damageReport}
      updateDamageReport={dispatch}
    />
  );
};
export default DamageReport;
