import { useTheme } from "@emotion/react";
import {
  Box,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  useMediaQuery,
} from "@mui/material";

const withSearchBar = (Component) => (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    search,
    setSearch,
    branch,
    branchOptions = ["SDL", "FLG"],
    setBranch,
    assetType,
    assetTypeOptions,
    setAssetType,
  } = props;

  const showAssetSelector =
    assetType && assetTypeOptions.length > 0 && setAssetType;

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleAssetType = (e) => {
    setAssetType(e.target.value);
  };

  return (
    <>
      <Box
        sx={{
          marginBottom: "1em",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <TextField
          variant="outlined"
          label="search..."
          size="small"
          value={search}
          onChange={handleSearch}
        />
        <Box
          sx={{
            minWidth: 120,
            marginLeft: isSmallScreen ? "0" : "1em",
            marginTop: isSmallScreen ? "1em" : "0",
            display: "flex",
            gap: "1rem",
          }}
        >
          <FormControl>
            <InputLabel id="select-label">Branch</InputLabel>
            <Select
              size="small"
              labelId="select-label"
              id="select"
              value={branch}
              label="Branch"
              onChange={handleBranch}
            >
              {branchOptions.map((br) => (
                <MenuItem key={br} value={br}>
                  {br}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showAssetSelector && (
            <FormControl>
              <InputLabel id="asset-select">Asset Type</InputLabel>
              <Select
                size="small"
                labelId="asset-select"
                id="select-asset"
                value={assetType}
                label="Asset"
                onChange={handleAssetType}
              >
                {assetTypeOptions.map((ass) => (
                  <MenuItem key={ass.value} value={ass.value}>
                    {ass.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </Box>
      <Component {...props} />
    </>
  );
};
export default withSearchBar;
