import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { useContext } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { DarkLightModeContext } from "../../../context/DarkLightModeContext";

const Jobcard = React.forwardRef(
  (
    {
      jobs = [],
      heading = "",
      open = false,
      search,
      searchHandler,
      navParam = null,
      ...options
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(open);
    const [openSearch, setOpenSearch] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const { isDarkMode } = useContext(DarkLightModeContext);

    return (
      <>
        <Box {...options}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ alignSelf: "center" }}
            >
              {heading}
            </Typography>

            <Box>
              <IconButton
                edge="start"
                onClick={() => {
                  setIsOpen(!isOpen);
                  if (openSearch) {
                    setOpenSearch(false);
                  }
                }}
              >
                {isOpen ? (
                  <ArrowDropUpIcon fontSize="large" />
                ) : (
                  <ArrowDropDownIcon fontSize="large" />
                )}
              </IconButton>
            </Box>
          </Box>
          {isOpen && (
            <Box
              sx={
                {
                  // overflow: "scroll",
                  // maxHeight: "250px",
                }
              }
            >
              {jobs.map((job, i) => {
                if (jobs.length === i + 1) {
                  return (
                    <Box
                      ref={ref}
                      key={i}
                      sx={{
                        padding: "7px",
                        marginBottom: "5px",
                      }}
                      onClick={() =>
                        navigate(`/jobs/${job.id}${navParam ? navParam : ""}`)
                      }
                    >
                      <p
                        style={{ margin: 0, fontWeight: "bold", fontSize: 16 }}
                      >
                        {job.job_name ? job.job_name : "n/a"}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontSize: 12,
                        }}
                      >
                        {job.address}
                      </p>
                      <Typography>{job.status_name}</Typography>
                    </Box>
                  );
                }
                return (
                  <>
                    <Box
                      key={i}
                      sx={{
                        padding: "7px",
                        marginBottom: "5px",
                        cursor: "pointer",
                        "&:hover": {
                          background: isDarkMode
                            ? "#5A5A5A"
                            : theme.palette.secondary.light,
                        },
                      }}
                      onClick={() =>
                        navigate(`/jobs/${job.id}${navParam ? navParam : ""}`)
                      }
                    >
                      <p
                        style={{ margin: 0, fontWeight: "bold", fontSize: 16 }}
                      >
                        {job.job_name ? job.job_name : "n/a"}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontSize: 12,
                        }}
                      >
                        {job.address}
                      </p>
                      <Typography
                        style={{
                          fontStyle: "italic",
                          fontSize: 12,
                        }}
                      >
                        {job.status_name}
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                );
              })}
            </Box>
          )}
        </Box>
      </>
    );
  }
);
export default Jobcard;
