import { AuthContext } from "../../../../../../context/AuthContext";
import api from "../../../../../../api/InventoryTracking";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import DamageReportView from "./DamageReportView";

const DamageComponent = () => {
  const { authToken } = useContext(AuthContext);
  const header = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const { type: assetType, id: assetId } = useParams();
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(null);

  const [damageReports, setDamageReports] = useState([]);

  const getAllDamageReports = async () => {
    try {
      const result = await api.get(
        `/assets/${assetType}/${assetId}/damage`,
        header
      );
      console.log(result);
      setDamageReports(result.data.damageReports);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDamageReports();
  }, []);

  return (
    <>
      <DamageReportView
        data={damageReports}
        isLoading={isLoading}
        error={error}
      />
    </>
  );
};

export default DamageComponent;
