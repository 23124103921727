import { useRef, useCallback } from "react";

const useInfiniteScroll = (callback, dependancies, predicate, isLoading) => {
  const observer = useRef();
  const lastElement = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && predicate) {
          console.log("visible");
          callback();
        }
      });
      if (node) observer.current.observe(node);
      // console.log("NODE: ", node);
    },
    [...dependancies]
  );

  return lastElement;
};
export default useInfiniteScroll;
