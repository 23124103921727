import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { Typography } from "@mui/material";

const CommittedItem = (props) => {
  const { jobId, committedId } = props;
  const { authToken } = useContext(AuthContext);
  const [details, setDetails] = useState(null);

  const getCommittedItemDetails = async () => {
    try {
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/committed-items/${committedId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      setDetails(response.data.committed);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCommittedItemDetails();
  }, [committedId]);

  if (details)
    return (
      <Typography {...props}>
        {details.description} [UOM: {details.uom}]
      </Typography>
    );
};

export default CommittedItem;
