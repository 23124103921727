import { useState, useContext, useEffect } from "react";
import { useDebounce } from "use-debounce";
import InventoryTracking from "../api/InventoryTracking";

const useVehicles = (authToken, branch) => {
  const [results, setResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue] = useDebounce(searchQuery, 1000);
  const [loading, setLoading] = useState(false);

  const search = async (controller) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const result = await InventoryTracking.get(
        `/vehicles?search=${searchQuery}&branch=${branch}`,
        data
      );

      setResults(result.data.vehicles);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    if (searchValue != undefined && branch != undefined) search(controller);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [searchValue, branch]);

  return [searchQuery, setSearchQuery, results, loading];
};
export default useVehicles;
