import { Box, Card, useMediaQuery, useTheme } from "@mui/material";

const PageContent = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (isSmallScreen) {
    return (
      <Box sx={{ mt: "60px" }} {...props}>
        {props.children}
      </Box>
    );
  } else {
    return (
      // <Card sx={{ padding: "16px", mt: "45px" }} {...props}>
      //   {props.children}
      // </Card>
      <Box sx={{ padding: "16px", mt: "45px" }} {...props}>
        {props.children}
      </Box>
    );
  }
};

export default PageContent;
