import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextareaAutosize,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import InventoryTracking from "../../../../api/InventoryTracking";
import { AuthContext } from "../../../../context/AuthContext";
import ListItem from "../../../../component/ListItem";
import PendingPoModal from "../../../../component/PendingPoModal";
import PageContent from "../../../../component/PageContent";
import serverTimeConverter from "../../../../helpers/serverTimeConverter";
import ReturnsTab from "./Tabs/ReturnsTab";
import CommittedTab from "./Tabs/CommittedTab";
import DeliveredTab from "./Tabs/DeliveredTab";
import StagedTab from "./Tabs/StagedTab";
import DailyStockoutsTab from "./Tabs/DailyStockoutsTab";
import PullRequestsTab from "./Tabs/PullRequestsTab";
import PurchaseOrdersTab from "./Tabs/PurchaseOrdersTab";
import MaterialActivitiesTab from "./Tabs/MaterialActivitiesTab";
import LoadedOnTruckTab from "./Tabs/LoadedOnTruckTab";
import { useTheme } from "@emotion/react";

const JobDetails = () => {
  const { id, section } = useParams();
  const { authToken } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [details, setDetails] = useState(null);
  const navigate = useNavigate();
  const defaultTab = (sectionParam) => {
    const tabParamPairings = [
      { param: "pos", tab: "POS" },
      { param: "pull-requests", tab: "PULL REQUESTS" },
      { param: "stock-outs", tab: "STOCK OUTS" },
      { param: "staged", tab: "STAGED" },
      { param: "loaded", tab: "LOADED TRUCKS" },
      { param: "delivered", tab: "DELIVERED" },
      { param: "committed", tab: "COMMITTED" },
      { param: "returns", tab: "RETURNS" },
    ];
    const validParam = tabParamPairings.find(
      (tab) => tab.param === sectionParam
    );
    return validParam ? validParam.tab : "POS";
  };
  const [activeTab, setActiveTab] = useState(defaultTab(section));

  const getJobDetails = async () => {
    try {
      const response = await InventoryTracking.get(`/jobs/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("Job Details", response.data);
      setDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (section) {
      console.log("section", section);
      setActiveTab(defaultTab(section));
    }
  }, [section]);

  useEffect(() => {
    getJobDetails();
  }, []);

  return (
    <PageContent>
      {details ? (
        <Grid container>
          <Grid
            item
            xs={12}
            //sm={8}
            sm={12}
          >
            <Grid container>
              <Grid item xs={12}>
                <div style={{ padding: 16 }}>
                  <h2
                    style={{
                      textAlign: "center",
                      flex: 1,
                      margin: 0,
                    }}
                  >
                    {details.job_name} - {details.projectid}
                  </h2>
                  <div style={{ textAlign: "center" }}>
                    Branch: {details.branch}
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {/* Address: {details.address} */}
                    Address:{" "}
                    <a href={`https://maps.google.com/?q=${details.address}`}>
                      {details.address}
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginLeft: 0,
                  marginRight: 0,
                  marginBottom: "16px",
                }}
              >
                <Tabs
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  // textColor="#fff"
                  // indicatorColor="#fff"
                  value={activeTab}
                  onChange={(event, newValue) => {
                    // setActiveTab(newValue);
                    switch (newValue) {
                      case "POS":
                        navigate(`/jobs/${id}/pos`, { replace: true });
                        break;
                      case "PULL REQUESTS":
                        navigate(`/jobs/${id}/pull-requests`, {
                          replace: true,
                        });
                        break;
                      case "STOCK OUTS":
                        navigate(`/jobs/${id}/stock-outs`, { replace: true });
                        break;
                      case "STAGED":
                        navigate(`/jobs/${id}/staged`, { replace: true });
                        break;
                      case "LOADED TRUCKS":
                        navigate(`/jobs/${id}/loaded`, { replace: true });
                        break;
                      case "DELIVERED":
                        navigate(`/jobs/${id}/delivered`, { replace: true });
                        break;
                      case "COMMITTED":
                        navigate(`/jobs/${id}/committed`, { replace: true });
                        break;
                      case "RETURNS":
                        navigate(`/jobs/${id}/returns`, { replace: true });
                        break;
                    }
                  }}
                >
                  <Tab
                    label="Vendor POs"
                    value="POS"
                    sx={{
                      height: "90px",
                      width: "100px",
                      // backgroundColor: activeTab === "POS" ? "#000" : "#fff",
                      // color:
                      //   activeTab === "POS" ? "#fff" : "rgba(0, 0, 0, 0.6)",
                    }}
                  />
                  <Tab
                    label="Pull Requests"
                    value="PULL REQUESTS"
                    sx={{
                      height: "90px",
                      width: "100px",
                      // backgroundColor:
                      //   activeTab === "PULL REQUESTS" ? "#000" : "#fff",
                      // color:
                      //   activeTab === "PULL REQUESTS"
                      //     ? "#fff"
                      //     : "rgba(0, 0, 0, 0.6)",
                    }}
                  />
                  <Tab
                    label="Stock Outs"
                    value="STOCK OUTS"
                    sx={{
                      height: "90px",
                      width: "100px",
                      // backgroundColor:
                      //   activeTab === "STOCK OUTS" ? "#000" : "#fff",
                      // color:
                      //   activeTab === "STOCK OUTS"
                      //     ? "#fff"
                      //     : "rgba(0, 0, 0, 0.6)",
                    }}
                  />
                  <Tab
                    label="Staged"
                    value="STAGED"
                    sx={{
                      height: "90px",
                      width: "100px",
                      // backgroundColor: activeTab === "STAGED" ? "#000" : "#fff",
                      // color:
                      //   activeTab === "STAGED" ? "#fff" : "rgba(0, 0, 0, 0.6)",
                    }}
                  />
                  <Tab
                    label="Loaded Trucks"
                    value="LOADED TRUCKS"
                    sx={{
                      height: "90px",
                      width: "100px",
                      // backgroundColor:
                      //   activeTab === "LOADED TRUCKS" ? "#000" : "#fff",
                      // color:
                      //   activeTab === "LOADED TRUCKS"
                      //     ? "#fff"
                      //     : "rgba(0, 0, 0, 0.6)",
                    }}
                  />
                  <Tab
                    label="Delivered"
                    value="DELIVERED"
                    sx={{
                      height: "90px",
                      width: "100px",
                      // backgroundColor:
                      //   activeTab === "DELIVERED" ? "#000" : "#fff",
                      // color:
                      //   activeTab === "DELIVERED"
                      //     ? "#fff"
                      //     : "rgba(0, 0, 0, 0.6)",
                    }}
                  />
                  <Tab
                    label="Committed"
                    value="COMMITTED"
                    sx={{
                      height: "90px",
                      width: "100px",
                      // backgroundColor:
                      //   activeTab === "COMMITTED" ? "#000" : "#fff",
                      // color:
                      //   activeTab === "COMMITTED"
                      //     ? "#fff"
                      //     : "rgba(0, 0, 0, 0.6)",
                    }}
                  />
                  <Tab
                    label="Returns"
                    value="RETURNS"
                    sx={{
                      height: "90px",
                      width: "100px",
                      // backgroundColor:
                      //   activeTab === "RETURNS" ? "#000" : "#fff",
                      // color:
                      //   activeTab === "RETURNS" ? "#fff" : "rgba(0, 0, 0, 0.6)",
                    }}
                  />
                </Tabs>
              </Box>
              {/* <div hidden={activeTab !== "MATERIAL ACTIVITIES"}>
                <MaterialActivitiesTab jobId={id} />
              </div> */}
              <div hidden={activeTab !== "POS"}>
                <PurchaseOrdersTab jobId={id} />
              </div>
              <div hidden={activeTab !== "PULL REQUESTS"}>
                <PullRequestsTab jobId={id} />
              </div>
              <div hidden={activeTab !== "STOCK OUTS"}>
                <DailyStockoutsTab jobId={id} />
              </div>
              <div hidden={activeTab !== "STAGED"}>
                <StagedTab jobId={id} />
              </div>
              <div hidden={activeTab !== "LOADED TRUCKS"}>
                <LoadedOnTruckTab jobId={id} />
              </div>
              <div hidden={activeTab !== "DELIVERED"}>
                <DeliveredTab jobId={id} />
              </div>
              <div hidden={activeTab !== "COMMITTED"}>
                <CommittedTab jobId={id} />
              </div>
              <div hidden={activeTab !== "RETURNS"}>
                <ReturnsTab jobId={id} />
              </div>
            </div>
          </Grid>
        </Grid>
      ) : (
        <div style={{ height: "100%" }}>
          <div>Loading...</div>
        </div>
      )}
    </PageContent>
  );
};

export default JobDetails;
