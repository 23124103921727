import axios from "axios";

const REACT_APP_INVENTORY_TRACKING_API =
  process.env.REACT_APP_INVENTORY_TRACKING_API;

export default axios.create({
  baseURL: REACT_APP_INVENTORY_TRACKING_API
    ? REACT_APP_INVENTORY_TRACKING_API
    : "http://localhost:8080",
});
