import { Box, Button, Card, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AssetContext } from "../../../../context/AssetContext";
import composePage from "../HOCs/composePage";
import makePage from "../HOCs/makePage";
import withConditionalFeedback from "../HOCs/withConditionalFeedback";
import withSearchBar from "../HOCs/withSearchBar";
import { useTheme } from "@emotion/react";

const EmployeeListView = ({ data, callback }) => {
  const navigate = useNavigate();
  const { updateAssetAssignment, assetAssignment } = useContext(AssetContext);
  const { assetType, assetId } = assetAssignment.asset;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleEmployeeSelect = (user) => {
    console.log(user);
    updateAssetAssignment({
      type: "SET-USER",
      value: {
        id: user.id,
        fullName: user.full_name,
      },
    });
  };

  return (
    <Box>
      <Typography textAlign={"center"} mb={"1em"} variant="h6">
        {assetAssignment.targetAssignee.userName
          ? `Assign To ${assetAssignment.targetAssignee.userName}`
          : "Select Employee To Assign Tool:"}
      </Typography>
      <Box sx={{ maxHeight: "25rem", overflowY: "scroll" }}>
        {data.map((user, index) => {
          if (data.length === index + 1) {
            return (
              <Card
                ref={callback}
                sx={styles.card}
                key={index}
                onClick={() => handleEmployeeSelect(user)}
              >
                <Typography variant="overline" sx={styles.name}>
                  {user.full_name}
                </Typography>
                <Typography variant="caption">{user.branch}</Typography>
              </Card>
            );
          } else {
            return (
              <Card
                sx={styles.card}
                key={index}
                onClick={() => handleEmployeeSelect(user)}
              >
                <Typography variant="overline" sx={styles.name}>
                  {user.full_name}
                </Typography>
                <Typography variant="caption">{user.branch}</Typography>
              </Card>
            );
          }
        })}
      </Box>
      {assetAssignment.targetAssignee.userName && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            mt: "2rem",
          }}
        >
          <Button
            variant="contained"
            fullWidth={isSmallScreen ? true : false}
            onClick={() => navigate(`/assets/${assetType}/${assetId}`)}
          >
            continue
          </Button>
        </Box>
      )}
    </Box>
  );
};
export default composePage(
  makePage,
  withSearchBar,
  withConditionalFeedback
)(EmployeeListView);

const styles = {
  card: {
    padding: ".5em",
    marginBottom: ".5em",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  name: {
    fontWeight: "600",
  },
};
