import { Box, Card, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import serverTimeConverter from "../../../../../helpers/serverTimeConverter";

const AssignedAssetModal = ({ assets, open, handleClose, user }) => {
  const navigate = useNavigate();
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        {!assets ||
          (assets.length < 1 && (
            <Typography variant="overline">
              Nothing Assigned to{" "}
              {user && user.full_name ? user.full_name : "User"}
            </Typography>
          ))}
        {assets.map((ass) => (
          <Card
            key={ass.id}
            sx={{ padding: 1 }}
            onClick={() =>
              navigate(`/assets/${ass.asset_type}/${ass.asset_id}`)
            }
          >
            <Typography>In possession of: {ass.asset_name}</Typography>
            <Typography>
              Since{" "}
              {serverTimeConverter
                .convertServerCreatedOnTimeStampToPhoenix(ass.date_accepted)
                .format("MM/DD/YYYY")}
            </Typography>
          </Card>
        ))}
      </Box>
    </Modal>
  );
};
export default AssignedAssetModal;
