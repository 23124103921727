import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  Divider,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ListItem from "../../../component/ListItem";
import { AuthContext } from "../../../context/AuthContext";
import InventoryTracking from "../../../api/InventoryTracking";
import useSearchAndPagination from "../../../customHooks/useSearchAndPagination";
import SearchAndPagination from "../../../component/SearchAndPagination";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import PendingPoModal from "../../../component/PendingPoModal";
import PrintableQRCode from "../../../component/PrintableQRCode";
import escapeCharacters from "../../../helpers/escapeCharacters";
import PageContent from "../../../component/PageContent";
import serverTimeConverter from "../../../helpers/serverTimeConverter";
import { useTheme } from "@emotion/react";
import { DarkLightModeContext } from "../../../context/DarkLightModeContext";

const PO_STATUS_PENDING = "PENDING";
const PO_STATUS_RECEIVED = "RECEIVED";
const PO_STATUS_ALL = "ALL";

const PendingPOs = () => {
  const navigate = useNavigate();
  const { poId } = useParams();
  const { authToken, userBranch } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [locations, setLocations] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState(0);
  const [isTile, setIsTile] = useState(false);
  const [pendingPos, setPendingPos] = useState([]);
  const [pendingPoModalData, setPendingPoModalData] = useState(null);
  const theme = useTheme();
  const { isDarkMode } = useContext(DarkLightModeContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [poStatus, setPoStatus] = useState(PO_STATUS_PENDING);

  const searchAndPagination = useSearchAndPagination(userBranch);
  const { search, location, page, limit, setPages, setPage } =
    searchAndPagination;

  const getLocations = async () => {
    try {
      const response = await InventoryTracking.get(`/locations`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("locations", response.datalocations);
      setLocations(response.data.locations);
    } catch (error) {
      console.log(error);
    }
  };

  const getVendorsByLocation = async () => {
    try {
      const response = await InventoryTracking.get(
        `/vendors?location=${location}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("vendors", response.data.vendors);
      setVendors(response.data.vendors);
    } catch (error) {
      console.log(error);
    }
  };

  const getPendingPoById = async (id) => {
    try {
      const response = await InventoryTracking.get(`/po/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("pending po", response.data);
      setPendingPoModalData({
        ...response.data,
        imageBlobs: [],
        imageUrls: [],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getPendingPos = async () => {
    try {
      const response = await InventoryTracking.get(
        `/po?search=${escapeCharacters(
          search
        )}&location=${location}&page=${page}&limit=${limit}&status=${poStatus}&vendor=${vendor}&isTile=${isTile}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Pending POs", response.data.pos);
      setPendingPos(response.data.pos);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (locations.length === 0) getLocations();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      getPendingPos();
    }
  }, [locations, location, page, search, poStatus, vendor, isTile]);

  useEffect(() => {
    getVendorsByLocation();
    setVendor(0);
  }, [location]);

  useEffect(() => {
    if (poId) {
      getPendingPoById(poId);
    }
  }, []);

  return (
    <PageContent>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!isSmallScreen && (
          <PrintableQRCode
            url={`${window.location.host}/pending-pos`}
            displaySize={100}
            printSize={100}
            subtext="Pending PO's"
          />
        )}
      </div>
      <h2 style={{ textAlign: "center" }}>POs</h2>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: isSmallScreen ? "flex-end" : "center",
        }}
      >
        <div style={{ width: isSmallScreen ? "inherit" : "100%" }}>
          <SearchAndPagination
            stateHandlers={searchAndPagination}
            locationOptions={locations.map((l) => l.location)}
            hideBranch={true}
          />
        </div>
        <TextField
          select
          value={isTile}
          label="Tile/Non-Tile Orders"
          onChange={(e) => setIsTile(e.target.value)}
          size="small"
          sx={{
            width: "270px",
          }}
        >
          <MenuItem value={false}>Non-Tile</MenuItem>
          <MenuItem value={true}>Tile</MenuItem>
        </TextField>
        <TextField
          select
          value={vendor}
          label="Vendor"
          onChange={(e) => setVendor(e.target.value)}
          size="small"
          sx={{
            marginLeft: "12px",
            width: "270px",
          }}
        >
          <MenuItem value={0}>All</MenuItem>
          {vendors.map((vendor, index) => (
            <MenuItem key={index} value={vendor.id}>
              {vendor.provider}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          value={poStatus}
          label="Status"
          onChange={(e) => setPoStatus(e.target.value)}
          size="small"
          sx={{
            marginLeft: "12px",
            width: "270px",
          }}
        >
          <MenuItem value={PO_STATUS_PENDING}>PENDING</MenuItem>
          <MenuItem value={PO_STATUS_RECEIVED}>RECEIVED</MenuItem>
          <MenuItem value={PO_STATUS_ALL}>ALL</MenuItem>
        </TextField>
      </div>
      <div>
        {pendingPos &&
          pendingPos.map((po, index) => {
            let itemDetails = [];
            // let emphasize = [];
            // let highlight = [];
            // let highlightOn = true;
            // const blue = "#7ab4eb";
            // const yellow = "#e6e681";
            // const green = "#03ab39";
            // const grey = "#d2d2d2";

            // let highlightColor = blue;
            let jobName;
            let status;

            if (po.job_name) {
              jobName = po.job_name;
              itemDetails.push(`Job: ${po.job_name}`);
              // emphasize.push(`Job: ${po.job_name}`);
            }

            if (po.poid.includes("INV-")) {
              if (po.status === 8) {
                if (po.is_partially_received == 1) {
                  // highlightOn = true;
                  // highlightColor = blue;
                  status = `PARTIALLY RECEIVED`;
                  itemDetails.push(`Status: PARTIALLY RECEIVED`);
                  // emphasize.push(`Status: PARTIALLY RECEIVED`);
                  // highlight.push(`Status: PARTIALLY RECEIVED`);
                } else if (po.is_partially_received == 0) {
                  // highlightOn = true;
                  // highlightColor = green;
                  status = `READY TO POST`;
                  itemDetails.push(`Status: READY TO POST`);
                  // emphasize.push(`Status: READY TO POST`);
                  // highlight.push(`Status: READY TO POST`);
                } else {
                  // po.is_partially_received == null
                  status = `NOT YET RECEIVED`;
                  itemDetails.push(`Status: NOT YET RECEIVED`);
                  // emphasize.push(`Status: NOT YET RECEIVED`);
                  // highlight.push(`Status: NOT YET RECEIVED`);
                }
              } else {
                status = `RECEIVED AND POSTED`;
                itemDetails.push(`Status: RECEIVED AND POSTED`);
                // emphasize.push(`Status: RECEIVED AND POSTED`);
                // highlight.push(`Status: RECEIVED AND POSTED`);
                // highlightColor = grey;
              }
            }
            // Non-Inventory/Regular PO
            else if (po.is_received == 1) {
              // PO is Received and Posted
              if (po.status == 9) {
                status = `RECEIVED AND POSTED`;
                itemDetails.push(`Status: RECEIVED AND POSTED`);
                // emphasize.push(`Status: RECEIVED AND POSTED`);
                // highlight.push(`Status: RECEIVED AND POSTED`);
                // highlightColor = grey;
              } // PO is Received and Ready to Post
              else {
                status = `RECEIVED AND READY TO POST IN BRM`;
                itemDetails.push(`Status: RECEIVED AND READY TO POST IN BRM`);
                // emphasize.push(`Status: RECEIVED AND READY TO POST IN BRM`);
                // highlight.push(`Status: RECEIVED AND READY TO POST IN BRM`);
                // highlightColor = green;
              }
            } else {
              if (po.is_partially_received == null) {
                status = `NOT YET RECEIVED`;
                itemDetails.push(`Status: NOT YET RECEIVED`);
                // emphasize.push(`Status: NOT YET RECEIVED`);
                // highlight.push(`Status: NOT YET RECEIVED`);
              } else {
                // If po.is_partially_received == 1 or 0 just mark it as partially received
                // It is not Ready to Post until the whole PO is marked as received
                // highlightOn = true;
                // highlightColor = yellow;
                status = `PARTIALLY RECEIVED`;
                itemDetails.push(`Status: PARTIALLY RECEIVED`);
                // emphasize.push(`Status: PARTIALLY RECEIVED`);
                // highlight.push(`Status: PARTIALLY RECEIVED`);
              }
            }

            let vendorName;
            if (po.vendor_name) {
              vendorName = po.vendor_name;
              itemDetails.push(`Vendor: ${po.vendor_name}`);
            }

            let orderDate;
            if (po.date_created) {
              orderDate = serverTimeConverter
                .convertServerCreatedOnTimeStampToPhoenix(po.date_created)
                .format("MM/DD/YY");
              itemDetails.push(
                `Order Date: ${serverTimeConverter
                  .convertServerCreatedOnTimeStampToPhoenix(po.date_created)
                  .format("MM/DD/YY")}`
              );
            }

            let jobsiteDeliveryType;
            if (po.jobsite_delivery_type_description) {
              jobsiteDeliveryType = po.jobsite_delivery_type_description;
              itemDetails.push(
                `Jobsite Delivery Type: ${po.jobsite_delivery_type_description}`
              );
            }
            // return (
            //   <ListItem
            //     key={index}
            //     title={`${po.poid} - ${
            //       po.deliver_type_description
            //         ? po.deliver_type_description
            //         : `Deliver to ${
            //             po.delivery_location[0].toUpperCase() +
            //             po.delivery_location.slice(1).toLowerCase()
            //           }`
            //     }`}
            //     details={itemDetails}
            //     emphasize={emphasize}
            //     highlight={highlight}
            // onClick={() => {
            //   getPendingPoById(po.id);
            // }}
            //     highlightColor={highlightColor}
            //     highlightOn={highlightOn}
            //   />
            // );
            return (
              <>
                <Box
                  sx={{
                    padding: "16px",
                    cursor: "pointer",
                    "&:hover": {
                      background: isDarkMode
                        ? "#5A5A5A"
                        : theme.palette.secondary.light,
                    },
                  }}
                  key={index}
                  onClick={() => {
                    getPendingPoById(po.id);
                  }}
                >
                  <ListItemText
                    primary={`${po.poid} - ${
                      po.deliver_type_description
                        ? po.deliver_type_description
                        : `Deliver to ${
                            po.delivery_location[0].toUpperCase() +
                            po.delivery_location.slice(1).toLowerCase()
                          }`
                    }`}
                    secondary={
                      <>
                        {jobName && (
                          <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                            Job: {jobName}
                          </Typography>
                        )}

                        {vendorName && (
                          <Typography sx={{ fontSize: 12 }}>
                            Vendor: {vendorName}
                          </Typography>
                        )}
                        {orderDate && (
                          <Typography sx={{ fontSize: 12 }}>
                            Order Date: {orderDate}
                          </Typography>
                        )}
                        {jobsiteDeliveryType && (
                          <Typography sx={{ fontSize: 12 }}>
                            Jobsite Delivery: {jobsiteDeliveryType}
                          </Typography>
                        )}
                      </>
                    }
                  />

                  <Box sx={{ paddingTop: "4px" }}>
                    {status && (
                      <Typography
                        // variant="subtitle2"
                        sx={{
                          fontSize: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#c4c4c4",
                          display: "inline",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          paddingLeft: "16px",
                          paddingRight: "16px",
                          borderRadius: "16px",
                        }}
                      >
                        {status}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Divider />
              </>
            );
          })}
        <PendingPoModal
          pendingPoModalData={pendingPoModalData}
          setPendingPoModalData={setPendingPoModalData}
          //receivePendingPo={receivePendingPo}
          //saveItemQuantityReceived={saveItemQuantityReceived}
          //isLoading={isLoading}
          onUpdatePo={() => {
            // Reset page data
            getPendingPos();
          }}
        />
      </div>
    </PageContent>
  );
};

export default PendingPOs;
