import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import toFixedIfNecessary from "../helpers/toFixedIfNecessary";
import { useTheme } from "@emotion/react";

const EditNonMaterialPoItem = ({
  poItem,
  //enableUpdateQuantity,
  //onUpdateOrderedQuantity,
  //onUpdateReceivedQuantity,
  onUpdatePrice,
  disableDelete,
  onDelete,
  disabled,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  if (poItem.is_deleted) return null;

  if (isSmallScreen)
    return (
      <Card sx={{ marginBottom: "4px", position: "relative" }}>
        <div
          style={{
            padding: 12,
            flex: 1,
            fontSize: 12,
            fontWeight: "bold",
            display: "flex",
            //justifyContent: "center",
            // /alignItems: "center",
          }}
        >
          <p style={{ flex: 1, margin: 0 }}>{poItem.description}</p>
          {!disableDelete && (
            <CloseIcon
              style={{
                cursor: "pointer",
              }}
              color="error"
              onClick={() => {
                onDelete();
              }}
            />
          )}
        </div>
        <CardContent
          style={{
            borderRadius: 0,
            marginTop: 1,
            display: "flex",
            flexDirection: "row",
            padding: 8,
            alignItems: "center",
          }}
        >
          <TextField
            disabled
            style={{
              width: 100,
              paddingLeft: 4,
              paddingRight: 4,
            }}
            label="Quantity"
            type="number"
            value={`${poItem.quantity}`}
          />
          <div
            style={{
              width: 80,
              fontSize: 12,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ margin: 0, textAlign: "center" }}>
              {poItem.unit_measure_desc}
            </p>
          </div>
          {/* <div style={{ width: 100, fontSize: 12 }}>
            <p
              style={{
                margin: 0,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {poItem.quantity}
            </p>
          </div> */}

          <TextField
            disabled={disabled}
            style={{
              width: 100,
              paddingLeft: 4,
              paddingRight: 4,
            }}
            label="Price"
            type="number"
            value={`${poItem.price}`}
            onChange={(e) => {
              let val;
              if (
                e.target.value == "" ||
                e.target.value === undefined ||
                e.target.value === null ||
                e.target.value === "0" ||
                e.target.value === "00"
              ) {
                val = 0;
              } else {
                val = e.target.value.replace(/^0+/, "");
                val = toFixedIfNecessary(val, 2);
              }
              // Do Not Allow Negative
              if (val < 0) {
                val = 0;
              }

              onUpdatePrice(val);
            }}
          />
        </CardContent>
      </Card>
    );
  else
    return (
      <div>
        <div>
          <div
            style={{
              borderRadius: 0,
              marginTop: 1,
              display: "flex",
              flexDirection: "row",
              padding: 8,
              position: "relative",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                fontSize: 12,
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ flex: 1, margin: 0 }}>{poItem.description}</p>
            </div>

            <div
              style={{
                width: 80,
                fontSize: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ margin: 0, textAlign: "center" }}>
                {poItem.unit_measure_desc}
              </p>
            </div>
            <div style={{ width: 100, fontSize: 12 }}>
              <p
                style={{
                  margin: 0,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {poItem.quantity}
              </p>
            </div>

            <TextField
              disabled={disabled}
              style={{
                width: 100,
                paddingLeft: 4,
                paddingRight: 4,
              }}
              label="Price"
              type="number"
              value={`${poItem.price}`}
              onChange={(e) => {
                let val;
                if (
                  e.target.value == "" ||
                  e.target.value === undefined ||
                  e.target.value === null ||
                  e.target.value === "0" ||
                  e.target.value === "00"
                ) {
                  val = 0;
                } else {
                  val = e.target.value.replace(/^0+/, "");
                  val = toFixedIfNecessary(val, 2);
                }
                // Do Not Allow Negative
                if (val < 0) {
                  val = 0;
                }

                onUpdatePrice(val);
              }}
            />
            {!disableDelete && (
              <CloseIcon
                style={{
                  cursor: "pointer",
                  // position: "absolute",
                  // top: 12,
                  // right: 0,
                }}
                color="error"
                onClick={() => {
                  onDelete();
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
};

export default EditNonMaterialPoItem;
