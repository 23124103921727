const escapeCharacters = (string) => {
  let newString = string;
  new Map([
    ["<", "%3C"],
    [">", "%3E"],
    ["#", "%23"],
    ["+", "%2B"],
    ["{", "%7B"],
    ["}", "%7D"],
    ["'", "%60"],
    ["^", "%5E"],
    ["[", "%5B"],
    ["]", "%5D"],
    [";", "%3B"],
    ["?", "%3F"],
    [":", "%3A"],
    ["@", "%40"],
    ["=", "%3D"],
    ["&", "%26"],
    ["$", "%24"],
  ]).forEach((val, key) => (newString = newString.replace(key, val)));
  return newString;
};
export default escapeCharacters;
