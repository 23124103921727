import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import React, { useRef } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";

const PrintableQRCode = ({
  url,
  additionalText,
  subtext,
  topText,
  displaySize,
  printSize,
}) => {
  const componentRef = useRef();
  const buttonRef = useRef();

  const qrCodeClick = () => {
    const button = buttonRef.current;
    button.click();
  };

  return (
    <>
      {/* QR Code Displayed to user */}
      {/* <button onClick={getShortUrl}>SHORT</button> */}
      <QRCode
        bgColor="#FFFFFF"
        fgColor="#000000"
        level="L" //'L' 'M' 'Q' 'H'
        size={displaySize} // display size
        title={subtext}
        value={url}
        onClick={qrCodeClick}
        style={{ cursor: "pointer" }}
      />
      <div style={{ overflow: "hidden", height: 0, width: 0 }}>
        <ReactToPrint
          trigger={() => {
            return (
              <Button
                ref={buttonRef}
                sx={{ display: "none" }}
                variant="contained"
              >
                Print All
              </Button>
            );
          }}
          content={() => componentRef.current}
        />
        <div
          ref={componentRef}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          {/* QR Code For Printing (NOT Displayed to User, Except on Print Preview) */}
          {topText && (
            <div style={{ marginBottom: "5px", width: "100px" }}>
              <p style={{ color: "#000", textAlign: "center" }}>{topText}</p>
            </div>
          )}
          <QRCode
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="L" //'L' 'M' 'Q' 'H'
            size={printSize} // print size
            title="Hello"
            value={url}
          />
          <div style={{ marginTop: "5px", width: "100px" }}>
            <p style={{ color: "#000", textAlign: "center" }}>{subtext}</p>
            {additionalText && (
              <p style={{ color: "#000", textAlign: "center" }}>
                {additionalText}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintableQRCode;
