import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import api from "../../../api/InventoryTracking";

import Tool from "./Tool";

const ToolDetail = () => {
  const toolId = useParams().id;
  const { authToken, role } = useContext(AuthContext);

  const isGateKeaper = (role) => {
    const validRoles = ["Admin", "Branch Admin", "Superintendent", "Time"];
    return validRoles.includes(role);
  };

  const [tool, setTool] = useState({});
  const [toolsInPossession, setToolsInPossession] = useState([]);
  const [log, setLog] = useState([]);
  const [requested, setRequested] = useState(false);
  const [error, setError] = useState();

  const asyncPipe =
    (...fns) =>
    (x) =>
      fns.reduce(async (y, fn) => fn(await y), x);

  const logger = (key) => (value) => {
    console.log(key, value);
    return value;
  };

  const callBack =
    (func) =>
    (dataProperty = []) =>
    (data) => {
      let res = data;
      dataProperty.forEach((e) => {
        res = res[e];
      });
      func(res);
      return data;
    };

  const apiCall =
    (call) =>
    async (...callbacks) => {
      try {
        await asyncPipe(...callbacks)(call);
      } catch (error) {
        console.log(error);

        setError(error.message ? error.message : "Something went wrong...");
      }
    };

  const setToolState = callBack(setTool)(["data", "tool"]);
  const getToolById = apiCall(
    api.get(`/tools/${toolId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
  const logTool = callBack(logger("TOOL: "))(["data", "tool"]);

  const setLogsState = callBack(setLog)(["data", "logs"]);
  const getToolLogs = apiCall(
    api.get(`/tools/${toolId}/logs`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  );

  const requestTool = () =>
    apiCall(
      api
        .post(
          `/tools/${toolId}/requests`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((v) => setRequested(true))
        .catch((e) => console.log(e))
    );

  const setPossessionState = callBack(setToolsInPossession)(["data", "tools"]);
  const getToolsCurrentlyAssignedToUser = apiCall(
    api.get(`/tools/possession`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  );

  const returnTool = () =>
    apiCall(
      api
        .post(
          `/tools/${toolId}/return`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then(setRequested(true))
        .catch((e) => {
          console.log(e);
          setError(e);
        })
    );

  useEffect(() => {
    getToolById(setToolState, logTool);
    getToolLogs(setLogsState);
    getToolsCurrentlyAssignedToUser(setPossessionState);
  }, []);

  return (
    <Tool
      tool={tool}
      log={log}
      possession={toolsInPossession}
      toolId={toolId}
      returnTool={returnTool}
      requested={requested}
      requestTool={requestTool}
      isGateKeaper={isGateKeaper(role)}
    />
  );
};
export default ToolDetail;
