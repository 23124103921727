import { useTheme } from "@emotion/react";
import { QrCode, QrCode2, QrCodeScannerOutlined } from "@mui/icons-material";
import { Button, IconButton, Box, Typography, Modal } from "@mui/material";
import QrScanner from "qr-scanner";
import { useEffect, useRef, useState, useCallback } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CustomQrCodeScanner = ({ onResult, buttonStyle }) => {
  const theme = useTheme();
  const videoRef = useRef();
  const scannerRef = useRef(null);
  const [isScanning, setIsScanning] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const videoElement = useCallback((node) => {
    console.log(node);
    if (node) {
      const scanner = new QrScanner(
        node,
        (result) => {
          console.log(result);
          onResult(result.data);
          console.log("STOP");
          scannerRef.current.stop();
          scannerRef.current.destroy();
          setIsScanning(false);
          handleClose();
        },
        {
          onDecodeError: (error) => {
            //console.log("Decode error", error);
            // camQrResult.textContent = error;
            // camQrResult.style.color = "inherit";
          },
          preferredCamera: "environment",
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );

      scannerRef.current = scanner;

      scanner.start().then(() => {
        setIsScanning(true);
        // handleOpen();
      });
    }
  }, []);

  const startScan = () => {
    let video = videoRef.current;
    console.log("ref = ", video);
    // const video = document.getElementById("qr-video");
    if (open && video) {
      const scanner = new QrScanner(
        video,
        (result) => {
          console.log(result);
          onResult(result.data);
          console.log("STOP");
          scannerRef.current.stop();
          scannerRef.current.destroy();
          setIsScanning(false);
        },
        {
          onDecodeError: (error) => {
            //console.log("Decode error", error);
            // camQrResult.textContent = error;
            // camQrResult.style.color = "inherit";
          },
          preferredCamera: "environment",
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );

      scannerRef.current = scanner;

      scanner.start().then(() => {
        setIsScanning(true);
        // handleOpen();
      });
    }
  };

  useEffect(() => {
    // Cleanup scanner when unmounted
    return () => {
      if (scannerRef) {
        if (scannerRef.current) {
          console.log("STOP");
          scannerRef.current.stop();
          scannerRef.current.destroy();
        }
      }
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <IconButton
        sx={[
          {
            width: 45,
            height: 45,
            borderRadius: 1,
            border: "1px solid",
            borderColor: "primary.main",
            backgroundColor: "black",
            // color: "white",
            marginLeft: "5px",
          },
          buttonStyle,
        ]}
        color="primary"
        onClick={() => {
          handleOpen();
          // startScan();
        }}
      >
        <QrCodeScannerOutlined fontSize="large" />
      </IconButton>

      <Modal
        open={open}
        onClose={() => {
          scannerRef.current.stop();
          scannerRef.current.destroy();
          setIsScanning(false);
          handleClose();
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: "auto",
            backgroundColor: "white",
          }}
        >
          <video
            id="qr-video"
            ref={videoElement}
            style={{
              //height: isScanning ? 300 : 0,
              maxWidth: isScanning ? 300 : 0,
              padding: 0,
              margin: 0,
            }}
          ></video>
          <Button
            variant="contained"
            style={{ width: 200, marginBottom: 16, marginTop: 100 }}
            onClick={() => {
              scannerRef.current.stop();
              scannerRef.current.destroy();
              setIsScanning(false);
              //onClose();
              handleClose();
            }}
          >
            Stop Scan
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CustomQrCodeScanner;
