import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../../../api/InventoryTracking";
import ListItem from "../../../../../component/ListItem";
import serverTimeConverter from "../../../../../helpers/serverTimeConverter";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";
import { Button, Card, Modal, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import jwtDecode from "jwt-decode";

const DeliveredTab = ({ jobId }) => {
  const [loading, setLoading] = useState(false);
  const [deliveredEntries, setDeliveredEntries] = useState([]);
  const [deliveredModalData, setDeliveredModalData] = useState(null);
  const { authToken } = useContext(AuthContext);
  const decoded = jwtDecode(authToken);
  let navigate = useNavigate();
  const theme = useTheme();

  const getCurrentDelivered = async () => {
    try {
      const response = await InventoryTracking.get(`/jobs/${jobId}/delivered`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("delivered", response.data);
      setDeliveredEntries(response.data.delivered);
    } catch (error) {
      console.log(error);
    }
  };

  const getDeliveredEntryDetails = async (entryId) => {
    try {
      setLoading(true);
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/delivered/${entryId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("delivered entry", response.data);
      setDeliveredModalData({ ...response.data, entryId });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const reStageDeliveredTransaction = async (entryId, transaction) => {
    try {
      setLoading(true);
      const response = await InventoryTracking.post(
        `/jobs/${jobId}/delivered/${entryId}/re-stage`,
        transaction,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("delivered entry", response.data);
      getDeliveredEntryDetails(entryId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentDelivered();
  }, []);

  useEffect(() => {
    console.log("decoded", decoded);
  }, [decoded]);

  return (
    <div>
      {deliveredEntries &&
        deliveredEntries.map((delivered, index) => {
          let details = [];
          details.push(`Items: ${delivered.items_concatenated}`);
          return (
            <ListItem
              key={index}
              title={`${serverTimeConverter
                .convertServerCreatedOnTimeStampToPhoenix(delivered.created_on)

                .format("MM/DD/YY hh:mm A z")} - ${delivered.full_name}`}
              details={details}
              onClick={() => {
                //   navigate(`/committed/${committed.id}`);
                getDeliveredEntryDetails(delivered.entry_id);
              }}
            />
          );
        })}

      <Modal
        open={deliveredModalData != null}
        onClose={() => {
          setDeliveredModalData(null);
        }}
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 300,
            maxWidth: 500,
            maxHeight: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "block",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          {deliveredModalData && (
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {serverTimeConverter
                  .convertServerCreatedOnTimeStampToPhoenix(
                    deliveredModalData.created_on
                  )
                  .format("MM/DD/YY hh:mm A z")}{" "}
                - {deliveredModalData.full_name}
              </Typography>
              <div>
                {deliveredModalData.items &&
                  deliveredModalData.items.map((item, index) => {
                    let details = [];
                    details.push(`Quantity: ${item.quantity} ${item.uom}`);

                    return (
                      <ListItem
                        key={index}
                        title={item.description}
                        details={details}
                        image={item.image_url}
                        iconRight={() => {
                          if (
                            decoded.username == "bguiang" &&
                            item.quantity > 0
                          )
                            return (
                              <LoadingButton
                                loading={loading}
                                onClick={() => {
                                  reStageDeliveredTransaction(
                                    deliveredModalData.entryId,
                                    item
                                  );
                                }}
                              >
                                Re-Stage
                              </LoadingButton>
                            );
                        }}
                        onClick={() => {}}
                      />
                    );
                  })}
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 12,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setDeliveredModalData(null);
              }}
            >
              Close
            </Button>
          </div>
        </Card>
      </Modal>
    </div>
  );
};

export default DeliveredTab;
