import makePage from "../../HOCs/makePage";
import withConditionalFeedback from "../../HOCs/withConditionalFeedback";
import { useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import api from "../../../../../api/InventoryTracking";
import QRCode from "react-qr-code";
import Print from "../../../../../component/Print";
import { Box, Button, Typography } from "@mui/material";

const PrintAssetQRCodes = () => {
  const { type: assetType, branch } = useParams();
  const { authToken } = useContext(AuthContext);
  const header = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const [isLoading, setIsloading] = useState(false);
  const [assets, setAssets] = useState([]);

  const getAssets = async () => {
    try {
      setIsloading(true);
      const result = await api.get(
        `/assets/${assetType}?search=&location=${branch}&pageSize=1000&page=1`,
        header
      );
      console.log(result);
      setAssets([...result.data.assets]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getAssets();
  }, []);

  const AssetList = () => {
    return (
      <div style={{ columns: 1, marginLeft: "15px" }}>
        {assets.map((ass, index) => (
          <div key={index}>
            <div
              style={{
                breakInside: "avoid",
                breakBefore: "always",
                breakAfter: "always",
              }}
            >
              <div
                style={{
                  marginLeft: "30%",
                  marginBottom: "0px",
                  width: "100px",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: 0,
                  }}
                >
                  {ass.name
                    ? ass.name
                    : ass.equipment_name
                    ? ass.equipment_name
                    : ass.gear_type}
                </p>
              </div>
              <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="L" //'L' 'M' 'Q' 'H'
                size={100} // display size
                title={
                  ass.name
                    ? ass.name
                    : ass.equipment_name
                    ? ass.equipment_name
                    : ass.gear_type
                }
                value={`${window.location.host}/assets/${assetType}/${ass.id}`}
                style={{
                  marginLeft: "30%",
                  marginTop: "0px",
                  marginBottom: 0,
                }}
              />
              <div
                style={{
                  marginLeft: "30%",
                  marginTop: "0px",
                  width: "100px",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  {ass.number ? ass.number : ass.serial}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="overline">LOADING...</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Print>
          <Print.Trigger>
            <Button variant="contained">Print</Button>
          </Print.Trigger>
          <Print.PrintableData>
            <div style={{ columns: 1, marginLeft: "15px" }}>
              {assets.map((ass, index) => (
                <div key={index}>
                  <div
                    style={{
                      breakInside: "avoid",
                      breakBefore: "always",
                      breakAfter: "always",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "30%",
                        marginBottom: "0px",
                        width: "100px",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          marginBottom: 0,
                        }}
                      >
                        {ass.name
                          ? ass.name
                          : ass.equipment_name
                          ? ass.equipment_name
                          : ass.gear_type}
                      </p>
                    </div>
                    <QRCode
                      bgColor="#FFFFFF"
                      fgColor="#000000"
                      level="L" //'L' 'M' 'Q' 'H'
                      size={100} // display size
                      title={
                        ass.name
                          ? ass.name
                          : ass.equipment_name
                          ? ass.equipment_name
                          : ass.gear_type
                      }
                      value={`${window.location.host}/assets/${assetType}/${ass.id}`}
                      style={{
                        marginLeft: "30%",
                        marginTop: "0px",
                        marginBottom: 0,
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "30%",
                        marginTop: "0px",
                        width: "100px",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        {ass.number ? ass.number : ass.serial}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Print.PrintableData>
        </Print>
        <AssetList />
      </Box>
    </>
  );
};
export default makePage(PrintAssetQRCodes);
