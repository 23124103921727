import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../../../api/InventoryTracking";
import ListItem from "../../../../../component/ListItem";
import serverTimeConverter from "../../../../../helpers/serverTimeConverter";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";
import {
  Box,
  Button,
  Card,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DeliverFromStaging from "./DeliverFromStaging";
import { SnackAlertContext } from "../../../../../context/SnackAlertContext";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";

const StagedTab = ({ jobId }) => {
  const [stagedTransactions, setStagedTransactions] = useState([]);

  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [selectVehicleModalOpen, setSelectVehicleModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const { authToken } = useContext(AuthContext);

  const { openSnackMessage } = useContext(SnackAlertContext);
  let navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [activities, setActivities] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().local().startOf("year").format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(moment().local().format("MM/DD/YYYY"));
  const selectStartDate = (val) => {
    setStartDate(val);
  };
  const selectEndDate = (val) => {
    setEndDate(val);
  };

  const getCurrentStaged = async () => {
    try {
      const response = await InventoryTracking.get(`/jobs/${jobId}/staged`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setStagedTransactions(response.data.staged);
    } catch (error) {
      console.log(error);
    }
  };

  // const getStagedActivities = async (controller) => {
  //   try {
  //     let beginningOfStartDate = moment(startDate, "MM/DD/YYYY")
  //       .startOf("day")
  //       .format();
  //     let endOfEndDate = moment(endDate, "MM/DD/YYYY").endOf("day").format();

  //     let data = {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     };
  //     if (controller) {
  //       data.signal = controller.signal;
  //     }

  //     const response = await InventoryTracking.get(
  //       `/jobs/${jobId}/staged/activities?startDate=${beginningOfStartDate}&endDate=${endOfEndDate}`,
  //       data
  //     );
  //     console.log("Staged Activities", response.data);
  //     setActivities(response.data.activities);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const deliverItems = async () => {
    try {
      setIsLoading(true);
      let items = [];

      // Add Selected Items in the request body
      let transactionIndex;
      let itemIndex;
      for (
        transactionIndex = 0;
        transactionIndex < stagedTransactions.length;
        transactionIndex++
      ) {
        let transaction = stagedTransactions[transactionIndex];

        transaction.checked = false;
        for (itemIndex = 0; itemIndex < transaction.items.length; itemIndex++) {
          let item = transaction.items[itemIndex];

          if (item.quantity > 0) {
            items.push(item);
          }
        }
      }

      let data = { stagedItems: items };

      const response = await InventoryTracking.post(
        `/jobs/${jobId}/delivered`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      console.log("DELIVERED!", data);
      openSnackMessage("success", "Items successfull added to delivered");

      window.location = `/jobs/${jobId}/delivered`;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const loadItemsOnTruck = async (vehicleId) => {
    try {
      setIsLoading(true);
      let items = [];

      // Add Selected Items in the request body
      let transactionIndex;
      let itemIndex;
      for (
        transactionIndex = 0;
        transactionIndex < stagedTransactions.length;
        transactionIndex++
      ) {
        let transaction = stagedTransactions[transactionIndex];

        transaction.checked = false;
        for (itemIndex = 0; itemIndex < transaction.items.length; itemIndex++) {
          let item = transaction.items[itemIndex];

          if (item.quantity > 0) {
            items.push(item);
          }
        }
      }

      let data = { stagedItems: items };

      const response = await InventoryTracking.post(
        `/jobs/${jobId}/loaded-on-truck/${vehicleId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      console.log("LOADED ON TRUCK!", data);
      openSnackMessage("success", "Items loaded on truck");

      window.location = `/jobs/${jobId}/loaded`;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentStaged();
  }, []);

  // useEffect(() => {
  //   const controller = new AbortController();

  //   getStagedActivities(controller);
  //   return () => {
  //     controller.abort(); // abort api call on unmount for cleanup
  //   };
  // }, [startDate, endDate]);

  return (
    <div>
      <DeliverFromStaging
        isLoading={isLoading}
        stagedTransactions={stagedTransactions}
        setStagedTransactions={setStagedTransactions}
        deliverItems={deliverItems}
        loadItemsOnTruck={loadItemsOnTruck}
        previewModalOpen={previewModalOpen}
        setPreviewModalOpen={setPreviewModalOpen}
        selectVehicleModalOpen={selectVehicleModalOpen}
        setSelectVehicleModalOpen={setSelectVehicleModalOpen}
        onTransactionClick={(transactionId) => {
          navigate(`/jobs/${jobId}/staged/${transactionId}`);
        }}
      />
      {/* <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <h2
            style={{
              flex: 1,
              margin: 0,
            }}
          >
            History
          </h2>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                label="From"
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={selectStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
              <MobileDatePicker
                label="To"
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={selectEndDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
        </div>

        <TableContainer component={Box}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Transferred To</TableCell>
                <TableCell>Transferred By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activities &&
                activities.map((activity, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      {serverTimeConverter
                        .convertServerCreatedOnTimeStampToPhoenix(
                          activity.created_on
                        )
                        .format("MM/DD/YY h:mm a")}
                    </TableCell>
                    <TableCell
                      style={{
                        color: activity.quantity < 0 ? "red" : "green",
                      }}
                    >
                      {activity.quantity < 0 ? "" : "+"}
                      {activity.quantity}
                    </TableCell>
                    <TableCell>{activity.transaction_id}</TableCell>
                    <TableCell>
                      {activity.transferred_to
                        ? activity.transferred_to
                        : "STAGED"}
                    </TableCell>
                    <TableCell>{activity.transferred_by}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
    </div>
  );
};

export default StagedTab;
