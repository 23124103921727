import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const AssetNotesModal = ({
  open,
  toggleOpen,
  handleUpdateNotes,
  assetNotes,
}) => {
  const [notes, setNotes] = useState(assetNotes);
  const handleChange = (e) => {
    setNotes(e.target.value);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={toggleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Status:
          </Typography>
          <TextField
            multiline
            fullWidth
            rows={10}
            value={notes}
            onChange={handleChange}
            label="Notes"
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "4rem",
              marginTop: "2rem",
            }}
          >
            <Button
              color="success"
              variant="contained"
              onClick={() => handleUpdateNotes(notes)}
            >
              Save
            </Button>
            <Button variant="contained" onClick={toggleOpen}>
              cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default AssetNotesModal;
