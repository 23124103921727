import React, { useState, createContext, useEffect } from "react";

const BEHMER_MATERIAL_GRABS = "BRM_PULL_REQUEST_CONTEXT";

export const PullRequestContext = createContext();

// {
// 	"inventory": [{
// 		"quantity": 1,
// 		"material_origin_key": "INVENTORY",
// 		"material_id": 7387,
// 		"item_description": "ADHESIVE  - ARIZONA PLASTIC CEMENT 121",
// 		"uom": "EA",
// 		"image_url": "https://inventory-tracking-app.s3.us-east-2.amazonaws.com/public/inventory/d5bc226e-d31c-4005-87e1-d056680452bb.jpg"
// 	}],
// 	"boneyard": [{
// 		"quantity": 1,
// 		"material_origin_key": "BONEYARD",
// 		"material_id": 51,
// 		"item_description": " EGL FIELD TILE WALNUT CREEK BLEND",
// 		"uom": "Pieces",
// 		"image_url": "https://inventory-tracking-app.s3.us-east-2.amazonaws.com/public/boneyard/a2cb2df2-5bcf-4d25-a759-9c695afa43d0.jpg"
// 	}],
// 	"committed": [],
// 	"staged": [{
// 		"quantity": 1,
// 		"material_origin_key": "STAGED",
// 		"material_id": 51,
// 		"item_description": " EGL FIELD TILE WALNUT CREEK BLEND",
// 		"uom": "Pieces",
// 		"image_url": "https://inventory-tracking-app.s3.us-east-2.amazonaws.com/public/boneyard/a2cb2df2-5bcf-4d25-a759-9c695afa43d0.jpg",
//    "staged_id": "INVENTORY"
//    "original_staged_transaction": "PULL-4"
//    "staged_source": "INVENTORY"
// 	}],
// 	"selectedJob": null,
// 	"delivery_location": "JOBSITE" || "YARD"
// }
const initialState = {
  inventory: [],
  boneyard: [],
  committed: [],
  staged: [],
  selectedJob: null,
  status: null,
  delivery_location: null,
  instructions: null,
  notes: "",
};

export const PullRequestProvider = (props) => {
  const [grabs, setGrabs] = useState(null);
  const [grabCount, setGrabCount] = useState(0);

  function toFixedIfNecessary(value, dp) {
    return +parseFloat(value).toFixed(dp);
  }

  useEffect(() => {
    getGrabsFromLocalStorage();
  }, []);

  useEffect(() => {
    // console.log("grabs", grabs);

    if (grabs) {
      let count = 0;
      // Count Inventory Items
      for (let index = 0; index < grabs.inventory.length; index++) {
        count += grabs.inventory[index].quantity;
      }
      // Count Boneyard Items
      for (let index = 0; index < grabs.boneyard.length; index++) {
        count += grabs.boneyard[index].quantity;
      }

      // Count Committed Items
      for (let index = 0; index < grabs.committed.length; index++) {
        count += grabs.committed[index].quantity;
      }
      // Count Staged Items
      for (let index = 0; index < grabs.staged.length; index++) {
        count += grabs.staged[index].quantity;
      }
      // console.log("Grab Count", count);
      setGrabCount(toFixedIfNecessary(count, 2));
    }
  }, [grabs]);

  const checkIfGrabStateIsUpdated = (grabState) => {
    for (const property in initialState) {
      if (grabState[property] === undefined) {
        return false;
      }
    }
    return true;
  };

  const getGrabsFromLocalStorage = () => {
    // check if auth exists in state
    // if not, check local storage for jwt and decode
    // decode
    // return
    try {
      const grabsString = localStorage.getItem(BEHMER_MATERIAL_GRABS);
      if (grabsString !== null) {
        // Check if state is an updated version
        let stateFromStorage = JSON.parse(grabsString);

        if (checkIfGrabStateIsUpdated(stateFromStorage)) {
          saveGrabs(stateFromStorage);
        }
        // if (checkIfGrabStateIsUpdated(stateFromStorage)) {
        //   saveGrabs(initialState);
        // }
        else {
          saveGrabs(initialState);
        }
      } else saveGrabs(initialState);
    } catch (error) {
      console.log("GrabsContext.getGrabsFromLocalStorage() ERROR", error);
      saveGrabs(initialState);
    }
  };

  const saveGrabs = (toSave) => {
    localStorage.setItem(BEHMER_MATERIAL_GRABS, JSON.stringify(toSave));
    setGrabs(toSave);
  };

  // Returns index of Inventory Grab with matching ID
  // Returns null if not found
  const findGrabIndex = (id, origin) => {
    let index;

    let grabList; // Either the inventory or boneyard list
    if (origin === "INVENTORY") {
      grabList = grabs.inventory;
    } else if (origin === "BONEYARD") {
      grabList = grabs.boneyard;
    } else if (origin === "COMMITTED") {
      grabList = grabs.committed;
    } else return null;

    for (index = 0; index < grabList.length; index++) {
      if (grabList[index].material_id === id) {
        console.log("Found at index", index);
        return index;
      }
    }
    console.log("Not Found");
    return null;
  };

  // Create a Grab Item depending on where it's from
  const createGrabItem = (item, origin, quantity) => {
    let grabItem = null;
    if (origin === "INVENTORY") {
      grabItem = {
        quantity: quantity,
        material_origin_key: origin,
        // material_id: item.inventoryId, //reference to: inventory_existence.id
        material_id: item.itemId, // material_id now corresponds with the lookup_inventory_master.id
        item_description: item.description,
        uom: item.uom,
        image_url: item.image_url,
      };
    } else if (origin === "BONEYARD") {
      grabItem = {
        quantity: quantity,
        material_origin_key: origin,
        material_id: item.id, //reference to boneyard_inventory.id (this remains unchanged)
        item_description: item.item,
        uom: item.uom_description,
        image_url: item.image_url,
      };
    } else if (origin === "COMMITTED") {
      grabItem = {
        quantity: quantity,
        material_origin_key: origin,
        material_id: item.id,
        item_description: item.item_description,
        uom: item.uom,
        image_url: item.image_url,
      };
    } else if (origin === "STAGED") {
      grabItem = {
        quantity: item.quantity,
        material_origin_key: origin,
        material_id: item.item_id, // material_id now corresponds with the lookup_inventory_master.id
        item_description: item.description,
        uom: item.uom,
        image_url: item.image_url,
        staged_id: item.staged_id,
        // original_staged_transaction: item.txn,
        reference_id: item.txn,
        staged_source: item.material_origin_key,
      };
    }

    return grabItem;
  };

  const grabFromInventory = (inventoryItem, qty) => {
    let quantity = 1; // default
    if (qty) {
      // If quantity is passed in, use it
      quantity = qty;
    }
    console.log("Grab Inventory Item");
    // Check inventory ID
    // Search Inventory Grabs by ID
    let existingInventoryGrabIndex = findGrabIndex(
      inventoryItem.itemId,
      "INVENTORY"
    );

    // If exists, increment quantity
    // If doesn't exist, push new item
    let invCopy = [...grabs.inventory];
    if (existingInventoryGrabIndex !== null) {
      invCopy[existingInventoryGrabIndex].quantity =
        invCopy[existingInventoryGrabIndex].quantity + quantity;

      saveGrabs({ ...grabs, inventory: invCopy });
    } else {
      let grabItem = createGrabItem(inventoryItem, "INVENTORY", quantity);
      invCopy.push(grabItem);

      saveGrabs({ ...grabs, inventory: invCopy });
    }
  };

  const updateInventoryGrabQuantity = (inventoryId, quantity) => {
    // Search Inventory Grabs by ID
    let existingInventoryGrabIndex = findGrabIndex(inventoryId, "INVENTORY");

    // If exists, update quantity
    let invCopy = [...grabs.inventory];
    if (existingInventoryGrabIndex !== null) {
      invCopy[existingInventoryGrabIndex].quantity = quantity;
      saveGrabs({ ...grabs, inventory: invCopy });
    }
  };

  const removeInventoryGrab = (inventoryId) => {
    // Search Inventory Grabs by ID
    let existingInventoryGrabIndex = findGrabIndex(inventoryId, "INVENTORY");

    // If exists, remove
    let invCopy = [...grabs.inventory];
    if (existingInventoryGrabIndex !== null) {
      invCopy.splice(existingInventoryGrabIndex, 1);
      saveGrabs({ ...grabs, inventory: invCopy });
    }
  };

  const grabFromBoneyard = (boneyardItem, qty) => {
    let quantity = 1; // default
    if (qty) {
      // If quantity is passed in, use it
      quantity = qty;
    }

    // Check Boneyard ID
    // Search Boneyard Grabs by ID
    let existingBoneyardGrabIndex = findGrabIndex(boneyardItem.id, "BONEYARD");

    // If exists, increment quantity
    // If doesn't exist, push new item
    let boneyardCopy = [...grabs.boneyard];
    if (existingBoneyardGrabIndex !== null) {
      boneyardCopy[existingBoneyardGrabIndex].quantity =
        boneyardCopy[existingBoneyardGrabIndex].quantity + quantity;

      saveGrabs({ ...grabs, boneyard: boneyardCopy });
    } else {
      let grabItem = createGrabItem(boneyardItem, "BONEYARD", quantity);
      boneyardCopy.push(grabItem);

      saveGrabs({ ...grabs, boneyard: boneyardCopy });
    }
  };

  const updateBoneyardGrabQuantity = (boneyardId, quantity) => {
    // Search Boneyard Grabs by ID
    let existingBoneyardGrabIndex = findGrabIndex(boneyardId, "BONEYARD");

    // If exists, update quantity
    let boneyardCopy = [...grabs.boneyard];
    if (existingBoneyardGrabIndex !== null) {
      boneyardCopy[existingBoneyardGrabIndex].quantity = quantity;
      saveGrabs({ ...grabs, boneyard: boneyardCopy });
    }
  };

  const removeBoneyardGrab = (boneyardId) => {
    // Search Boneyard Grabs by ID
    let existingBoneyardGrabIndex = findGrabIndex(boneyardId, "BONEYARD");

    // If exists, remove
    let boneyardCopy = [...grabs.boneyard];
    if (existingBoneyardGrabIndex !== null) {
      boneyardCopy.splice(existingBoneyardGrabIndex, 1);
      saveGrabs({ ...grabs, boneyard: boneyardCopy });
    }
  };

  const grabFromCommitted = (committedItem, qty) => {
    console.log("GRAB FOR COMMITTED", committedItem);
    let quantity = 1; // default
    if (qty) {
      // If quantity is passed in, use it
      quantity = qty;
    }

    // Check Committed ID
    // Search COMMITTED Grabs by ID
    let existingCommittedGrabIndex = findGrabIndex(
      committedItem.id,
      "COMMITTED"
    );

    // SET selected job
    let selectedJob = {
      id: committedItem.project_backlog_id,
      jobName: committedItem.job_name,
      projectId: committedItem.job_id,
    };

    // If exists, increment quantity
    // If doesn't exist, push new item
    let committedCopy = [...grabs.committed];
    if (existingCommittedGrabIndex !== null) {
      committedCopy[existingCommittedGrabIndex].quantity =
        committedCopy[existingCommittedGrabIndex].quantity + quantity;

      saveGrabs({ ...grabs, committed: committedCopy, selectedJob });
    } else {
      let grabItem = createGrabItem(committedItem, "COMMITTED", quantity);
      committedCopy.push(grabItem);

      saveGrabs({ ...grabs, committed: committedCopy, selectedJob });
    }
  };

  const updateCommittedGrabQuantity = (committedId, quantity) => {
    // Search COMMITTED Grabs by ID
    let existingCommittedGrabIndex = findGrabIndex(committedId, "COMMITTED");

    // If exists, update quantity
    let committedCopy = [...grabs.committed];
    if (existingCommittedGrabIndex !== null) {
      committedCopy[existingCommittedGrabIndex].quantity = quantity;
      saveGrabs({ ...grabs, committed: committedCopy });
    }
  };

  const removeCommittedGrab = (committedId) => {
    // Search COMMITTED Grabs by ID
    let existingCommittedGrabIndex = findGrabIndex(committedId, "COMMITTED");

    // If exists, remove
    let committedCopy = [...grabs.committed];
    if (existingCommittedGrabIndex !== null) {
      committedCopy.splice(existingCommittedGrabIndex, 1);

      // If committed is empty after removal, set the selected job to null
      if (committedCopy.length === 0)
        saveGrabs({ ...grabs, committed: committedCopy, selectedJob: null });
      else saveGrabs({ ...grabs, committed: committedCopy });
    }
  };

  const grabFromStaged = (stagedItems, id, jobName, projectId) => {
    let newStaged = [];
    for (const stagedItem of stagedItems) {
      let grabItem = createGrabItem(stagedItem, "STAGED");
      newStaged.push(grabItem);
    }

    // Set the following at the same time:
    // ADD STAGED ITEMS
    // SET SELECTED JOB
    // SET DELIVERY LOCATION
    saveGrabs({
      ...grabs,
      staged: newStaged,
      selectedJob: { id, jobName, projectId },
      deliveryLocation: "JOBSITE",
    });
  };

  const setDeliveryLocation = (deliveryLocation) => {
    saveGrabs({ ...grabs, delivery_location: deliveryLocation });
  };

  const setStatus = (status) => {
    saveGrabs({ ...grabs, status });
  };

  const setSelectedJob = (id, jobName, projectId) => {
    saveGrabs({
      ...grabs,
      selectedJob: {
        id,
        jobName,
        projectId,
      },
    });
  };

  const clearSelectedJob = () => {
    saveGrabs({
      ...grabs,
      selectedJob: null,
    });
  };

  const setInstructions = (instructions) => {
    saveGrabs({ ...grabs, instructions });
  };

  const setNotes = (notes) => {
    saveGrabs({ ...grabs, notes });
  };

  const clearGrabs = (keepSelectedJob) => {
    if (keepSelectedJob) {
      let selectedJob = grabs.selectedJob;
      saveGrabs({ ...initialState, selectedJob });
    } else {
      saveGrabs(initialState);
    }
  };

  return (
    <PullRequestContext.Provider
      value={{
        grabs,
        grabCount,
        grabFromInventory,
        updateInventoryGrabQuantity,
        removeInventoryGrab,
        grabFromBoneyard,
        updateBoneyardGrabQuantity,
        removeBoneyardGrab,
        grabFromCommitted,
        updateCommittedGrabQuantity,
        removeCommittedGrab,
        grabFromStaged,
        setStatus,
        setSelectedJob,
        clearSelectedJob,
        clearGrabs,
        setDeliveryLocation, //JOBSITE || YARD
        setInstructions,
        setNotes,
      }}
    >
      {props.children}
    </PullRequestContext.Provider>
  );
};
