const clickOnceWrapper = (fn) => {
  let clicked = false;
  return (...args) => {
    if (!clicked) {
      clicked = true;
      fn(...args);
    }
  };
};

export default clickOnceWrapper;
