import { useParams, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import api from "../../../../../api/InventoryTracking";
import { AuthContext } from "../../../../../context/AuthContext";
import { AssetContext } from "../../../../../context/AssetContext";
import AssetTransferModal from "./AssetTransferModal";
import AssetDetailView from "./AssetDetailView";
import useAssetManagers from "../../../../../customHooks/useAssetManagers";
import jwt_decode from "jwt-decode";
import moment from "moment";

const AssetDetailComponent = () => {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const decodedToken = jwt_decode(authToken);
  const { updateAssetAssignment, assetAssignment } = useContext(AssetContext);
  const { isAssetManager } = useAssetManagers();

  const { id, type } = useParams();
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(null);
  const [, setRefreshBecasueForSomeReasonTheAPIcallWasntDoingIt] = useState();

  const [asset, setAsset] = useState({});

  const [inValidBirthdate, setInValidBirthdate] = useState(false);
  const [birthdate, setBirthdate] = useState({ month: "", day: "", year: "" });
  const handleBirthdate = (key) => (e) => {
    setBirthdate((curr) => ({ ...curr, [key]: e.target.value }));
  };

  const [openTransferModal, setOpenTransferModal] = useState(false);
  const toggleTransferModal = () => setOpenTransferModal(!openTransferModal);

  const [acceptingUsersUsername, setAcceptingUsersUsername] = useState("");
  const handleUsernameChange = (e) => setAcceptingUsersUsername(e.target.value);

  const toolIsBeingTransfered = () => {
    const { asset, targetAssignee } = assetAssignment;
    if (asset.assetId != id || asset.assetType != type) {
      return false;
    }
    if (Object.values({ ...asset, ...targetAssignee }).includes(null)) {
      return false;
    }
    return true;
  };

  const getAssetDetails = async () => {
    try {
      setIsloading(true);
      const res = await api.get(`/assets/${type}/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("TOOl", res.data.asset);
      setAsset({ ...res.data.asset });
    } catch (error) {
      setError(error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (toolIsBeingTransfered()) {
      setOpenTransferModal(true);
    }
  }, [, assetAssignment]);

  // const verifyEmailThen =
  //   (fn) =>
  //   async (username, ...args) => {
  //     console.log("verifyEmailThen", username);
  //     const header = {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     };
  //     const result = await api.post("/verify-user", { username }, header);
  //     console.log(result);
  //     const { isValid } = result.data;
  //     console.log("is VALID", isValid);
  //     if (isValid) {
  //       fn(...args);
  //     } else {
  //       setInvalidUsername(true);
  //     }
  //   };
  const verifyBirthdayThen =
    (fn) =>
    async (bday, ...args) => {
      const isValid = moment(bday).isValid();

      if (isValid) {
        fn(...args);
      } else {
        setInValidBirthdate(false);
      }
    };

  const makeToolTransfer = async () => {
    try {
      if (toolIsBeingTransfered()) {
        const { asset, targetAssignee } = assetAssignment;
        const body = {
          assetId: asset.assetId,
          assetType: asset.assetType,
          assetName: asset.assetName,
          userId: targetAssignee.userId,
          userFullName: targetAssignee.userName,
        };
        const header = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };

        const res = await api.post(
          `/assets/${type}/${id}/transfer`,
          body,
          header
        );
        console.log(res);
        if (res.data.isTransfered === true) {
          setOpenTransferModal(false);
          updateAssetAssignment({ type: "CLEAR-STATE" });
          getAssetDetails();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const makeToolTransferRequest = async (
    sendToNAME,
    sendToID,
    actionType,
    assetType,
    assetId,
    assetName
  ) => {
    try {
      setIsloading(true);
      const actionTypeOptions = ["ASSIGN", "UN-ASSIGN"];
      if (!actionTypeOptions.includes(actionType)) {
        throw "Invalid Action Type";
      }

      const body = {
        assetType: assetType,
        assetId: assetId,
        assetName: assetName,
        senderId: decodedToken.id,
        senderName: decodedToken.fullName, // sender is always the person logged in
        targetId: sendToID,
        targetName: sendToNAME,
        actionType: actionType,
      };
      const header = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const res = await api.post(`/assets/${type}/${id}/request`, body, header);
      if (res.data.isRequested === true) {
        setOpenTransferModal(false);
        updateAssetAssignment({ type: "CLEAR-STATE" });
        getAssetDetails();
      }
      console.log("makeToolTransferRequest ", res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };
  const returnAsset = async () => {
    try {
      if (isAssetManager) {
        const header = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        const res = await api.put(`/assets/${type}/${id}/return`, {}, header);
        console.log("returnAsset ", res);
        if (res.data.isReturned === true) {
          updateAssetAssignment({ type: "CLEAR-STATE" });
          getAssetDetails();
        }
      } else {
        console.log("no authority, make request instead");
        makeToolTransferRequest(null, null, "UN-ASSIGN", type, id, asset.name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAcceptOrReject = (type) => (birthdate) => {
    switch (type) {
      case "accept":
        console.log(type);
        console.log(birthdate);
        console.log(acceptingUsersUsername);
        verifyBirthdayThen(makeToolTransfer)(birthdate);
        break;
      case "reject":
        console.log(type);
        console.log(acceptingUsersUsername);
        updateAssetAssignment({ type: "CLEAR-STATE" });
        setOpenTransferModal(false);

        break;
      case "send":
        console.log(type);
        const target = assetAssignment.targetAssignee;
        const asset = assetAssignment.asset;
        makeToolTransferRequest(
          target.userName,
          target.userId,
          "ASSIGN",
          asset.assetType,
          asset.assetId,
          asset.assetName
        );
        break;
      default:
        break;
    }
    getAssetDetails();
    setRefreshBecasueForSomeReasonTheAPIcallWasntDoingIt();
  };

  const handleRequest = () => {
    makeToolTransferRequest(
      decodedToken.fullName,
      decodedToken.id,
      "ASSIGN",
      type,
      id,
      asset.name
    );
  };

  const softDeleteAsset = async (assetStatus) => {
    try {
      const statusOptions = ["SOLD", "OUTOFCOMMITION", "LOSTSTOLEN", "DELETED"];
      if (!statusOptions.includes(assetStatus)) {
        throw "Invlaid Asset Status Type";
      } else {
        const res = await api.put(
          `/assets/${type}/${id}/delete`,

          { reason: assetStatus },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        console.log(res);
        if (res.data.deleted) {
          navigate(`/assets/${type}`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateAssetNotes = async (notes) => {
    try {
      console.log("Hello World");
      const res = await api.put(
        `/assets/${type}/${id}/notes`,
        { notes: notes },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(res);
      getAssetDetails();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAssetDetails();
  }, []);

  return (
    <>
      <AssetTransferModal
        open={openTransferModal}
        toggleOpen={toggleTransferModal}
        handleAcceptOrReject={handleAcceptOrReject}
        // username={acceptingUsersUsername}
        // handleUsernameChange={handleUsernameChange}
        birthdate={birthdate}
        handleBirthdate={handleBirthdate}
        asset={assetAssignment.asset}
        assignee={assetAssignment.targetAssignee}
        // inValidUsername={inValidUsername}
        inValidBirthdate={inValidBirthdate}
      />
      <AssetDetailView
        data={asset}
        isAssetManager={isAssetManager}
        isInPossession={asset.user_id_in_possession == decodedToken.id}
        handleReturn={returnAsset}
        handleRequest={handleRequest}
        handleDelete={softDeleteAsset}
        updateAssetNotes={updateAssetNotes}
        toggleTransferModal={toggleTransferModal}
        isLoading={isLoading}
        error={error}
      />
    </>
  );
};
export default AssetDetailComponent;
