import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  IconButton,
  CardActionArea,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect, useContext } from "react";
import api from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const ToolsInUsersPossession = () => {
  const { authToken, role } = useContext(AuthContext);
  const navigate = useNavigate();
  const [tools, setTools] = useState();
  const [requested, setRequested] = useState(false);

  const getToolsCurrentlyAssignedToUser = async () => {
    try {
      const response = await api.get(`/tools/possession`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response);
      setTools(response.data.tools);
    } catch (error) {
      console.log(error);
    }
  };

  const returnTool = async (toolId) => {
    try {
      const response = await api.post(
        `/tools/${toolId}/return`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 201) {
        setRequested(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getToolsCurrentlyAssignedToUser();
  }, []);

  if (!tools) return <div>Loading...</div>;
  return (
    <div style={{ marginTop: 70 }}>
      <Button variant="contained" onClick={() => navigate("/tools")}>
        Back To Tools
      </Button>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {tools.map((tool, i) => (
          <Card
            key={i}
            sx={{ display: "flex", flexDirection: "row", width: "350px" }}
          >
            <CardContent sx={{ flex: "3" }}>
              <Typography>{tool.asset_name}</Typography>
            </CardContent>
            <CardActionArea sx={{ flex: "1" }}>
              <Button
                variant="contained"
                disabled={requested}
                onClick={() => returnTool(tool.asset_id)}
              >
                Return {requested ? "Requested" : null}
              </Button>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </div>
  );
};

export default ToolsInUsersPossession;
