import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import api from "../../../../../api/InventoryTracking";
import jwtDecode from "jwt-decode";
import AssignedAssetsView from "./AssignedAssetsView";

const AssignedAssetsComponent = () => {
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(null);
  const { authToken } = useContext(AuthContext);
  const decodedToken = jwtDecode(authToken);
  const [assets, setAssets] = useState([]);

  const getUsersAssets = async () => {
    try {
      const header = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const res = await api.get(
        `/assets/user/${decodedToken.id}/assigned`,
        header
      );
      console.log(res.data);
      setAssets([...res.data.assets]);
    } catch (error) {
      console.log(error);
    }
  };

  const makeReturnRequest = async (assetType, assetId, assetName) => {
    try {
      const body = {
        assetType: assetType,
        assetId: assetId,
        assetName: assetName,
        senderId: decodedToken.id,
        senderName: decodedToken.fullName, // sender is always the person logged in
        targetId: null,
        targetName: null,
        actionType: "UN-ASSIGN",
      };
      const header = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const res = await api.post(
        `/assets/${assetType}/${assetId}/request`,
        body,
        header
      );
      console.log("makeReturnRequest ", res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsersAssets();
  }, []);

  return (
    <AssignedAssetsView
      data={assets}
      handleReturn={makeReturnRequest}
      isLoading={isLoading}
      error={error}
    />
  );
};
export default AssignedAssetsComponent;
