import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { GrabsContext } from "../../../context/GrabsContext";
import PageContent from "../../../component/PageContent";
import PendingDailyStockouts from "../Pulls/PendingStockouts";
import PendingPullRequests from "../Pulls/PendingPullRequests";
import escapeCharacters from "../../../helpers/escapeCharacters";
import { useTheme } from "@emotion/react";

const NeedToPost = () => {
  const navigate = useNavigate();
  const { section } = useParams();
  const { authToken } = useContext(AuthContext);
  const [stockouts, setStockouts] = useState([]);
  const [searchStockout, setSearchStockout] = useState("");

  const [pullRequests, setPullrequests] = useState([]);
  const [searchPullRequest, setSearchPullRequest] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const defaultTab = (sectionParam) => {
    const tabParamPairings = [
      { param: "stock-outs", tab: "STOCK OUTS" },
      { param: "pull-requests", tab: "PULL REQUESTS" },
    ];
    const validParam = tabParamPairings.find(
      (tab) => tab.param === sectionParam
    );
    return validParam ? validParam.tab : "STOCK OUTS";
  };
  const [activeTab, setActiveTab] = useState(defaultTab(section));

  const getPendingStockouts = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await InventoryTracking.get(
        `/stockouts?search=${escapeCharacters(searchStockout)}&status=pending`,
        data
      );
      console.log(response.data);
      setStockouts(response.data.stockouts);
    } catch (error) {
      if (!error.code === "ERR_CANCELED") {
        // If it's not an abort controller message
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    getPendingStockouts(controller);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [searchStockout]);

  const getPendingPullRequests = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await InventoryTracking.get(
        `/pull-requests?search=${escapeCharacters(
          searchPullRequest
        )}&status=pending`,
        data
      );
      console.log(response.data);
      setPullrequests(response.data.pullRequests);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    getPendingPullRequests(controller);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [searchPullRequest]);

  return (
    <PageContent>
      <div
        style={{
          position: "relative",
          flexDirection: "row",
          display: useMediaQuery(theme.breakpoints.down("sm"))
            ? "column"
            : "row",
          marginTop: 16,
        }}
      >
        <h2
          style={{
            textAlign: "center",
          }}
        >
          Need To Post
        </h2>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginLeft: 0,
            marginRight: 0,
            marginBottom: "16px",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            // textColor="#fff"
            // indicatorColor="#fff"
            value={activeTab}
            onChange={(event, newValue) => {
              setActiveTab(newValue);
            }}
          >
            <Tab label="Stock Outs" value="STOCK OUTS" />
            <Tab label="Pull Requests" value="PULL REQUESTS" />
          </Tabs>
        </Box>

        <div hidden={activeTab !== "STOCK OUTS"}>
          <PendingDailyStockouts
            stockouts={stockouts}
            search={searchStockout}
            setSearch={setSearchStockout}
          />
        </div>
        <div hidden={activeTab !== "PULL REQUESTS"}>
          <PendingPullRequests
            pullRequests={pullRequests}
            search={searchStockout}
            setSearch={setSearchStockout}
          />
        </div>
      </div>
    </PageContent>
  );
};

export default NeedToPost;
