import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Checkbox,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import toFixedIfNecessary from "../../../../../helpers/toFixedIfNecessary";
import serverTimeConverter from "../../../../../helpers/serverTimeConverter";
import SelectVehicle from "./SelectVehicle";
import { useTheme } from "@emotion/react";

const LoadedTrucks = ({
  isLoading,
  trucks,
  setTrucks,
  deliverItems,
  loadItemsOnTruck,
  previewModalOpen,
  setPreviewModalOpen,
  selectVehicleModalOpen,
  setSelectVehicleModalOpen,
  onTruckClick,
}) => {
  const theme = useTheme();
  const [] = useState(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (!trucks) return null;

  console.log("TRUCKS", trucks);

  return (
    <div>
      <div>
        <div>
          {trucks &&
            trucks.map((truck, truckIndex) => (
              <Card
                sx={{
                  marginTop: "16px",
                  padding: "16px",
                }}
              >
                <div key={truckIndex}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <h3
                      style={{
                        flex: 1,
                        cursor: onTruckClick ? "pointer" : null,
                        textDecoration: onTruckClick ? "underline" : null,
                      }}
                      // onClick={() => {
                      //   onTruckClick(truck.vehicle_id, truck);
                      // }}
                    >
                      {truck.vehicle_nick_name}
                    </h3>
                    <Button
                      size="small"
                      style={{
                        height: 50,
                        marginTop: 16,
                      }}
                      startIcon={<LocalShippingIcon />}
                      variant="outlined"
                      onClick={() => {
                        onTruckClick(truck.vehicle_id, truck);
                      }}
                    >
                      Actions
                    </Button>
                  </div>
                  <TableContainer component={Box}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell>From</TableCell>
                          <TableCell>UOM</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Transaction ID</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {truck &&
                          truck.items &&
                          truck.items.map((item, index) => (
                            <TableRow
                              // onClick={() =>
                              //   //navigate(`/daily-stockouts/${grab.id}`)
                              // }
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{item.description}</TableCell>
                              {/* <TableCell component="th" scope="row">
                        {serverTimeConverter
                          .convertServerCreatedOnTimeStampToPhoenix(
                            activity.date
                          )

                          .format("MM/DD/YY hh:mm A z")}
                      </TableCell> */}
                              <TableCell>{item.material_origin_key}</TableCell>
                              <TableCell>{item.uom}</TableCell>
                              <TableCell>
                                {item.transaction_total_quantity}
                              </TableCell>
                              <TableCell>{item.transaction_id}</TableCell>
                              {/* <TableCell>
                                <Link
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate(
                                      `/jobs/${item.project_backlog_id}/loaded`
                                    );
                                  }}
                                >
                                  {item.job_id}: {item.job_name}
                                </Link>
                              </TableCell> */}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Card>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LoadedTrucks;
