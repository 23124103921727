import { Box } from "@mui/material";

const makePage = (Component) => (props) => {
  return (
    <Box sx={{ marginTop: "3em" }}>
      <Component {...props} />
    </Box>
  );
};
export default makePage;
