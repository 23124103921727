import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Close, Edit } from "@mui/icons-material";
import toFixedIfNecessary from "../helpers/toFixedIfNecessary";
import { useDebounce } from "use-debounce";
import { useTheme } from "@emotion/react";

const PulledItemCard = ({
  itemName,
  source,
  internalCode,
  uom,
  qtyReq,
  qtyLabel,
  qtyPulled,
  onQtyChange,
  onRemove,
  disableEdit = false,
  disableDelete = false,
  enableEditRequestedQty,
  showRequested,
  onReqQtyChange,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [quantityPulled, setQuantityPulled] = useState(qtyPulled);
  const [qtyPulledValue] = useDebounce(quantityPulled, 500);
  const [openEdit, setOpenEdit] = useState(false);
  const [requiredQuantity, setRequiredQuantity] = useState(qtyReq);

  useEffect(() => {
    console.log("qtPulled Changed", qtyPulled);
    setQuantityPulled(qtyPulled);
  }, [qtyPulled]);

  useEffect(() => {
    if (qtyPulledValue && qtyPulledValue != qtyPulled) {
      console.log("Update Quantity");
      onQtyChange(qtyPulledValue);
    }
  }, [qtyPulledValue]);

  return (
    <>
      {enableEditRequestedQty && (
        <Dialog
          open={openEdit}
          onClose={() => {
            setOpenEdit(false);
          }}
        >
          <DialogTitle>{itemName}</DialogTitle>
          <DialogContent>
            {qtyReq && (
              <DialogContentText>
                Current Quantity Requested: {qtyReq}
              </DialogContentText>
            )}

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Quantity Requested"
              fullWidth
              variant="filled"
              type="number"
              value={`${requiredQuantity}`}
              onChange={(e) => {
                let val;
                if (
                  e.target.value == "" ||
                  e.target.value === undefined ||
                  e.target.value === null ||
                  e.target.value === "0" ||
                  e.target.value === "00"
                ) {
                  val = 0;
                } else {
                  val = e.target.value.replace(/^0+/, "");
                  val = toFixedIfNecessary(val, 2);
                }
                // Do Not Allow Negative
                if (val < 0) {
                  val = 0;
                }
                setRequiredQuantity(val);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenEdit(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onReqQtyChange(requiredQuantity);
                setOpenEdit(false);
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Box
        sx={{
          margin: "30px 0 0 0",
          borderTop: "1px solid black",
          paddingTop: "10px",
        }}
      >
        <Box
          sx={{
            display: isSmallScreen ? "block" : "flex",
            flexDirection: "row",
            // justifyContent: "space-evenly",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: "600" }}>
              {itemName?.toUpperCase()}
            </Typography>
            <Typography variant="caption">
              {source?.toUpperCase()}
              {internalCode && internalCode != "None"
                ? ` - ${internalCode?.toUpperCase()}`
                : ""}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 2,
              display: "flex",
              flexDirection: "row",
              marginTop: isSmallScreen ? "8px" : 0,
              justifyContent: isSmallScreen ? "flex-end" : "center",
            }}
          >
            {showRequested && (
              <Box
                sx={{
                  flex: 1,
                  // flex: isSmallScreen ? "none" : 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginRight: isSmallScreen ? "16px" : 0,
                }}
              >
                <Typography
                  textAlign="center"
                  variant="caption"
                  fontWeight="600"
                >
                  REQUESTED
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography textAlign="center">
                    {qtyReq ? qtyReq : 0} {uom}
                  </Typography>
                  {enableEditRequestedQty && !disableEdit && (
                    <IconButton onClick={() => setOpenEdit(true)}>
                      <Edit />
                    </IconButton>
                  )}
                </div>
              </Box>
            )}
            <Box
              sx={{
                flex: 1,
                // flex: isSmallScreen ? "none" : 1,
                marginRight: isSmallScreen ? "16px" : 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography textAlign="center" variant="caption" fontWeight="600">
                {qtyLabel ? qtyLabel : "QTY PULLED"}
              </Typography>
              {disableEdit ? (
                <Typography textAlign="center">{quantityPulled}</Typography>
              ) : (
                <TextField
                  disabled={disableEdit}
                  size="small"
                  type="number"
                  value={`${quantityPulled}`}
                  onChange={(e) => {
                    setQuantityPulled(e.target.value);
                  }}
                  sx={{ maxWidth: 100 }}
                />
              )}
            </Box>
            {!disableDelete && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Close onClick={onRemove} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default PulledItemCard;
