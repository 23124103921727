import {
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  IconButton,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import CustomQrCodeScanner from "./CustomQrCodeScanner";
import { useState, useRef } from "react";
import ListItem from "./ListItem";

const CategorySearch = ({
  categoryOptions = [],
  categoryValue = "",
  searchValue = "",
  results,
  handleResultClick,
  handleSearch,
  handleSelect,
  handleClick,
  onQrCodeScan,
}) => {
  // const parseResultsObjects = resArr => {
  //   const hasDescription = obj => Object.hasOwn(obj, "description")
  //   resArr.map(res => hasDescription(res) ? res : )
  // }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: "0 0 0 0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // height: "35px",
            flex: 9,
            // border: `1px solid`,
            // borderColor: theme.palette.primary.main,
            borderRadius: "5px",
          }}
        >
          {categoryOptions.length > 0 && (
            <FormControl
              variant="filled"
              size="small"
              sx={{
                m: "1 0",
                minWidth: 115,
                border: 0,
                boxShadow: "none",
              }}
            >
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                autoWidth
                value={categoryValue}
                onChange={handleSelect}
                sx={{
                  height: "100%",
                  // backgroundColor: "#D9D9D9",
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                {categoryOptions.map((cat, i) => (
                  <MenuItem key={i} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl
            variant="outlined"
            sx={{ width: "100%", height: "100%" }}
          >
            <OutlinedInput
              disabled={categoryOptions.length < 1 || !categoryValue}
              placeholder="Search..."
              sx={{
                marginLeft: "8px",
                // boxShadow: "none",
                // ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
              inputProps={{
                style: {
                  height: "100%",
                },
              }}
              endAdornment={<SearchIcon />}
              value={searchValue}
              onChange={handleSearch}
            />
          </FormControl>
        </div>
        <div
          style={{
            marginLeft: "5px",
          }}
        >
          <CustomQrCodeScanner onResult={onQrCodeScan} />
        </div>
      </div>
      {results.length > 0 && (
        <div
          style={{
            // border: "1px solid black",
            maxHeight: "200px",
            margin: "0 10px 0 10px",
            overflowY: "scroll",
            borderRadius: "5px",
          }}
        >
          {results.map((res, i) => (
            <ListItem
              title={`${
                res.description ||
                res.item_description ||
                res.item ||
                res.job_name
              } ${res.internal_code ? ` (${res.internal_code})` : ""}`}
              onClick={() => {
                console.log(res);
                handleResultClick(res);
              }}
            />
          ))}
        </div>
      )}
    </>
  );
};
export default CategorySearch;
/* 
Inventory: description
Committed: item_description
Boneyard: item
*/
