import React, { useState, createContext, useEffect } from "react";

export const SnackAlertContext = createContext();

export const SnackAlertProvider = (props) => {
  const [openSnackAlert, setOpenSnackAlert] = React.useState(false);
  const [snackAlertSeverity, setSnackAlertSeverity] = React.useState("");
  const [snackAlertMessage, setSnackAlertMessage] = useState("");

  const openSnackMessage = (severity, message) => {
    setSnackAlertMessage(message);
    setSnackAlertSeverity(severity);
    setOpenSnackAlert(true);
  };

  return (
    <SnackAlertContext.Provider
      value={{
        openSnackMessage,
        setOpenSnackAlert,
        openSnackAlert,
        snackAlertSeverity,
        snackAlertMessage,
      }}
    >
      {props.children}
    </SnackAlertContext.Provider>
  );
};
