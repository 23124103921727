import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  TextareaAutosize,
  TextField,
  useMediaQuery,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate, useParams } from "react-router-dom";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { TopNavContext } from "../../../context/TopNavContext";
import PageContent from "../../../component/PageContent";
import serverTimeConverter from "../../../helpers/serverTimeConverter";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import toFixedIfNecessary from "../../../helpers/toFixedIfNecessary";
import { DarkLightModeContext } from "../../../context/DarkLightModeContext";
import ReturnItemDetailsCard from "./ReturnItemDetailsCard";
import { Add } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { SnackAlertContext } from "../../../context/SnackAlertContext";

const ReturnDetails = () => {
  const { openSnackMessage } = useContext(SnackAlertContext);

  const { isDarkMode } = useContext(DarkLightModeContext);
  const { id } = useParams();
  const { authToken, isAdmin } = useContext(AuthContext);
  const { setPageName } = useContext(TopNavContext);
  const [details, setDetails] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  let returnIsPostable = details && !details.posted_on && !details.rejected_on;

  const getReturnDetails = async () => {
    try {
      setLoading(true);
      const response = await InventoryTracking.get(`/returns/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setDetails(response.data.return);
      setPageName(`${response.data.return.job_name} - Return Details`);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const update = async () => {
    try {
      setLoading(true);
      const response = await InventoryTracking.put(`/returns/${id}`, details, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("UPDATED", response.data.return);
      setDetails(response.data.return);
      openSnackMessage("success", "Updated");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const udpatedAndReject = async () => {
    try {
      setLoading(true);
      const response = await InventoryTracking.put(
        `/returns/${id}/reject`,
        details,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Posted", response.data.return);
      setDetails(response.data.return);
      openSnackMessage("success", "Rejected");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const updateAndPost = async () => {
    try {
      setLoading(true);
      const response = await InventoryTracking.put(
        `/returns/${id}/post`,
        details,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Posted", response.data.return);
      setDetails(response.data.return);
      openSnackMessage("success", "Posted");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  // const updateImage = async (imageBlob) => {
  //   try {
  //     let formData = new FormData();
  //     formData.append("id", id);
  //     if (imageBlob) formData.append("image", imageBlob);
  //     else formData.append("image", null);

  //     console.log(formData);
  //     const response = await InventoryTracking.post(`/returns`, formData, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //         "content-type": "multipart/form-data",
  //       },
  //     });
  //     console.log("Image Update response", response.data);
  //     setDetails(response.data.material);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const updateNotes = async () => {
  //   try {
  //     let data = {
  //       id,
  //       notes,
  //     };
  //     const response = await InventoryTracking.post(`/returns`, data, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });
  //     setDetails(response.data.material);
  //     setNotes(response.data.material.notes);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    getReturnDetails();
  }, []);

  useEffect(() => {
    console.log("Return State", details);
  }, [details]);

  return (
    <PageContent>
      <h2 style={{ textAlign: "center" }}>Return Details</h2>
      {details ? (
        <div>
          <h3>
            {details.transactionid} - {details.job_name}
          </h3>
          <div style={{}}>
            Returned On:{" "}
            {serverTimeConverter
              .convertServerCreatedOnTimeStampToPhoenix(details.created_on)
              .format("MM/DD/YY h:mm a")}
          </div>
          <div style={{}}>Returned By: {details.created_by}</div>
          {details.posted_on && (
            <>
              <div style={{}}>
                Posted On:{" "}
                {serverTimeConverter
                  .convertServerCreatedOnTimeStampToPhoenix(details.posted_on)
                  .format("MM/DD/YY h:mm a")}
              </div>
              <div style={{}}>Posted By: {details.posted_by}</div>
            </>
          )}
          {details.rejected_on && (
            <>
              <div style={{}}>
                Rejected On:{" "}
                {serverTimeConverter
                  .convertServerCreatedOnTimeStampToPhoenix(details.rejected_on)
                  .format("MM/DD/YY h:mm a")}
              </div>
              <div style={{}}>Rejected By: {details.rejected_by}</div>
            </>
          )}

          <div style={{}}>Notes:</div>
          <TextareaAutosize
            disabled={!returnIsPostable}
            minRows={3}
            aria-label="empty textarea"
            placeholder="Empty..."
            style={{
              width: "100%",
              background: isDarkMode
                ? "#232323"
                : theme.palette.primary.extraDark,
              color: theme.palette.secondary.contrastText,
              borderColor: theme.palette.primary.main,
            }}
            value={details.notes}
            onChange={(e) => {
              setDetails((prev) => ({ ...prev, notes: e.target.value }));
            }}
          />

          <Box
            sx={{
              flexGrow: 1,
              marginTop: "10px",
            }}
          >
            <Grid2
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{ marginLeft: "auto" }}
            >
              {details?.img_urls?.map((img, i) => (
                <Grid2
                  xs={2}
                  sm={4}
                  md={4}
                  key={i}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={img.image_url}
                    alt=""
                    style={{
                      maxHeight: "150px",
                      maxWidth: "150px",
                    }}
                  />
                </Grid2>
              ))}
            </Grid2>
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              sx={{
                width: "100%",
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "32px",
              }}
            >
              Items
            </Typography>
            <div style={{ width: "100%" }}>
              {details.items &&
                details.items.map((item, index) => {
                  if (isAdmin) {
                    return (
                      <ReturnItemDetailsCard
                        isEditable={returnIsPostable}
                        key={`${index}${item.id}${item.randomKey}`}
                        jobId={details.job_id}
                        description={item.item_text}
                        setDescription={(description) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].item_text = description;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        quantity={item.quantity}
                        setQuantity={(quantity) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].quantity = quantity;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        returnFrom={item.return_from}
                        setReturnFrom={(from) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].return_from = from;
                          itemsCopy[index].return_from_id = null; // Reset Return From ID
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        returnFromId={item.return_from_id}
                        setReturnFromId={(fromId) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].return_from_id = fromId;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        returnTo={item.return_to}
                        setReturnTo={(destination) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].return_to = destination;
                          itemsCopy[index].item_id = null; // Reset inventory item_id
                          itemsCopy[index].provider_id = null; // Reset provider_id
                          itemsCopy[index].uom = null; // Reset uom id
                          itemsCopy[index].price = null; // Reset uom id
                          setDetails((prev) => ({
                            ...prev,

                            items: itemsCopy,
                          }));
                        }}
                        inventoryItemId={item.item_id}
                        setInventoryItemId={(itemId) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].item_id = itemId;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        vendorId={item.provider_id}
                        setVendorId={(vendorId) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].provider_id = vendorId;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        uom={item.uom}
                        setUom={(uomId) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].uom = uomId;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        price={item.price}
                        setPrice={(price) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].price = price;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        onRemove={() => {
                          let itemsCopy = [...details.items];
                          itemsCopy.splice(index, 1);
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                      />
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        style={{
                          marginTop: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <TextField
                          sx={{ flex: 1 }}
                          name="Description"
                          label="Description"
                          type="text"
                          value={item.item_text}
                        />
                        <TextField
                          sx={{ width: "80px", marginLeft: "8px" }}
                          name="Quantity"
                          label="Quantity"
                          value={item.quantity}
                        />
                      </div>
                    );
                  }
                })}
            </div>
            {returnIsPostable && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{ marginTop: "15px", width: 150 }}
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => {
                    let itemsCopy = [...details.items];
                    itemsCopy.push({
                      randomKey: uuidv4(), // To prevent ui bug of lingering inputs when removing an item (data on index x stays on index x)
                    });
                    setDetails((prev) => ({
                      ...prev,
                      items: itemsCopy,
                    }));
                  }}
                >
                  Add Item
                </Button>
              </div>
            )}
          </Box>
          {returnIsPostable && (
            <Box
              sx={{
                paddingTop: "16px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                loading={loading}
                sx={{ marginRight: "8px" }}
                variant="outlined"
                onClick={udpatedAndReject}
              >
                Reject
              </LoadingButton>
              <LoadingButton
                loading={loading}
                sx={{ marginRight: "8px" }}
                variant="outlined"
                onClick={update}
              >
                Save
              </LoadingButton>
              <LoadingButton
                loading={loading}
                variant="outlined"
                onClick={updateAndPost}
              >
                Post
              </LoadingButton>
            </Box>
          )}
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          <div>Loading...</div>
        </div>
      )}
    </PageContent>
  );
};

export default ReturnDetails;
