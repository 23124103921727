import axios from "axios";
import api from "../../../../../api/InventoryTracking";
import { AuthContext } from "../../../../../context/AuthContext";
import escapeCharacters from "../../../../../helpers/escapeCharacters";
import { useContext, useState, useEffect } from "react";
import EmployeeList from "./view";
import AssignedAssetModal from "./AssignedAssetsModal";
import useInfiniteScroll from "../../../../../customHooks/useInfiniteScroll";
import useAssetManagers from "../../../../../customHooks/useAssetManagers";

const Employees = () => {
  const { userBranch, authToken } = useContext(AuthContext);
  const { isAssetManager } = useAssetManagers();
  const header = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const [isLoading, setIsloading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null);

  const [search, setSearch] = useState("");
  const [branch, setBranch] = useState(userBranch);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const [modalOpen, setModalOpen] = useState(false);
  const toggleOpen = () => setModalOpen(!modalOpen);

  const infiniteScrollRef = useInfiniteScroll(
    () => setPage((curr) => curr + 1),
    [isLoading, page < pages],
    page < pages,
    isLoading
  );

  const [usersAssets, setUsersAssets] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const selectedUser = employees.find(
    (employee) => employee.id == selectedUserId
  );

  const getAssetsBelongingToUser = (userId) => {
    if (isAssetManager) {
      api
        .get(`/assets/user/${userId}/assigned`, header)
        .then((res) => {
          setUsersAssets(res.data.assets);
          console.log(res);
        })
        .then(setModalOpen(true))
        .catch((err) => setError(err));
    }
  };

  const handleClick = (employee) => {
    getAssetsBelongingToUser(employee.id);
    setSelectedUserId(employee.id);
  };

  useEffect(() => {
    setIsloading(true);
    const controller = new AbortController();
    api
      .get(
        `/employees?search=${escapeCharacters(
          search
        )}&location=${branch}&pageSize=25&page=${page}`,
        {
          signal: controller.signal,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        console.log("EMPLOYEES: ", res);
        setPages(res.data.pages);
        if (page > 1) {
          setEmployees((curr) => [...curr, ...res.data.employees]);
        } else {
          setEmployees([...res.data.employees]);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("request canceled");
        } else {
          setError(err);
          console.log("ERROR: ", err);
        }
      })
      .finally(() => setIsloading(false));
    return () => controller.abort();
  }, [, page, branch, search]);

  return (
    <>
      <EmployeeList
        data={employees}
        infiniteScrollRef={infiniteScrollRef}
        handleClick={handleClick}
        isLoading={isLoading}
        error={error}
        branchOptions={["SDL", "FLG"]}
        branch={branch}
        setBranch={setBranch}
        search={search}
        setSearch={setSearch}
      />
      <AssignedAssetModal
        assets={usersAssets}
        open={modalOpen}
        handleClose={toggleOpen}
        user={selectedUser}
      />
    </>
  );
};
export default Employees;
