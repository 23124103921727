import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Return from "../pages/admin/Return/Return";
import { useNavigate } from "react-router-dom";

export default function InventoryTabs({ pageName }) {
  const navigate = useNavigate();

  const defaultActive = (tabName) => {
    switch (tabName.toUpperCase()) {
      case "INVENTORY":
        return 0;
      case "BONEYARD":
        return 1;
      case "COMMITTED":
        return 2;
      default:
        return 0;
    }
  };

  const [value, setValue] = React.useState(defaultActive(pageName));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/inventory");
        break;
      case 1:
        navigate("/boneyard");
        break;
      case 2:
        navigate("/committed");
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons={false}
        >
          <Tab label="Inventory" />
          <Tab label="Boneyard" />
          <Tab label="Committed" />
        </Tabs>
      </Box>
    </Box>
  );
}
