import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";

const AssetTransferModal = ({
  open,
  toggleOpen,
  username,
  handleUsernameChange,
  handleAcceptOrReject,
  asset,
  assignee,
  inValidUsername,
  inValidBirthdate,
  birthdate,
  handleBirthdate,
}) => {
  const [getUserApproval, setGetUserApproval] = useState(false);

  const accept = handleAcceptOrReject("accept");
  const reject = handleAcceptOrReject("reject");
  const send = handleAcceptOrReject("send");

  const makeDate = (bday) => `${bday.month}/${bday.day}/${bday.year}`;
  return (
    <>
      <Modal open={open} onClose={toggleOpen}>
        <Box sx={styles.modalContent}>
          <Typography variant="overline">{`Assign ${asset.assetName} to`}</Typography>
          <Typography variant="overline" sx={{ fontWeight: "600" }}>
            {assignee.userName}
          </Typography>
          <Box>
            {!getUserApproval ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography textAlign={"center"}>
                  Have employee accept now, or send request to their phone?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    mt: "2rem",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setGetUserApproval(true)}
                  >
                    Now
                  </Button>
                  <Button variant="contained" onClick={send}>
                    Send
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ mt: 2 }}>
                  {assignee?.userName?.split(" ")[0]}, Enter Your{" "}
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    BIRTHDATE
                  </Typography>{" "}
                  To Accept:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: ".5rem",
                    width: "75%",
                  }}
                >
                  <TextField
                    type="number"
                    size="small"
                    label="month"
                    onChange={handleBirthdate("month")}
                    value={birthdate.month}
                    error={inValidBirthdate}
                  />
                  <TextField
                    type="number"
                    size="small"
                    label="day"
                    onChange={handleBirthdate("day")}
                    value={birthdate.day}
                    error={inValidBirthdate}
                  />
                  <TextField
                    type="number"
                    size="small"
                    label="year"
                    onChange={handleBirthdate("year")}
                    value={birthdate.year}
                    error={inValidBirthdate}
                  />
                </Box>

                {/* <TextField
                  variant="outlined"
                  size="small"
                  sx={{ mt: "1em" }}
                  value={username}
                  onChange={handleUsernameChange}
                  error={inValidUsername}
                /> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1em",
                    mt: "1em",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (birthdate) {
                        const bday = makeDate(birthdate);
                        accept(bday);
                      }
                    }}
                  >
                    Accept?
                  </Button>
                  <Button variant="outlined" onClick={reject}>
                    cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default AssetTransferModal;
const styles = {
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
