import React, { useState, createContext, useEffect } from "react";

export const DarkLightModeContext = createContext();

export const DarkLightModeProvider = (props) => {
  const [isDarkMode, setIsDarkMode] = React.useState(null);

  const toggleDarkMode = () => {
    localStorage.setItem("InventoryTrackingDarkMode", !isDarkMode);
    setIsDarkMode((prev) => !prev);
  };

  const getModeFromLocalSettings = () => {
    // check if auth exists in state
    // if not, check local storage for jwt and decode
    // decode
    // return
    try {
      const darkMode = localStorage.getItem("InventoryTrackingDarkMode");
      if (darkMode && darkMode === "true") {
        setIsDarkMode(true);
      } else setIsDarkMode(false);
    } catch (error) {
      setIsDarkMode(true);
    }
  };

  useEffect(() => {
    if (isDarkMode === null) {
      getModeFromLocalSettings();
    }
  }, []);

  return (
    <DarkLightModeContext.Provider
      value={{
        isDarkMode,
        toggleDarkMode,
      }}
    >
      {props.children}
    </DarkLightModeContext.Provider>
  );
};
