import React, { useState, createContext, useEffect } from "react";
import jwt_decode from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [authToken, setAuthToken] = useState(null);
  const [role, setRole] = useState(null);
  const [username, setUsername] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [userBranch, setUserBranch] = useState(null);

  const isAdmin = () => {
    return ["Admin", "Branch Admin"].includes(role);
  };

  useEffect(() => {
    if (authToken === null) {
      getAuthenticationFromLocalStorage();
    }
  }, []);

  const getAuthenticationFromLocalStorage = () => {
    // check if auth exists in state
    // if not, check local storage for jwt and decode
    // decode
    // return
    try {
      const token = localStorage.getItem("InventoryTrackingToken");
      var decoded = jwt_decode(token);
      setAuthToken(token);
      setRole(decoded.role);
      if (decoded.branch !== "SDL" && decoded.branch !== "FLG") {
        setUserBranch("SDL");
      } else {
        setUserBranch(decoded.branch);
      }
    } catch (error) {
      console.log("No token in localstorage");
    }
  };

  const setAuthentication = (token) => {
    var decoded = jwt_decode(token);
    setAuthToken(token);
    setRole(decoded.role);
    setUsername(decoded.username);
    setFullName(decoded.fullName);
    if (decoded.branch !== "SDL" && decoded.branch !== "FLG") {
      setUserBranch("SDL");
    } else {
      setUserBranch(decoded.branch);
    }

    localStorage.setItem("InventoryTrackingToken", token);
  };

  const signOut = () => {
    setAuthToken(null);
    setRole(null);
    setUsername(null);
    setFullName(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        setAuthentication,
        authToken,
        username,
        fullName,
        role,
        isAdmin: isAdmin,
        userBranch,
        signOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
