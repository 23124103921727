import { Paper, Typography, Box, Button } from "@mui/material";
import PrintableQRCode from "../../../component/PrintableQRCode";

import ToolRequests from "./ToolRequests";
import ToolPossessionHistory from "./toolPossessionHistory";

const Tool = ({
  tool,
  log,
  possession,
  toolId,
  returnTool,
  requestTool,
  requested,
  isGateKeaper,
}) => {
  console.log("THE TOOL OBJECT: ", tool);
  const isInPossession = possession.some((e) => e.asset_id == toolId);

  return (
    <Paper sx={{ padding: "15px", marginTop: "50px" }}>
      <Typography sx={{ textAlign: "center", fontWeight: "700" }} variant="h5">
        {tool.name}
      </Typography>
      {isInPossession && (
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Currently Assinged To You.
        </Typography>
      )}
      <Box sx={{ marginTop: "25px" }}>
        <Box
          sx={{
            boxShadow: "2px 4px 5px #999999",
            maxHeight: "200px",
            overflow: "scroll",
          }}
        >
          {(isInPossession || isGateKeaper) && <ToolRequests />}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "35px",
          }}
        >
          {isInPossession && (
            <>
              <Button
                disabled={requested}
                variant="contained"
                onClick={returnTool}
                size="large"
              >
                Return Tool To Yard?
              </Button>
              {requested && (
                <Typography textAlign="center" variant="caption">
                  Pending Approval
                </Typography>
              )}
            </>
          )}
          {!isInPossession && (
            <>
              <Button
                disabled={requested}
                variant="contained"
                onClick={requestTool}
                size="large"
              >
                Request Tool?
              </Button>
              {requested && (
                <Typography textAlign="center" variant="caption">
                  Pending Approval
                </Typography>
              )}
            </>
          )}
        </Box>

        <Box
          sx={{
            marginTop: "55px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PrintableQRCode
            url={`${window.location.host}/tools/${toolId}`}
            displaySize={100}
            printSize={100}
            subtext={tool.name}
          />
          <Box sx={{ marginTop: "15px" }}>
            <Box>
              <Typography display="inline" fontWeight="700">
                Tool:{" "}
              </Typography>
              <Typography display="inline">
                {tool.name ? tool.name : "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography display="inline" fontWeight="700">
                Number:{" "}
              </Typography>
              <Typography display="inline">
                {tool.number ? tool.number : "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography display="inline" fontWeight="700">
                Model:{" "}
              </Typography>
              <Typography display="inline">
                {tool.model ? tool.model : "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography display="inline" fontWeight="700">
                Description:{" "}
              </Typography>
              <Typography display="inline">
                {tool.description ? tool.description : "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography display="inline" fontWeight="700">
                Notes:{" "}
              </Typography>
              <Typography display="inline">
                {tool.notes ? tool.notes : "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box marginTop={"25px"}>
          <ToolPossessionHistory logs={log} />
        </Box>
      </Box>
    </Paper>
  );
};

export default Tool;
