import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Fab,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import ListItem from "../../../component/ListItem";
import { AuthContext } from "../../../context/AuthContext";
import InventoryTracking from "../../../api/InventoryTracking";
import useSearchAndPagination from "../../../customHooks/useSearchAndPagination";
import SearchAndPagination from "../../../component/SearchAndPagination";
import ImageModalButton from "../../../component/ImageModalButton";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import QRCode from "react-qr-code";
import Print from "../../../component/Print";
import escapeCharacters from "../../../helpers/escapeCharacters";

import InventoryTabs from "../../../component/InventoryTabs";

import PendingPoModal from "../../../component/PendingPoModal";
import PageContent from "../../../component/PageContent";
import { useTheme } from "@emotion/react";

const Committed = () => {
  const navigate = useNavigate();
  const { authToken, userBranch } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [committedList, setCommittedList] = useState([]);
  const [locations, setLocations] = useState(["SDL", "FLG"]);
  // const [pendingPos, setPendingPos] = useState([]);
  // const [pendingPoModalData, setPendingPoModalData] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);

  const searchAndPagination = useSearchAndPagination(userBranch);
  const { search, location, page, limit, setPages, setPage, pages } =
    searchAndPagination;

  const observer = useRef();
  const lastElement = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < pages) {
          console.log("visible");
          setPage((curr) => curr + 1);
        }
      });
      if (node) observer.current.observe(node);
      // console.log("NODE: ", node);
    },
    [isLoading, page < pages]
  );

  const getCommittedList = async () => {
    try {
      const response = await InventoryTracking.get(
        `/committed?search=${escapeCharacters(
          search
        )}&location=${location}&pageSize=20&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      // setCommittedList(response.data.committed.committed);

      if (page > 1) {
        setCommittedList((prev) => [
          ...prev,
          ...response.data.committed.committed,
        ]);
      } else {
        setCommittedList((prev) => [...response.data.committed.committed]);
      }
      setPages(response.data.committed.pages);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (locations.length > 0) {
      getCommittedList();
    }
  }, [locations, location, page]);
  useEffect(() => {
    if (locations.length > 0) {
      setTimeout(() => {
        getCommittedList();
        setPage(1);
      }, 1500);
    }
  }, [search]);

  return (
    <PageContent>
      <div
        style={{
          position: "relative",
          flexDirection: "row",
          display: useMediaQuery(theme.breakpoints.down("sm"))
            ? "column"
            : "row",
          marginTop: 16,
          marginLeft: 16,
          marginRight: 16,
        }}
      >
        {/* <h2
          style={{
            textAlign: useMediaQuery(theme.breakpoints.down("md"))
              ? "left"
              : "center",
            flex: 1,
            margin: 0,
          }}
        >
          Committed
        </h2> */}
        <InventoryTabs pageName="committed" />
      </div>

      <SearchAndPagination
        stateHandlers={searchAndPagination}
        locationOptions={locations}
      />
      {!isSmallScreen && (
        <Print>
          <Print.Trigger style={{ marginTop: "10px" }}>
            <Button variant="contained">Print QR Codes</Button>
          </Print.Trigger>
          <Print.PrintableData>
            <div style={{ columns: 1, marginLeft: "15px" }}>
              {committedList.map((item) => (
                <div
                  style={{
                    breakInside: "avoid",
                    breakBefore: "always",
                    breakAfter: "always",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "30%",
                      marginBottom: "0px",
                      width: "100px",
                    }}
                  >
                    <p
                      style={{
                        color: "#000",
                        marginBottom: "0px",
                        textAlign: "center",
                      }}
                    >
                      {item.item_description}
                    </p>
                  </div>

                  <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="L" //'L' 'M' 'Q' 'H'
                    size={100} // display size
                    title={item.item_description}
                    value={`${window.location.host}/committed/${item.id}`}
                    style={{
                      marginLeft: "30%",
                      marginTop: "0px",
                      marginBottom: 0,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "30%",
                      marginTop: "0px",
                      width: "100px",
                    }}
                  >
                    <p
                      style={{
                        color: "#000",
                        textAlign: "center",
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      {item.internal_code
                        ? item.internal_code
                        : "BRM code: N/A"}
                    </p>
                    <p
                      style={{
                        color: "#000",
                        textAlign: "center",
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      COMMITTED
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Print.PrintableData>
        </Print>
      )}
      <Button
        variant="outlined"
        style={{
          width: useMediaQuery(theme.breakpoints.down("sm")) ? "100%" : 200,
        }}
        onClick={() => {
          navigate("/committed/transactions");
        }}
      >
        View Transactions
      </Button>
      {committedList.map((committed, index) => {
        let details = [];
        details.push(
          `Committed to: ${committed.job_name} (${committed.job_id})`
        );
        details.push(
          `Internal Code: ${
            committed.internal_code ? committed.internal_code : ""
          }`
        );
        details.push(`Quantity: ${committed.quantity} ${committed.uom}`);

        details.push(`Location: ${committed.location}`);

        if (committedList.length === index + 1) {
          return (
            <div ref={lastElement}>
              <ListItem
                key={index}
                title={committed.item_description}
                details={details}
                // image={committed.image_url}
                onClick={() => {
                  navigate(`/committed/${committed.id}`);
                }}
              />
            </div>
          );
        }
        return (
          <ListItem
            key={index}
            title={committed.item_description}
            details={details}
            // image={committed.image_url}
            onClick={() => {
              navigate(`/committed/${committed.id}`);
            }}
          />
        );
      })}

      {/* <div>
        <h3>Pending POs</h3>
        {pendingPos &&
          pendingPos.map((po, index) => {
            let itemDetails = [];
            return (
              <ListItem
                key={index}
                title={`${po.poid}`}
                details={itemDetails}
                onClick={() => {
                  getPendingPoById(po.id);
                }}
              />
            );
          })}
        <PendingPoModal
          pendingPoModalData={pendingPoModalData}
          setPendingPoModalData={setPendingPoModalData}
          //receivePendingPo={receivePendingPo}
          //isLoading={isLoading}
          onUpdatePo={() => {
            // Reset page data
            getCommittedList();
            getPendingCommittedPos();
          }}
        />
      </div> */}
    </PageContent>
  );
};

export default Committed;
