import { Button, Card, CardActionArea, Modal, Typography } from "@mui/material";
import SelectVehicle from "./SelectVehicle";

const SelectTruckModal = ({ open, setOpen, onSelect }) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: 300,
          maxWidth: 500,
          maxHeight: "100%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "block",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Select Truck Loaded
        </Typography>
        <SelectVehicle
          onSelect={(vehicleId, vehicle) => {
            onSelect(vehicleId, vehicle);
          }}
        />
        <CardActionArea
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 16,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </CardActionArea>
      </Card>
    </Modal>
  );
};

export default SelectTruckModal;
