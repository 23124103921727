import React, {
  useContext,
  useEffect,
  useState,
  forwardRef,
  useRef,
} from "react";

import ReactToPrint from "react-to-print";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { Button, MenuItem, TextField } from "@mui/material";

export const PrintAllInventoryItems = forwardRef((props, ref) => {
  const { authToken } = useContext(AuthContext);
  const [items, setItems] = useState();
  const [branch, setBranch] = useState("SDL");
  const [branchOptions, setBranchOptions] = useState([]);

  const componentRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef(null);

  const AndreasDesiredOrderOfThings = [
    // subject to change??? hope not
    "SGP000053",
    "CTG000027",
    "CTG000034",
    "CTG000042",
    "TIL0000001",
    "MSC000016",
    "UND000013",
    "SGP000058",
    "SGP000023",
    "MSC000009",
    "MSC00010",
    "MSC000011",
    "MSC000006",
    "MSC000007",
    "ADH000003",
    "CTG000036",
    "ADH000004",
    "CTG000013",
    "CTG000016",
    "CTG000015",
    "SGP000035",
    "TIL0000005",
    "TIL0000007",
    "TIL0000003",
    "TIL0000046",
    "TIL0000008",
    "TIL0000002",
    "OTH000001",
    "PJS000011",
    "PJS000001",
    "PJS000002",
    "PJS000004",
    "PJS000023",
    "PJS000012",
    "PJS000013",
    "OTH000013",
    "PJS000010",
    "PJS000016",
    "SHG000017",
    "TIL0000034",
    "TIL0000031",
    "SHG000022",
    "SHG000054",
    "OTH000011",
    "OTH000010",
    "OTH000004",
    "FLS000048",
    "FLS000004",
    "FLS0000007",
    "FLS00000036",
    "FLS0000002",
    "FLS0000013",
    "FLS0000014",
    "FLS0000015",
    "FLS000017",
    "FLS000041",
    "FLS000003",
    "FLS0000011",
    "FLS00000040",
    "FLS000044",
    "FLS000020",
    "FLS000001",
    "FLS000045",
    "FLS000002",
    "FLS000021",
    "FLS0000009",
    "FLS0000032",
    "FLS000025",
    "TIL000013",
    "SPF000001",
    "SPF0000002",
    "SGP000038",
    "SHG000023",
    "FST000012",
    "FST000007",
    "FST000010",
    "SGP000059",
    "SGP000056",
    "FST000011",
    "FST000022",
    "FST000017",
    "FST000018",
    "FST000025",
    "FST000008",
    "SGP000007",
    "FST000004",
    "FST000037",
    "FST000015",
    "FST000019",
    "SGP000036",
    "SLT000009",
    "SLT000010",
    "SLT000013",
    "OTH000008",
    "OTH000017",
    "OTH000018",
    "OTH000019",
    "SLT000007",
    "CTG000003",
    "OTH000014",
    "OTH000015",
    "OTH000043",
    "SGP000006",
    "SGP000062",
    "SGP000014",
    "BUR000008",
    "SGP000061",
    "FST000035",
    "FST000055",
    "FST000032",
  ];
  const sortItems = (list, targetOrder = AndreasDesiredOrderOfThings) => {
    const sorted = targetOrder.reduce(
      (acc, curr) => [
        ...acc,
        ...list.filter((item) => item.internal_code === curr),
      ],
      []
    );
    const unmatched = list.filter(
      (item) => !targetOrder.includes(item.internal_code)
    );
    return [...sorted, ...unmatched];
  };

  const getAll = async () => {
    try {
      const res = await InventoryTracking.get(
        `/inventory/all?branch=${branch}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (res.data.inventory) {
        const sortedItems = sortItems(res.data.inventory);

        setItems(sortedItems);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLocations = async () => {
    try {
      const response = await InventoryTracking.get(`/locations`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setBranchOptions(response.data.locations);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
    getAll();
  }, [, branch]);

  // We watch for the state to change here, and for the Promise resolve to be available
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  if (items)
    return (
      <div style={{ marginTop: 70 }}>
        <TextField
          select
          value={branch}
          label="Branch"
          onChange={(e) => setBranch(e.target.value)}
        >
          {branchOptions.map((b) => (
            <MenuItem key={b.location} value={b.location}>
              {b.location}
            </MenuItem>
          ))}
        </TextField>
        <ReactToPrint
          trigger={() => {
            return <Button variant="contained">Print All</Button>;
          }}
          content={() => componentRef.current}
          onBeforeGetContent={() => {
            return new Promise((resolve) => {
              promiseResolveRef.current = resolve;
              setIsPrinting(true);
            });
          }}
          onAfterPrint={() => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
          }}
        />
        <div
          ref={componentRef}
          style={{
            marginTop: "20px",
            paddingTop: "20px",
          }}
        >
          {items.map((item, i) => {
            return (
              <div
                key={item.internal_code}
                style={{
                  borderBottom: "2px solid black",
                  lineHeight: "2pt",
                  breakInside: "avoid",
                  breakAfter: "always",
                  marginLeft: "20px",
                  marginRight: "20px",
                  paddingTop: "10px",
                }}
              >
                <p
                  style={{
                    color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                    fontWeight: "600",
                  }}
                >
                  {item.description}
                </p>
                <p
                  style={{
                    color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                    fontSize: "15px",
                  }}
                >
                  CODE: {item.internal_code}
                </p>
                <p
                  style={{
                    color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                    fontSize: "15px",
                  }}
                >
                  QTY: {item.quantity}
                </p>
                <p
                  style={{
                    color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                    fontSize: "15px",
                  }}
                >
                  CATEGORY: {item.category}
                </p>
                <p
                  style={{
                    color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                    fontSize: "15px",
                  }}
                >
                  BRANCH: {item.branch}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
});
